<template>
  <el-dialog
    append-to-body
    class="custom-dialog"
    title="审核"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-descriptions :column="2" size="medium">
      <el-descriptions-item label="姓名">{{ row.name }}</el-descriptions-item>
      <el-descriptions-item label="手机号">
        {{ row.phone }}
      </el-descriptions-item>
      <el-descriptions-item label="地址" :span="2">
        {{ row.province || '' }}{{ row.city || '' }}{{ row.district || ''
        }}{{ row.address || '' }}
      </el-descriptions-item>
    </el-descriptions>
    <el-form
      ref="form"
      label-suffix="："
      label-width="90px"
      :model="form"
      style="margin-top: 15px"
    >
      <el-form-item
        v-if="form.status === 2"
        label="审核意见"
        prop="comment"
        :rules="{
          required: true,
          message: '审核意见不能为空',
          trigger: 'blur',
        }"
      >
        <el-input
          v-model="form.comment"
          maxlength="40"
          placeholder="审核意见（必填，20字以内）"
          :rows="3"
          type="textarea"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { postChannelApplyAudit } from '@/api/distributor/apply'

  export default {
    name: 'TableEdit',
    data() {
      return {
        form: {
          comment: '',
          status: 1,
          id: null,
        },
        dialogFormVisible: false,
        row: {},
      }
    },
    created() {},
    methods: {
      showEdit(row, status) {
        console.log('row', row)
        this.row = row
        this.form = {
          ...this.form,
          id: row.id,
          status,
        }
        this.dialogFormVisible = true
      },
      close() {
        this.form = this.$options.data().form
        this.dialogFormVisible = false
        setTimeout(() => {
          this.$refs['form'].resetFields()
        }, 400)
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let res = await postChannelApplyAudit(this.form)
            if (res.code === 0) {
              this.$baseMessage(
                '提交成功',
                'success',
                'vab-hey-message-success'
              )
              this.$emit('fetch-data')
              this.close()
            }
          }
        })
      },
    },
  }
</script>
<style lang="scss">
  @import '../../../dialog.scss';

  .custom-dialog {
    @include setDialog();
  }
</style>
