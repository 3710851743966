var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Dialog", {
    attrs: {
      height: _vm.dailog.height,
      "model-value": _vm.dailog.visible,
      title: _vm.dailog.title,
      visible: _vm.dailog.visible,
      width: _vm.dailog.width,
    },
    on: {
      handleCloseDialog: _vm.handleCloseDialog,
      handleConfirm: _vm.handleCloseDialog,
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "el-tabs",
              {
                on: { "tab-click": _vm.handleQuery },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c("el-tab-pane", {
                  attrs: { label: "电表", name: "electric" },
                }),
                _c("el-tab-pane", { attrs: { label: "水表", name: "water" } }),
              ],
              1
            ),
            _c(
              "vab-query-form",
              [
                _c(
                  "vab-query-form-top-panel",
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          inline: true,
                          "label-width": "80px",
                          model: _vm.queryForm,
                        },
                        nativeOn: {
                          submit: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "抄表方式" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  placeholder: "抄表方式",
                                },
                                model: {
                                  value: _vm.queryForm.readType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryForm, "readType", $$v)
                                  },
                                  expression: "queryForm.readType",
                                },
                              },
                              _vm._l(_vm.MeterMethod, function (item) {
                                return _c("el-option", {
                                  key: "MeterMethod" + item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "抄表场景" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  placeholder: "抄表场景",
                                },
                                model: {
                                  value: _vm.queryForm.readReason,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryForm, "readReason", $$v)
                                  },
                                  expression: "queryForm.readReason",
                                },
                              },
                              _vm._l(_vm.MeterReasons, function (item) {
                                return _c("el-option", {
                                  key: "MeterReasons" + item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "抄表时间" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                "end-placeholder": "结束日期",
                                "range-separator": "至",
                                "start-placeholder": "开始日期",
                                type: "daterange",
                              },
                              model: {
                                value: _vm.queryForm.date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "date", $$v)
                                },
                                expression: "queryForm.date",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-search",
                                  "native-type": "submit",
                                  type: "primary",
                                },
                                on: { click: _vm.handleQuery },
                              },
                              [_vm._v(" 查询 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { icon: "el-icon-refresh-left" },
                                on: {
                                  click: function ($event) {
                                    return _vm.resetForm("form")
                                  },
                                },
                              },
                              [_vm._v(" 重置 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                attrs: { border: "", data: _vm.tableData, stripe: "" },
                on: { "selection-change": _vm.setSelectRows },
                scopedSlots: _vm._u([
                  {
                    key: "empty",
                    fn: function () {
                      return [
                        _c("el-image", {
                          staticClass: "vab-data-empty",
                          attrs: {
                            src: require("@/assets/empty_images/data_empty.png"),
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c("el-table-column", {
                  attrs: { align: "center", type: "selection", width: "50" },
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "抄表序号", width: "85" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ $index }) {
                        return [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (_vm.listQuery.pageNumber - 1) *
                                    _vm.listQuery.pageSize +
                                    $index +
                                    1
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: `总用${
                      _vm.activeName === "electric" ? "电" : "水"
                    }量`,
                    prop: "totalUsedVal",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: `剩余${
                      _vm.activeName === "electric" ? "电" : "水"
                    }量`,
                    prop: "surplusVal",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "上期读数",
                    prop: "lastMeterVal",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.lastMeterVal == null
                                  ? "-"
                                  : scope.row.lastMeterVal
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "本期读数",
                    prop: "meterVal",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "用量", prop: "usedVal" },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "抄表场景",
                    prop: "readReason",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm.returnLabel(scope.row.readReason)) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "抄表方式",
                    prop: "readType",
                    width: "85",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.readType === 1
                                  ? "自动获取"
                                  : "手动录入"
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "入账状态",
                    prop: "readType",
                    width: "85",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.isUsed === 1 ? "已入账" : "未入账"
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "抄表时间",
                    prop: "meterDate",
                    width: "180",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " + _vm._s(scope.row.meterDate || "-") + " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "是否修改", width: "180" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.isUpdatedMeterVal ? "是" : "否"
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "更新时间",
                    prop: "lastUpdatedMeterValTime",
                    width: "180",
                  },
                }),
              ],
              1
            ),
            _c("Pagination", {
              attrs: {
                limit: _vm.listQuery.pageSize,
                page: _vm.listQuery.pageNumber,
                "total-count": _vm.totalCount,
              },
              on: {
                "update:limit": function ($event) {
                  return _vm.$set(_vm.listQuery, "pageSize", $event)
                },
                "update:page": function ($event) {
                  return _vm.$set(_vm.listQuery, "pageNumber", $event)
                },
                pagination: _vm.fetchData,
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }