var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "custom-dialog",
      attrs: {
        "append-to-body": "",
        stripe: "",
        title: "账单详情",
        visible: _vm.dialogFormVisible,
        width: "720px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-descriptions",
        { attrs: { column: 2, size: "medium" } },
        [
          _c("el-descriptions-item", { attrs: { label: "商户名称" } }, [
            _vm._v(" " + _vm._s(_vm.info.shopName || "--") + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "手机号" } }, [
            _vm._v(" " + _vm._s(_vm.info.shopPhone || "--") + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "地址", span: 2 } }, [
            _vm._v(" " + _vm._s(_vm.info.registerAddress || "--") + " "),
          ]),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { "margin-top": "10px" },
          attrs: {
            border: "",
            "cell-style": { padding: "5px 8px" },
            data: _vm.tableData,
            "max-height": "300",
          },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png"),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "账单号/流水号",
              "min-width": "140",
              prop: "billId",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_vm._v(" " + _vm._s(row.id || "--") + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "账单周期", "min-width": "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.startDate
                            ? _vm.parseTime(row.startDate, "{y}-{m}-{d}")
                            : "--"
                        ) +
                        " - " +
                        _vm._s(
                          row.endDate
                            ? _vm.parseTime(row.endDate, "{y}-{m}-{d}")
                            : "--"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "房源",
              "min-width": "100",
              prop: "houseName",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(row.communityName) +
                        _vm._s(row.buildingName) +
                        _vm._s(row.houseName) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "状态",
              "min-width": "100",
              prop: "houseName",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("statusFilterName")(row.status)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "账单金额(元)",
              "min-width": "90",
              prop: "receivableAmount",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " + _vm._s((row.receivableAmount || 0).toFixed(2)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNumber,
          layout: "prev, pager, next",
          "page-size": _vm.queryForm.pageSize,
          small: "",
          total: _vm.totalCount,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c(
        "el-button",
        {
          staticClass: "btn-merchant-info",
          attrs: { plain: "", type: "primary" },
          on: { click: _vm.toMerchantInfo },
        },
        [_vm._v(" 商户详情 ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }