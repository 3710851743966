<template>
  <div class="comprehensive-table-container">
    <el-page-header :content="'出货记录详情'" @back="goBack" />
    <el-form
      ref="form"
      class="form"
      label-width="100px"
      :model="formData"
      size="small"
    >
      <div class="card-title">订单信息</div>
      <el-row>
        <el-col :span="12">
          <el-form-item label="商户名称" prop="shopName">
            {{ formData.shopName }}
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
            <el-form-item label="商户编号" prop="shopId">
              {{ formData.shopId }}
            </el-form-item>
          </el-col> -->
        <el-col :span="12">
          <el-form-item label="出货时间" prop="outWarehouseTime">
            {{ formData.outWarehouseTime }}
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
            <el-form-item label="订单编号" prop="orderNo">
              {{ formData.orderNo }}
            </el-form-item>
          </el-col> -->
        <el-col :span="12">
          <el-form-item label="收货人姓名" prop="receiver">
            {{ formData.receiver }}
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收货人电话" prop="receiverPhone">
            {{ formData.receiverPhone }}
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收货地址" prop="receiverAddress">
            {{ formData.receiverAddress }}
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="订单金额" prop="orderPrice">
            {{ formData.orderPrice }}
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="订单备注" prop="type">
            {{ formData.remark }}
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-divider />
    <el-form ref="formPro" label-width="0" :model="deviceForm" size="small">
      <div class="card-title">商品信息</div>
      <el-row :gutter="10">
        <el-col :span="5">
          <el-form-item label="" prop="typeId">
            <el-select
              v-model="deviceForm.typeId"
              clearable
              placeholder="设备类型"
            >
              <el-option
                v-for="item in optionsType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="" prop="brandId">
            <el-select
              v-model="deviceForm.brandId"
              clearable
              placeholder="设备品牌"
              @change="fetchModel"
            >
              <el-option
                v-for="item in optionsBrand"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="5">
          <el-form-item label="" prop="modelId">
            <el-select
              v-model="deviceForm.modelId"
              clearable
              placeholder="设备型号"
            >
              <el-option
                v-for="item in optionsModel"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="5">
          <el-form-item label="" prop="deviceNo">
            <el-input
              v-model="deviceForm.deviceNo"
              clearable
              placeholder="请填写或扫码录入设备编号"
              style="max-width: 300px"
            />
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-button style="width: 100%" type="primary" @click="fetchData">
            搜索
          </el-button>
        </el-col>
        <el-col :span="2">
          <el-button
            icon="el-icon-refresh-left"
            style="width: 100%"
            @click="resetForm('formPro')"
          >
            重置
          </el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table v-loading="loading" border :data="tableData" stripe>
      <!--
            @select="selectRow"
            @select-all="selectRow"<el-table-column align="center" type="selection" width="50" /> -->
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          <span>
            {{ $index + 1 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="设备类型" prop="typeName" />
      <el-table-column align="center" label="设备品牌" prop="brandName" />
      <el-table-column align="center" label="设备型号" prop="modelName" />
      <el-table-column align="center" label="设备编号" prop="deviceNo">
        <template #default="{ row }">
          <span>
            {{ row.deviceNo }}
          </span>
          <el-button type="text" @click="handleViewBarcode(row.deviceNo)">
            查看标签
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="添加时间"
        prop="createTime"
        sortable
      />
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <!-- <Pagination
            :limit.sync="listQuery.pageSize"
            :page.sync="listQuery.pageNumber"
            :total-count="totalCount"
            @pagination="fetchData"
          /> -->
    <barcode ref="viewBarcode" />
  </div>
</template>

<script>
  import { ListDevices, DetailDeviceOrder } from '@/api/device/shipment'
  import { SelectDeviceType } from '@/api/device/type'
  import { SelectDeviceBrand } from '@/api/device/brand'
  import { SelectDeviceModel } from '@/api/device/model'
  import { mapActions } from 'vuex'
  import { handleActivePath } from '@/utils/routes'
  import barcode from '../components/barcode.vue'
  export default {
    name: 'DeviceShipmentDetail',
    components: {
      // Pagination,
      barcode,
    },
    data() {
      return {
        id: '',
        formData: {
          orderNo: '',
          shopName: '',
          shopId: '',
          outWarehouseTime: '',
          orderPrice: '',
          remark: '',
        },
        deviceForm: {
          typeId: '',
          brandId: '',
          modelId: '',
          deviceNo: '',
        },
        optionsType: [],
        optionsBrand: [],
        optionsModel: [],
        tableData: [],
        loading: false,
      }
    },
    created() {
      const route = this.$route
      this.id = route.query.id
      this.fetchDetail(this.id)
      this.fetchType()
      this.fetchBrand()
    },
    methods: {
      ...mapActions({
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      handleViewBarcode(deviceNo) {
        this.$refs['viewBarcode'].showModal(deviceNo)
      },
      async goBack() {
        const detailPath = await handleActivePath(this.$route, true)
        this.$router.push({ name: 'DeviceShipment' })
        this.delVisitedRoute(detailPath)
      },
      async fetchType() {
        const { data } = await SelectDeviceType()
        this.optionsType = data.map((item) => {
          return {
            label: item.name,
            value: item.id,
          }
        })
      },
      async fetchBrand() {
        const { data } = await SelectDeviceBrand()
        this.optionsBrand = data.map((item) => {
          return {
            label: item.name,
            value: item.id,
          }
        })
      },
      async fetchModel(val) {
        const { data } = await SelectDeviceModel({
          brandId: val,
        })
        this.optionsModel = data.map((item) => {
          return {
            label: item.name,
            value: item.id,
          }
        })
      },
      handleCloseDialog() {
        this.$refs['form'].resetFields()
        this.formData = this.$options.data().formData
        this.deviceForm = this.$options.data().deviceForm
      },
      async fetchDetail(id) {
        const { data } = await DetailDeviceOrder({ id })
        const {
          remark,
          orderPrice,
          receiver,
          receiverAddress,
          receiverPhone,
          orderNo,
          shopId,
          shopName,
          outWarehouseTime,
        } = data
        this.formData = {
          remark,
          orderPrice,
          receiver,
          receiverAddress,
          receiverPhone,
          orderNo,
          shopId,
          shopName,
          outWarehouseTime,
        }
        this.id = id
        this.fetchData()
      },
      resetForm(formName) {
        this.$refs[formName].resetFields()
        this.optionsModel = []
        this.fetchData()
      },
      async fetchData() {
        if (this.loading) return
        try {
          this.loading = true
          const { typeId, brandId, modelId, deviceNo } = this.deviceForm
          const { data } = await ListDevices({
            orderId: this.id,
            typeId,
            brandId,
            modelId,
            deviceNo: deviceNo ? deviceNo.trim() : '',
          })
          this.tableData = data
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  ::v-deep(.container) {
    padding: 0 20px;
  }
  .card-title {
    margin-bottom: 18px;
    font-size: 16px;
  }
  .form {
    ::v-deep(.el-form-item--small.el-form-item) {
      margin-bottom: 5px;
    }
  }
  ::v-deep(.el-select) {
    position: relative;
    display: block;
  }
</style>
