var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-width": "100px",
            model: _vm.formData,
            rules: _vm.rules,
            size: "small",
          },
        },
        [
          _c("div", { staticClass: "card-title" }, [_vm._v("订单信息")]),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商户名称", prop: "shopName" } },
                    [
                      _c("el-autocomplete", {
                        staticClass: "inline-input",
                        staticStyle: { width: "340px" },
                        attrs: {
                          clearable: "",
                          "fetch-suggestions": _vm.querySearch,
                          "highlight-first-item": true,
                          placeholder: "请输入...",
                          "trigger-on-focus": false,
                        },
                        on: { select: _vm.handleSelect },
                        model: {
                          value: _vm.formData.shopName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "shopName", $$v)
                          },
                          expression: "formData.shopName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "出货时间", prop: "outWarehouseTime" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "340px" },
                        attrs: { placeholder: "请选择", type: "datetime" },
                        model: {
                          value: _vm.formData.outWarehouseTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "outWarehouseTime", $$v)
                          },
                          expression: "formData.outWarehouseTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收货人姓名", prop: "receiver" } },
                    [
                      _c("el-input", {
                        staticStyle: { "max-width": "340px" },
                        attrs: { clearable: "", placeholder: "请输入..." },
                        model: {
                          value: _vm.formData.receiver,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "receiver", $$v)
                          },
                          expression: "formData.receiver",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收货人电话", prop: "receiverPhone" } },
                    [
                      _c("el-input", {
                        staticStyle: { "max-width": "340px" },
                        attrs: { clearable: "", placeholder: "请输入..." },
                        model: {
                          value: _vm.formData.receiverPhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "receiverPhone", $$v)
                          },
                          expression: "formData.receiverPhone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收货地址", prop: "receiverAddress" } },
                    [
                      _c("el-input", {
                        staticStyle: { "max-width": "340px" },
                        attrs: { clearable: "", placeholder: "请输入..." },
                        model: {
                          value: _vm.formData.receiverAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "receiverAddress", $$v)
                          },
                          expression: "formData.receiverAddress",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单金额", prop: "orderPrice" } },
                    [
                      _c("el-input", {
                        staticStyle: { "max-width": "340px" },
                        attrs: { clearable: "", placeholder: "请输入..." },
                        model: {
                          value: _vm.formData.orderPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "orderPrice", $$v)
                          },
                          expression: "formData.orderPrice",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单备注", prop: "type" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "340px" },
                        attrs: {
                          maxlength: "2000",
                          placeholder: "可以换行输入,至多2000字",
                          rows: 3,
                          type: "textarea",
                        },
                        model: {
                          value: _vm.formData.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "remark", $$v)
                          },
                          expression: "formData.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-form",
        {
          ref: "formPro",
          attrs: {
            "label-width": "0",
            model: _vm.deviceForm,
            rules: _vm.deviceRules,
            size: "small",
          },
        },
        [
          _c("div", { staticClass: "card-title" }, [_vm._v("商品信息")]),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "typeId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "设备类型" },
                          on: { change: _vm.handleDeviceType },
                          model: {
                            value: _vm.deviceForm.typeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.deviceForm, "typeId", $$v)
                            },
                            expression: "deviceForm.typeId",
                          },
                        },
                        _vm._l(_vm.optionsType, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "brandId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "设备品牌" },
                          on: { change: _vm.fetchModel },
                          model: {
                            value: _vm.deviceForm.brandId,
                            callback: function ($$v) {
                              _vm.$set(_vm.deviceForm, "brandId", $$v)
                            },
                            expression: "deviceForm.brandId",
                          },
                        },
                        _vm._l(_vm.optionsBrand, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "modelId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "设备型号" },
                          model: {
                            value: _vm.deviceForm.modelId,
                            callback: function ($$v) {
                              _vm.$set(_vm.deviceForm, "modelId", $$v)
                            },
                            expression: "deviceForm.modelId",
                          },
                        },
                        _vm._l(_vm.optionsModel, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "deviceNo" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { "max-width": "340px" },
                          attrs: {
                            clearable: "",
                            placeholder: "请填写或扫码录入设备编号",
                          },
                          model: {
                            value: _vm.deviceForm.deviceNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.deviceForm, "deviceNo", $$v)
                            },
                            expression: "deviceForm.deviceNo",
                          },
                        },
                        [
                          ["SMART_LOCK", "SMART_GATEWAY"].includes(
                            _vm.currentDeviceNumber
                          )
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "append",
                                    icon: "el-icon-refresh",
                                  },
                                  on: { click: _vm.handleRandomNo },
                                  slot: "append",
                                },
                                [_vm._v(" 随机 ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { type: "primary" },
                      on: { click: _vm.handleAddDevice2 },
                    },
                    [_vm._v(" 添加 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { "margin-bottom": "18px" },
          attrs: { border: "", data: _vm.tableData, stripe: "" },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png"),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "序号", width: "55" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ $index }) {
                  return [_c("span", [_vm._v(" " + _vm._s($index + 1) + " ")])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "设备类型", prop: "typeName" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "设备品牌", prop: "brandName" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "设备型号", prop: "modelName" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "设备编号", prop: "deviceNo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [_vm._v(" " + _vm._s(row.deviceNo) + " ")]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleViewBarcode(row.deviceNo)
                          },
                        },
                      },
                      [_vm._v(" 查看标签 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "添加时间",
              prop: "createTime",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDeleteDevice(
                              scope.row,
                              scope.$index
                            )
                          },
                        },
                      },
                      [_vm._v(" 删除 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btns" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleConfirm } },
            [_vm._v("提交")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-refresh-left" },
              on: { click: _vm.resetForm },
            },
            [_vm._v("重置")]
          ),
        ],
        1
      ),
      _c("barcode", { ref: "viewBarcode" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }