<template>
  <div class="comprehensive-table-container">
    <el-form
      ref="form"
      label-width="100px"
      :model="formData"
      :rules="rules"
      size="small"
    >
      <div class="card-title">订单信息</div>
      <el-row>
        <el-col :span="12">
          <el-form-item label="商户名称">
            {{ formData.shopName }}
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
              <el-form-item label="商户编号">
                {{ formData.shopId }}
              </el-form-item>
            </el-col> -->
        <el-col :span="12">
          <el-form-item label="出货时间" prop="outWarehouseTime">
            {{ formData.outWarehouseTime }}
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row>
            <el-col :span="12">
              <el-form-item
                v-if="formData.orderNo"
                label="订单编号"
                prop="orderNo"
              >
                {{ formData.orderNo }}
              </el-form-item>
            </el-col>
          </el-row> -->

      <el-row>
        <el-col :span="12">
          <el-form-item label="收货人姓名" prop="receiver">
            <el-input
              v-model="formData.receiver"
              clearable
              placeholder="请输入..."
              style="max-width: 340px"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收货人电话" prop="receiverPhone">
            <el-input
              v-model="formData.receiverPhone"
              clearable
              placeholder="请输入..."
              style="max-width: 340px"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收货地址" prop="receiverAddress">
            <el-input
              v-model="formData.receiverAddress"
              clearable
              placeholder="请输入..."
              style="max-width: 340px"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="订单金额" prop="orderPrice">
            <el-input
              v-model="formData.orderPrice"
              clearable
              placeholder="请输入..."
              style="max-width: 340px"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="订单备注" prop="type">
            <el-input
              v-model="formData.remark"
              maxlength="2000"
              placeholder="可以换行输入,至多2000字"
              :rows="3"
              style="width: 340px"
              type="textarea"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-divider />
    <el-form
      ref="formPro"
      label-width="0"
      :model="deviceForm"
      :rules="deviceRules"
      size="small"
    >
      <div class="card-title">商品信息</div>
      <el-row :gutter="10">
        <el-col :span="5">
          <el-form-item label="" prop="typeId">
            <el-select
              v-model="deviceForm.typeId"
              clearable
              placeholder="设备类型"
              @change="handleDeviceType"
            >
              <el-option
                v-for="item in optionsType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="" prop="brandId">
            <el-select
              v-model="deviceForm.brandId"
              clearable
              placeholder="设备品牌"
              @change="fetchModel"
            >
              <el-option
                v-for="item in optionsBrand"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="5">
          <el-form-item label="" prop="modelId">
            <el-select
              v-model="deviceForm.modelId"
              clearable
              placeholder="设备型号"
            >
              <el-option
                v-for="item in optionsModel"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="" prop="deviceNo">
            <el-input
              v-model="deviceForm.deviceNo"
              clearable
              placeholder="请填写或扫码录入设备编号"
              style="max-width: 340px"
            >
              <el-button
                v-if="
                  ['SMART_LOCK', 'SMART_GATEWAY'].includes(currentDeviceNumber)
                "
                slot="append"
                icon="el-icon-refresh"
                @click="handleRandomNo"
              >
                随机
              </el-button>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-button
            :loading="loaingAdd"
            style="width: 100%"
            type="primary"
            @click="handleAddDevice"
          >
            添加
          </el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table v-loading="loading" border :data="tableData" stripe>
      <!--
            @select="selectRow"
            @select-all="selectRow"<el-table-column align="center" type="selection" width="50" /> -->
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          <span>
            {{ (listQuery.pageNumber - 1) * listQuery.pageSize + $index + 1 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="设备类型" prop="typeName" />
      <el-table-column align="center" label="设备品牌" prop="brandName" />
      <el-table-column align="center" label="设备型号" prop="modelName" />
      <el-table-column align="center" label="设备编号" prop="deviceNo">
        <template #default="{ row }">
          <span>
            {{ row.deviceNo }}
          </span>
          <el-button type="text" @click="handleViewBarcode(row.deviceNo)">
            查看标签
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="添加时间"
        prop="createTime"
        sortable
      />
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button
            :disabled="loadingDelete"
            size="mini"
            type="text"
            @click="handleDeleteDevice(scope.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <!-- <Pagination
            :limit.sync="listQuery.pageSize"
            :page.sync="listQuery.pageNumber"
            :total-count="totalCount"
            @pagination="fetchData"
          /> -->
    <div class="btns">
      <el-button type="primary" @click="handleConfirm('form')">提交</el-button>
    </div>
    <barcode ref="viewBarcode" />
  </div>
</template>

<script>
  import { random } from '@/utils'
  import dayjs from 'dayjs'
  import { mapActions } from 'vuex'
  import { translateTitle } from '@/utils/i18n'
  // import Pagination from '@/components/pagination.vue'
  import { isNumber, isPhone } from '@/utils/validate'
  import {
    // SaveDeviceOrder,
    UpdateDeviceOrder,
    ListDevices,
    DeleteDevices,
    DetailDeviceOrder,
    SaveDevices,
  } from '@/api/device/shipment'
  import { SelectDeviceType } from '@/api/device/type'
  import { SelectDeviceBrand } from '@/api/device/brand'
  import { SelectDeviceModel } from '@/api/device/model'
  import barcode from '../components/barcode.vue'
  export default {
    name: 'DeviceShipmentEdit',
    components: {
      // Pagination,
      barcode,
    },
    data() {
      const validateNumber = (rule, value, callback) => {
        if (value && !isNumber(value)) {
          callback(new Error(this.translateTitle('请输入金额')))
        } else {
          callback()
        }
      }
      const validatePhone = (rule, value, callback) => {
        if (value && !isPhone(value)) {
          callback(new Error(this.translateTitle('请输入正确的手机号')))
        } else {
          callback()
        }
      }
      return {
        id: '',
        formData: {
          orderNo: '',
          shopName: '',
          shopId: '',
          outWarehouseTime: '',
          orderPrice: '',
          remark: '',
        },
        rules: {
          receiver: [
            {
              required: false,
              message: '请输入收货人姓名',
              trigger: 'blur',
            },
          ],
          receiverPhone: [
            {
              required: false,
              message: '请输入收货人电话',
              trigger: 'blur',
            },
            { validator: validatePhone, trigger: 'blur' },
          ],
          receiverAddress: [
            {
              required: false,
              message: '请输入收货地址',
              trigger: 'blur',
            },
          ],
          orderPrice: [
            {
              required: false,
              message: '请输入订单金额',
              trigger: 'blur',
            },
            { validator: validateNumber, trigger: 'blur' },
          ],
        },
        loaingAdd: false,
        loadingDelete: false,
        deviceForm: {
          typeId: '',
          brandId: '',
          modelId: '',
          deviceNo: '',
        },
        deviceRules: {
          typeId: [
            {
              required: true,
              message: '请选择设备类型',
              trigger: 'change',
            },
          ],
          brandId: [
            {
              required: true,
              message: '请选择品牌名称',
              trigger: 'change',
            },
          ],
          modelId: [
            {
              required: true,
              message: '请选择产品型号',
              trigger: 'change',
            },
          ],
          deviceNo: [
            {
              required: true,
              message: '请填写或扫码录入设备编号',
              trigger: 'blur',
            },
          ],
        },
        options: [
          {
            value: '选项1',
            label: '黄金糕',
          },
          {
            value: '选项2',
            label: '双皮奶',
          },
          {
            value: '选项3',
            label: '蚵仔煎',
          },
          {
            value: '选项4',
            label: '龙须面',
          },
          {
            value: '选项5',
            label: '北京烤鸭',
          },
        ],
        optionsType: [],
        optionsBrand: [],
        optionsModel: [],
        listQuery: {
          pageNumber: 1,
          pageSize: 10,
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        loadingSure: false,
        currentDeviceNumber: '',
      }
    },
    created() {
      const route = this.$route
      this.id = route.query.id
      this.fetchDetail(this.id)
      this.fetchType()
      this.fetchBrand()
    },
    methods: {
      handleViewBarcode(deviceNo) {
        this.$refs['viewBarcode'].showModal(deviceNo)
      },
      ...mapActions({
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      translateTitle,
      //表格选择
      selectRow(selection, row) {
        console.log('单选===', selection)
        console.log('单选===', row)
      },
      handleDeviceType(e) {
        this.currentDeviceNumber = this.optionsType.find(
          (item) => e === item.value
        )?.number
        this.deviceForm.deviceNo = ''
      },
      handleRandomNo() {
        const prefix = {
          SMART_LOCK: 'L',
          SMART_GATEWAY: 'G',
        }
        const randomNo = `${prefix[this.currentDeviceNumber]}${dayjs().format(
          'YYYYMMDD'
        )}${random(100000, 999999)}`
        this.deviceForm.deviceNo = randomNo
      },
      async fetchType() {
        const { data } = await SelectDeviceType()
        this.optionsType = data.map((item) => {
          return {
            label: item.name,
            value: item.id,
            number: item.number,
          }
        })
      },
      async fetchBrand() {
        const { data } = await SelectDeviceBrand()
        this.optionsBrand = data.map((item) => {
          return {
            label: item.name,
            value: item.id,
          }
        })
      },
      async fetchModel(val) {
        const { data } = await SelectDeviceModel({
          brandId: val,
        })
        this.optionsModel = data.map((item) => {
          return {
            label: item.name,
            value: item.id,
          }
        })
        this.deviceForm.modelId = ''
      },
      handleConfirm() {
        this.$refs['form'].validate(async (valid) => {
          if (valid && !this.loadingSure) {
            this.loadingSure = true
            try {
              const {
                remark,
                orderPrice,
                receiver,
                receiverAddress,
                receiverPhone,
              } = this.formData
              await UpdateDeviceOrder({
                id: this.id,
                remark,
                orderPrice,
                receiver,
                receiverAddress,
                receiverPhone,
              })
              this.$baseMessage(
                '操作成功',
                'success',
                'vab-hey-message-success'
              )
              this.delVisitedRoute()
              this.$router.push({
                name: 'DeviceShipment',
              })
            } finally {
              this.loadingSure = false
            }
          }
        })
      },
      async fetchDetail(id) {
        if (this.loading) return
        try {
          this.loading = true
          const { data } = await DetailDeviceOrder({ id })
          const {
            remark,
            orderPrice,
            receiver,
            receiverAddress,
            receiverPhone,
            orderNo,
            shopId,
            shopName,
            outWarehouseTime,
          } = data
          this.formData = {
            remark,
            orderPrice,
            receiver,
            receiverAddress,
            receiverPhone,
            orderNo,
            shopId,
            shopName,
            outWarehouseTime,
          }
          this.id = id
          this.fetchData()
          this.disabled = true
          console.log(data)
        } finally {
          this.loading = false
        }
      },
      async fetchData() {
        const { data } = await ListDevices({
          orderId: this.id,
        })
        this.tableData = data
      },
      //添加设备信息
      async handleAddDevice() {
        if (this.loaingAdd) return
        this.$refs['formPro'].validate(async (valid) => {
          if (valid) {
            this.loaingAdd = true
            try {
              const { typeId, brandId, modelId, deviceNo } = this.deviceForm
              await SaveDevices({
                orderId: this.id,
                typeId,
                brandId,
                modelId,
                deviceNo: deviceNo.trim(),
              })
              this.$baseMessage(
                '添加成功',
                'success',
                'vab-hey-message-success'
              )
              this.deviceForm.deviceNo = ''
              this.fetchData()
            } finally {
              this.loaingAdd = false
            }
          }
        })
      },
      //删除设备
      async handleDeleteDevice(row) {
        if (this.loadingDelete) return
        this.$baseConfirm(
          `你确定要删除设备编号： ${row.deviceNo} 吗`,
          null,
          async () => {
            try {
              this.loadingDelete = true
              await DeleteDevices({ id: row.id })
              this.$baseMessage(
                '删除成功',
                'success',
                'vab-hey-message-success'
              )
              this.fetchData()
            } finally {
              this.loadingDelete = false
            }
          }
        )
      },
    },
  }
</script>

<style scoped lang="scss">
  ::v-deep(.container) {
    padding: 0 20px;
  }
  .card-title {
    margin-bottom: 18px;
    font-size: 16px;
  }
  ::v-deep(.el-select) {
    position: relative;
    display: block;
  }
  .btns {
    margin: 30px auto 0;
    text-align: center;
  }
</style>
