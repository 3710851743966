<template>
  <div class="index-container">
    <el-form label-width="80px" :model="listQuery" size="small">
      <el-row>
        <el-col
          :md="{ span: 6 }"
          :sm="{ span: 6 }"
          :xl="{ span: 6 }"
          :xs="{ span: 24 }"
        >
          <el-form-item label="套餐名称">
            <el-input
              v-model="listQuery.name"
              clearable
              placeholder="套餐名称"
            />
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 6 }"
          :sm="{ span: 6 }"
          :xl="{ span: 6 }"
          :xs="{ span: 24 }"
        >
          <el-form-item label="套餐状态">
            <el-select v-model="listQuery.status" placeholder="套餐状态">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 10 }"
          :sm="{ span: 10 }"
          :xl="{ span: 10 }"
          :xs="{ span: 24 }"
        >
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="listQuery.rechargeTime"
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
            />
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 6 }"
          :sm="{ span: 6 }"
          :xl="{ span: 6 }"
          :xs="{ span: 24 }"
        >
          <el-form-item>
            <el-button
              icon="el-icon-search"
              size="mini"
              type="primary"
              @click="getList"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" size="mini" @click="reset">
              重置
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <vab-query-form-left-panel :span="24">
      <el-form ref="form" :inline="true">
        <el-form-item>
          <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
            新增套餐
          </el-button>
        </el-form-item>
      </el-form>
    </vab-query-form-left-panel>
    <el-table v-loading="loading" border :data="tableData" stripe>
      <el-table-column align="center" label="序号" width="50">
        <template slot-scope="scope">
          <span>
            {{
              (listQuery.pageNumber - 1) * listQuery.pageSize + scope.$index + 1
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="套餐名称"
        prop="name"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="套餐内容" prop="">
        <template #default="{ row }">
          {{ row.rechargeAmount }}元{{ row.contractQuantity }}份
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="购买次数"
        prop="purchaseCount"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="备注"
        prop="remark"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="状态"
        prop="createTime"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <el-tooltip
            :content="!row.status ? '点击开启' : '点击禁用'"
            :enterable="false"
            placement="top"
          >
            <el-switch
              :active-value="true"
              :disabled="statusChangeLoading"
              :inactive-value="false"
              :value="row.status"
              @change="onSwitchChange(row)"
            />
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="操作" show-overflow-tooltip>
        <template #default="{ row }">
          <el-button size="mini" type="text" @click="handleEdit(row)">
            编辑
          </el-button>
          <el-button size="mini" type="text" @click="handleDelete(row)">
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />
    <addTemplete ref="refAddTemplete" @ok="getList" />
  </div>
</template>

<script>
  import dayjs from 'dayjs'
  import { CONTRACTTEMPLETETYPE } from '@/utils/contract'
  import {
    getEsignContractPackage,
    switchEsignContractPackage,
    deleteEsignContractPackage,
  } from '@/api/esign/contract'
  import Pagination from '@/components/pagination.vue'
  import addTemplete from './components/add.vue'
  export default {
    name: 'Contract',
    components: {
      Pagination,
      addTemplete,
    },
    data() {
      return {
        typeOptions: CONTRACTTEMPLETETYPE.dict,
        options: [
          { value: 1, label: '启用' },
          { value: 0, label: '禁用' },
        ],
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          name: '',
          endDate: '',
          startDate: '',
          rechargeTime: [],
          status: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        direction: 'rtl',
        drawer: false,
        size: '',
        drawerData: {},
        ids: '',
        statusChangeLoading: false,
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      async onSwitchChange(row) {
        try {
          this.statusChangeLoading = true
          let res = await switchEsignContractPackage(row.id)
          if (res.code === 0) {
            row.status = !row.status
          }
        } finally {
          this.statusChangeLoading = false
        }
      },
      getList() {
        this.loading = true
        const { rechargeTime, ...params } = this.listQuery
        params.startDate =
          rechargeTime && rechargeTime.length
            ? dayjs(rechargeTime[0]).format('YYYY-MM-DD')
            : undefined
        params.endDate =
          rechargeTime && rechargeTime.length
            ? dayjs(rechargeTime[1]).format('YYYY-MM-DD')
            : undefined
        getEsignContractPackage(params).then((res) => {
          if (res.code === 0) {
            const { records, total } = res.data
            this.tableData = records.map((item) => ({
              ...item,
              status: item.status == 1,
            }))
            this.totalCount = +total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      handleAdd() {
        this.$refs.refAddTemplete.showModal()
      },
      handleEdit(row) {
        this.$refs.refAddTemplete.showModal(row)
      },
      handleCheckDetail(id) {
        console.log(id)
        this.$router.push({
          path: '/admin/house/contract/detail',
          query: { id },
        })
      },
      handleClose() {
        this.drawer = false
      },
      reset() {
        for (let key in this.listQuery) {
          this.listQuery[key] = ''
        }
        this.listQuery.pageNumber = 1
        this.listQuery.pageSize = 20
        this.getList()
      },
      handleDelete(row) {
        this.$confirm('是否删除该套餐?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          deleteEsignContractPackage(row.id).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.getList()
          })
        })
      },
      handleBindFiled() {},
    },
  }
</script>

<style lang="scss" scoped>
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .left {
      display: flex;
    }
  }
  ::v-deep .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: auto;
  }
</style>
