var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "custom-dialog",
      attrs: {
        "append-to-body": "",
        title: "编辑信息",
        visible: _vm.dialogFormVisible,
        width: "720px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v(" 保存 ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "editForm",
          staticStyle: { padding: "0 20px" },
          attrs: {
            "label-width": "100px",
            model: _vm.ruleForm,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "渠道商名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: 24, placeholder: "渠道商名称" },
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "name", $$v)
                          },
                          expression: "ruleForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "上级" } }, [
                    _vm.info.superiorDistributorName
                      ? _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.info.superiorDistributorName) + " "
                          ),
                        ])
                      : _c("span", { staticStyle: { color: "#999" } }, [
                          _vm._v("无上级"),
                        ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "申请时间" } }, [
                    _vm._v(" " + _vm._s(_vm.info.applyTime) + " "),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "申请通过时间" } }, [
                    _vm._v(" " + _vm._s(_vm.info.applySuccessTime) + " "),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号码", prop: "phone" } },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: true,
                          maxlength: 11,
                          placeholder: "手机号码",
                        },
                        model: {
                          value: _vm.ruleForm.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "phone", $$v)
                          },
                          expression: "ruleForm.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系地址", required: "" } },
                    [
                      _c(
                        "el-row",
                        { staticStyle: { width: "94%" } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "margin-bottom": "0",
                                  },
                                  attrs: { prop: "provinceCode" },
                                },
                                [
                                  _c("region-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "default-text":
                                        _vm.getRegionText("address"),
                                      enable: _vm.enablePicker,
                                      placeholder: "请选择省/市/区",
                                      prop: "address",
                                      value: [
                                        _vm.ruleForm.provinceCode,
                                        _vm.ruleForm.cityCode,
                                        _vm.ruleForm.districtCode,
                                      ],
                                    },
                                    on: { change: _vm.handleRegionChange },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "margin-bottom": "0",
                                    "margin-left": "10px",
                                  },
                                  attrs: { prop: "address" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: 64,
                                      placeholder: "xxx街道x号",
                                    },
                                    model: {
                                      value: _vm.ruleForm.address,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "address", $$v)
                                      },
                                      expression: "ruleForm.address",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "银行名称", prop: "bankName" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: 24, placeholder: "请输入银行名称" },
                        model: {
                          value: _vm.ruleForm.bankName,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "bankName", $$v)
                          },
                          expression: "ruleForm.bankName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "结算账户名", prop: "settleAccount" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: 24,
                          placeholder: "请输入结算账户名",
                        },
                        model: {
                          value: _vm.ruleForm.settleAccount,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "settleAccount", $$v)
                          },
                          expression: "ruleForm.settleAccount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "结算账户", prop: "settleAccountNo" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: 32, placeholder: "请输入结算账户" },
                        model: {
                          value: _vm.ruleForm.settleAccountNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "settleAccountNo", $$v)
                          },
                          expression: "ruleForm.settleAccountNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "地址/支行", required: "" } },
                    [
                      _c(
                        "el-row",
                        { staticStyle: { width: "94%" } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "margin-bottom": "0",
                                  },
                                  attrs: { prop: "openBankProvinceCode" },
                                },
                                [
                                  _c("region-city-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "default-text": _vm.getRegionText("bank"),
                                      enable: _vm.enablePicker,
                                      placeholder: "请选择省/市",
                                      prop: "bankAddress",
                                      value: [
                                        _vm.ruleForm.openBankProvinceCode,
                                        _vm.ruleForm.openBankCityCode,
                                      ],
                                    },
                                    on: { change: _vm.handleRegionChange },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "margin-bottom": "0",
                                    "margin-left": "10px",
                                  },
                                  attrs: { prop: "openBank" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: 32,
                                      placeholder: "支行名称",
                                    },
                                    model: {
                                      value: _vm.ruleForm.openBank,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "openBank", $$v)
                                      },
                                      expression: "ruleForm.openBank",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }