<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="90px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="商户名称">
            <el-input
              v-model="listQuery.shopName"
              clearable
              placeholder="商户名称"
            />
          </el-form-item>
          <el-form-item label="手机号">
            <el-input
              v-model="listQuery.phone"
              clearable
              placeholder="手机号"
            />
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="listQuery.status" clearable placeholder="状态">
              <el-option label="开票中" value="INVOICING" />
              <el-option label="已开票" value="COMPLETE" />
              <el-option label="已驳回" value="REJECT" />
            </el-select>
          </el-form-item>
          <el-form-item label="发票抬头">
            <el-input
              v-model="listQuery.title"
              clearable
              placeholder="发票抬头"
            />
          </el-form-item>
          <el-form-item label="费用类型">
            <el-select
              v-model="listQuery.costType"
              clearable
              placeholder="费用类型"
            >
              <el-option label="充值" value="RECHARGE" />
              <el-option label="账单" value="SERVICE_FEE" />
            </el-select>
          </el-form-item>
          <el-form-item label="申请时间">
            <el-date-picker
              v-model="creatTime"
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="getList"
            >
              查询
            </el-button>

            <el-button icon="el-icon-refresh-left" @click="searchReset">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>
    <el-table v-loading="loading" border :data="tableData" stripe>
      <el-table-column align="center" label="序号" width="50">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="商户名称"
        prop="shopName"
        width="120"
      />
      <el-table-column
        align="center"
        label="账单金额(元)"
        prop="amount"
        width="120"
      />
      <el-table-column align="center" label="开票类型" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.type == 'ORDINARY'">普通发票</span>
          <span v-if="scope.row.type == 'VAT'">增值税专用发票</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="抬头类型"
        prop="titleType"
        width="120"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.titleType == 'PERSONAL'">个人</span>
          <span v-if="scope.row.titleType == 'ENTERPRISE'">企业</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="费用类型"
        prop="costType"
        width="120"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.costType == 'RECHARGE'">充值</span>
          <span v-if="scope.row.costType == 'SERVICE_FEE'">账单</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="开票抬头"
        prop="title"
        width="120"
      />
      <el-table-column
        align="center"
        label="手机号码"
        prop="adminPhone"
        width="120"
      />
      <el-table-column align="center" label="状态" prop="status" width="120">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status == 'INVOICING'" type="pamary">
            开票中
          </el-tag>
          <el-tag v-if="scope.row.status == 'COMPLETE'" type="success">
            已开票
          </el-tag>
          <el-tag v-if="scope.row.status == 'REJECT'" type="danger">
            已驳回
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="申请时间"
        prop="createTime"
        width="200"
      />
      <el-table-column align="center" fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button
            :disabled="scope.row.status == 'INVOICING'"
            size="mini"
            type="text"
            @click="lookInvoice(scope.row)"
          >
            查看发票
          </el-button>
          <el-button
            :disabled="scope.row.status !== 'INVOICING'"
            size="mini"
            type="text"
            @click="invoicing(scope.row)"
          >
            开票
          </el-button>
          <el-button
            :disabled="scope.row.status !== 'INVOICING'"
            size="mini"
            type="text"
            @click="turnDown(scope.row)"
          >
            驳回
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>

    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />
    <TurnDownReasonVue
      v-if="turnDownVisible"
      :turn-down-visible.sync="turnDownVisible"
      @trunDownConfirm="trunDownConfirm"
    />
  </div>
</template>

<script>
  import {
    // makeInvoice,
    invoicePage,
    invoiceReject,
    // invoiceRemove,
    // invoiceRemoveBatch,
    // invoiceUpdate,
    // invoiceDetail,
  } from '@/api/financial/newIndex'
  import Pagination from '@/components/pagination.vue'
  import TurnDownReasonVue from './components/turnDownReason.vue'
  let dayjs = require('dayjs')
  export default {
    name: 'InvoiceManagement',
    components: {
      Pagination,
      TurnDownReasonVue,
    },
    data() {
      return {
        rowData: {},
        turnDownVisible: false,
        creatTime: null,
        listQuery: {
          shopName: '',
          title: '',
          costType: '',
          phone: '',
          status: '',
          pageNumber: 1,
          pageSize: 20,
        },
        totalCount: 0,
        tableData: [],
        loading: false,
      }
    },
    created() {
      this.getList()
    },
    methods: {
      trunDownConfirm(val) {
        invoiceReject({ rejectReason: val, id: this.rowData.id }).then(
          (res) => {
            if (res.code === 0) {
              this.turnDownVisible = false
              this.getList()
              return this.$message.success(res.msg)
            }
          }
        )
      },
      turnDown(row) {
        this.rowData = { ...row }
        this.turnDownVisible = true
      },
      invoicing(row) {
        this.$router.push({
          name: 'ManagementBill',
          params: { id: row.id },
        })
      },
      lookInvoice(row) {
        this.$router.push({
          name: 'ManagementBill',
          params: { id: row.id },
        })
      },
      searchReset() {
        this.listQuery = {
          shopName: '',
          title: '',
          costType: '',
          phone: '',
          status: '',
          pageNumber: 1,
          pageSize: 20,
        }
        this.creatTime = null
        this.getList()
      },
      //查询
      getList() {
        this.loading = true
        let params = {
          ...this.listQuery,
          startTime:
            this.creatTime !== null
              ? dayjs(this.creatTime[0]).format('YYYY-MM-DD')
              : '',
          endTime:
            this.creatTime !== null
              ? dayjs(this.creatTime[1]).format('YYYY-MM-DD')
              : '',
        }
        invoicePage(params).then((res) => {
          if (res.code === 0) {
            this.tableData = res.data.records
            this.totalCount = +res.data.total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-range-editor.el-input__inner {
    width: 105%;
  }
</style>
