<!-- 渠道商列表 -->
<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="searchForm"
          :inline="true"
          :model="searchForm"
          @submit.native.prevent
        >
          <el-form-item label="名称/上级名称" prop="keywords">
            <el-input
              v-model="searchForm.keywords"
              placeholder="请输入名称/上级名称"
            />
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="searchForm.phone" placeholder="请输入手机号" />
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select v-model="searchForm.status" placeholder="请选择状态">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQueryForm"
            >
              查询
            </el-button>
            <el-button
              icon="el-icon-refresh-left"
              native-type="submit"
              @click="resetQueryForm"
            >
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel :span="24">
        <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
          新增渠道商
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :cell-style="{ padding: '5px 8px' }"
      :data="tableData"
      stripe
    >
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          <span>
            {{ (queryForm.pageNumber - 1) * queryForm.pageSize + $index + 1 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="手机号"
        min-width="120"
        prop="phone"
      />
      <el-table-column align="center" label="名称" min-width="100" prop="name">
        <template #default="{ row }">
          {{ row.name || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="上级名称"
        min-width="100"
        prop="superiorDistributorName"
      >
        <template #default="{ row }">
          {{ row.superiorDistributorName || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="地址"
        min-width="220"
        prop="address"
      >
        <template #default="{ row }">
          {{ row.province || '' }}{{ row.city || '' }}{{ row.district || ''
          }}{{ row.address || '' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="下级数量"
        min-width="100"
        prop="lowerDistributorCount"
      />

      <el-table-column
        align="center"
        label="推广商户数"
        min-width="100"
        prop="shopCount"
      />
      <el-table-column
        align="center"
        label="获得佣金"
        min-width="120"
        prop="commissionAmount"
      >
        <template #default="{ row }">
          {{ (row.commission || 0).toFixed(2) }}
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="注册时间"
        prop="createTime"
        width="140"
      >
        <template #default="{ row }">
          {{ parseTime(row.createTime, '{y}-{m}-{d} {h}:{i}') }}
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        fixed="right"
        label="状态"
        min-width="80"
        prop="status"
      >
        <template #default="{ row }">
          <el-tooltip
            :content="row.status == 0 ? '点击开启' : '点击禁用'"
            :enterable="false"
            placement="top"
          >
            <el-switch
              v-model="row.status"
              :active-value="1"
              :disabled="statusChangeLoading"
              :inactive-value="0"
              @change="onSwitchChange(row)"
            />
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        fixed="right"
        label="操作"
        show-overflow-tooltip
        width="80"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">详情</el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="queryForm.pageSize"
      :page.sync="queryForm.pageNumber"
      :total-count="totalCount"
      @pagination="fetchData"
    />
    <distributor-add ref="add" @fetch-data="handleQueryForm" />
  </div>
</template>
<script>
  import {
    getDistributorPage,
    updateDistributorStatus,
  } from '@/api/distributor/index'
  import { parseTime } from '@/utils/index'
  import Pagination from '@/components/pagination.vue'
  import DistributorAdd from './components/DistributorAdd.vue'
  export default {
    name: 'DistributorManage',
    components: {
      Pagination,
      DistributorAdd,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          1: 'success',
          0: 'gray',
          2: 'danger',
        }
        return statusMap[status]
      },
      statusFilterName(status) {
        const statusNameMap = {
          0: '禁用',
          1: '开启',
        }
        return statusNameMap[status]
      },
    },
    data() {
      const searchForm = {
        phone: null,
        status: null,
        keywords: null,
      }
      return {
        searchForm,
        queryForm: {
          pageNumber: 1,
          pageSize: 20,
          ...searchForm,
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        options: [
          {
            label: '全部',
            value: '',
          },
          {
            label: '禁止',
            value: 0,
          },
          {
            label: '开启',
            value: 1,
          },
        ],
        statusChangeLoading: false,
      }
    },
    mounted() {
      this.fetchData()
    },
    methods: {
      handleQueryForm() {
        this.queryForm = Object.assign({}, this.queryForm, this.searchForm, {
          pageNumber: 1,
        })
        this.fetchData()
      },
      resetQueryForm() {
        this.$refs['searchForm'].resetFields()
        this.handleQueryForm()
      },
      fetchData() {
        this.loading = true
        getDistributorPage(this.queryForm).then((res) => {
          if (res.code === 0) {
            const { records, total } = res.data
            this.tableData = records
            this.totalCount = +total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      updateBatch() {},
      handleEdit(row) {
        this.$router.push({
          name: 'DistributorManageInfo',
          query: {
            id: row.id,
          },
        })
      },
      parseTime,
      async onSwitchChange(row) {
        this.statusChangeLoading = true
        let res = await updateDistributorStatus(row.id)
        this.statusChangeLoading = false
        if (res.code !== 0) {
          row.status = row.status === 0 ? 1 : 0
        }
      },
      handleAdd() {
        this.$refs['add'].showEdit()
      },
    },
  }
</script>

<style lang="scss" scoped>
  :deep() {
    .el-table .cell.el-tooltip {
      min-width: auto;
    }
    .el-table--border .el-table__cell:first-child .cell {
      padding: 0;
    }
    .el-table .cell {
      padding: 0;
    }
    .vab-query-form .el-form-item + .el-form-item {
      margin-left: 15px !important;
    }
  }
</style>
