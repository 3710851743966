<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="85px"
          :model="queryForm"
          @submit.native.prevent
        >
          <el-form-item label="商户类型">
            <el-select
              v-model="queryForm.merchantType"
              clearable
              placeholder="商户类型"
            >
              <el-option label="个体工商户" value="1" />
              <el-option label="企业" value="2" />
              <el-option label="个人(小微)" value="3" />
              <el-option label="政府事业单位" value="4" />
              <el-option label="其他组织" value="9" />
            </el-select>
          </el-form-item>
          <el-form-item label="业主名称">
            <el-input
              v-model="queryForm.shopName"
              clearable
              placeholder="业主名称"
            />
          </el-form-item>
          <el-form-item label="绑定时间">
            <el-date-picker
              v-model="queryForm.date"
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetForm('form')">
              重置
            </el-button>
            <!-- <el-button type="text" @click="handleFold">
              <span v-if="fold">展开</span>
              <span v-else>合并</span>
              <vab-icon
                class="vab-dropdown"
                :class="{ 'vab-dropdown-active': fold }"
                icon="arrow-up-s-line"
              />
            </el-button> -->
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @selection-change="setSelectRows"
    >
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          <span>
            {{ (listQuery.pageNumber - 1) * listQuery.pageSize + $index + 1 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作员名称"
        prop="meterWorkerName"
      />
      <el-table-column
        align="center"
        label="登录账号"
        prop="meterWorkerPhone"
      />
      <el-table-column align="center" label="业主名称" prop="shopName" />
      <el-table-column align="center" label="房源信息">
        <template slot-scope="scope">
          {{ scope.buildingName }}{{ scope.houseName }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="设备编号" prop="deviceNo" />
      <el-table-column align="center" label="设备类型" prop="typeName" />
      <el-table-column align="center" label="旧表读数" prop="lastMeterVal" />
      <el-table-column align="center" label="新表读数" prop="meterVal" />
      <el-table-column align="center" label="绑定时间" prop="bindTime" />
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="fetchData"
    />
  </div>
</template>

<script>
  import {
    SelectMerchant,
    PageMemberSmartDeviceBindLog,
  } from '@/api/device/devices-bind.js'
  import Pagination from '@/components/pagination.vue'

  export default {
    name: 'MemberBindlog',
    components: {
      Pagination,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        queryForm: {
          shopName: '',
          date: '',
        },
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        fold: false,
      }
    },
    beforeMount() {
      window.addEventListener('resize', this.handleHeight)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleHeight)
    },
    created() {
      this.fetchData()
      SelectMerchant().then((res) => {
        console.log(res)
      })
    },
    methods: {
      handleFold() {
        this.fold = !this.fold
        this.handleHeight()
      },
      handleHeight() {
        if (this.fold) this.height = this.$baseTableHeight(2) - 47
        else this.height = this.$baseTableHeight(3) - 30
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleQuery() {
        this.listQuery.pageNumber = 1
        this.fetchData()
      },
      async fetchData() {
        if (this.loading) return
        try {
          this.loading = true
          const { merchantType, shopName, date } = this.queryForm
          const {
            data: { records, total },
          } = await PageMemberSmartDeviceBindLog({
            merchantType,
            shopName,
            startDate: date[0],
            endDate: date[1],
            ...this.listQuery,
          })
          this.tableData = records
          this.totalCount = +total
        } finally {
          this.loading = false
        }
      },
      toggleSelection(rows) {
        if (rows) {
          rows.forEach((row) => {
            this.$refs.tableSort.toggleRowSelection(row)
          })
        } else {
          this.$refs.tableSort.clearSelection()
        }
      },
      resetForm(formName) {
        this.$refs[formName].resetFields()
        this.queryForm = this.$options.data().queryForm
        this.fetchData()
      },
    },
  }
</script>
