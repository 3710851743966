<template>
  <div class="grey-container">
    <div class="comprehensive-table-container user-data-box">
      <div class="user-data-item">
        <div class="item-title">合同余量</div>
        <div class="item-center">
          <div class="item-center-left">
            <img alt="" src="@/assets/data_overview/icon-daishenhe2.svg" />
            <el-tooltip
              class="item"
              :content="statistics.remaining"
              effect="dark"
              placement="top-start"
            >
              <span>{{ statistics.remaining }}</span>
            </el-tooltip>
          </div>
          <div class="alert-count">
            预警值
            <el-input v-model="esignContractAlertCount" placeholder="请输入" />
            <el-button type="primary" @click="updateALertCount">保存</el-button>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="user-data-item">
        <div class="item-title">累计已使用合同</div>
        <div class="item-center">
          <div class="item-center-left">
            <img alt="" src="@/assets/data_overview/icon-daishenhe2.svg" />
            <el-tooltip
              class="item"
              :content="statistics.usage"
              effect="dark"
              placement="top-start"
            >
              <span>{{ statistics.usage }}</span>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="form-table-container">
      <el-form label-width="80px" :model="listQuery" size="small">
        <el-row>
          <el-col
            :md="{ span: 6 }"
            :sm="{ span: 6 }"
            :xl="{ span: 6 }"
            :xs="{ span: 24 }"
          >
            <el-form-item label="商户名称">
              <el-input
                v-model="listQuery.shopName"
                clearable
                placeholder="商户名称"
              />
            </el-form-item>
          </el-col>
          <el-col
            :md="{ span: 6 }"
            :sm="{ span: 6 }"
            :xl="{ span: 6 }"
            :xs="{ span: 24 }"
          >
            <el-form-item label="登录账号">
              <el-input
                v-model="listQuery.loginAccount"
                clearable
                placeholder="登录账号"
              />
            </el-form-item>
          </el-col>
          <el-col
            :md="{ span: 6 }"
            :sm="{ span: 6 }"
            :xl="{ span: 6 }"
            :xs="{ span: 24 }"
          >
            <el-form-item>
              <el-button
                icon="el-icon-search"
                size="mini"
                type="primary"
                @click="getList"
              >
                查询
              </el-button>
              <el-button icon="el-icon-refresh-left" size="mini" @click="reset">
                重置
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table v-loading="loading" border :data="tableData" stripe>
        <el-table-column align="center" label="序号" width="50">
          <template slot-scope="scope">
            <span>
              {{
                (listQuery.pageNumber - 1) * listQuery.pageSize +
                scope.$index +
                1
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="商户名称"
          prop="shopName"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="登录账号"
          prop="loginAccount"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="合同余量"
          prop="contractRemainingCount"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="累计已使用份数"
          prop="accumulatedUsageCount"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="操作"
          prop="createTime"
          show-overflow-tooltip
        >
          <template #default="{ row }">
            <el-button
              size="mini"
              type="text"
              @click="handleRechargeRecord(row)"
            >
              充值记录
            </el-button>
            <el-button
              size="mini"
              type="text"
              @click="handleInitiateRecord(row)"
            >
              发起记录
            </el-button>
          </template>
        </el-table-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>
      <Pagination
        :limit.sync="listQuery.pageSize"
        :page.sync="listQuery.pageNumber"
        :total-count="totalCount"
        @pagination="getList"
      />
    </div>
    <initiateRecord ref="refInitiateRecord" />
    <rechargeRecord ref="refRechargeRecord" />
  </div>
</template>

<script>
  import { CONTRACTTEMPLETETYPE } from '@/utils/contract'
  import {
    getEsignContractUsage,
    getEsignContractRemaining,
    getEsignContractUsagePage,
    getEsignContractAlert,
    putEsignContractAlert,
  } from '@/api/esign/contract'
  import Pagination from '@/components/pagination.vue'
  import initiateRecord from './components/initiateRecord.vue'
  import rechargeRecord from './components/rechargeRecord.vue'
  export default {
    name: 'Contract',
    components: {
      Pagination,
      initiateRecord,
      rechargeRecord,
    },
    data() {
      return {
        typeOptions: CONTRACTTEMPLETETYPE.dict,
        options: [
          { value: true, label: '启用' },
          { value: false, label: '禁用' },
        ],
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          shopName: '',
          loginAccount: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        size: '',
        statistics: {
          usage: '0',
          remaining: '0',
        },
        esignContractAlertCount: '',
      }
    },
    mounted() {
      this.getList()
      this.getStatistics()
      this.getEsignContractAlertCount()
    },
    methods: {
      getStatistics() {
        getEsignContractUsage().then((res) => {
          this.statistics.usage = `${res.data || 0}`
        })
        getEsignContractRemaining().then((res) => {
          this.statistics.remaining = `${res.data.totalRemainingQuantity || 0}`
        })
      },
      getList() {
        this.loading = true
        getEsignContractUsagePage(this.listQuery).then((res) => {
          if (res.code === 0) {
            const { records, total } = res.data
            this.tableData = records.map((item) => ({
              ...item,
              houseTypeName: CONTRACTTEMPLETETYPE.getLabelByValue(
                item.houseType
              ),
            }))
            this.totalCount = +total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      reset() {
        for (let key in this.listQuery) {
          this.listQuery[key] = ''
        }
        this.listQuery.pageNumber = 1
        this.listQuery.pageSize = 20
        this.getList()
      },
      handleRechargeRecord(row) {
        this.$refs.refRechargeRecord.showModal(row.shopId)
      },
      handleInitiateRecord(row) {
        this.$refs.refInitiateRecord.showModal(row.shopId)
      },
      getEsignContractAlertCount() {
        getEsignContractAlert().then((res) => {
          this.esignContractAlertCount = res.data
        })
      },
      updateALertCount() {
        putEsignContractAlert(this.esignContractAlertCount).then(() => {
          this.$message({
            type: 'success',
            message: '操作成功!',
          })
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .grey-container {
    padding: 0 !important;
    background: $base-color-background !important;
  }
  .form-table-container {
    padding: 20px;
    background-color: white;
  }
  .alert-count {
    color: #e6a23c;
    display: inline-flex;
    align-items: center;
    margin-left: 30px;
    .el-input {
      width: 120px;
      margin: 0 10px;
    }
  }
  .user-data-box {
    display: flex;
    padding: 20px;
    background-color: white;
    margin-bottom: 20px;
    .user-data-item {
      flex: 1;
      box-sizing: border-box;
      padding: 0 17px 0 20px;
      .item-title {
        font-weight: 400;
        font-size: 16px;
        color: #666;
        margin-bottom: 13px;
      }
      .item-center {
        align-items: center;
        display: flex;
        margin-bottom: 14px;
        .item-center-left {
          align-items: center;
          display: flex;
          img {
            width: 30px;
            height: 30px;
            margin-right: 11px;
          }
          span {
            font-weight: 700;
            font-size: 25px;
            color: #1f1f1f;
            &::after {
              content: '份';
              font-size: 12px;
              font-weight: normal;
              display: inline-block;
              margin-left: 8px;
            }
          }
        }
        .item-center-right {
          font-weight: 400;
          font-size: 14px;
          color: #126dff;
          flex: 1;
          cursor: pointer;
          text-align: right;
          i {
            font-size: 8px;
          }
        }
      }
      .item-footer {
        font-weight: 400;
        font-size: 16px;
        color: #999;
        span {
          font-weight: 400;
          font-size: 16px;
          color: #34d1c9;
          margin: 0 6px;
        }
        i {
          color: #34d1c9;
        }
      }
    }
  }
</style>
