<template>
  <el-dialog
    append-to-body
    class="custom-dialog"
    title="添加渠道商"
    :visible.sync="dialogFormVisible"
    width="480px"
    @close="close"
  >
    <el-form
      ref="editForm"
      v-loading="loading"
      label-width="100px"
      :model="ruleForm"
      :rules="rules"
      style="padding: 0 20px"
    >
      <el-form-item label="手机号码" prop="phone">
        <el-input
          v-model="ruleForm.phone"
          clearable
          :maxlength="11"
          placeholder="手机号码"
        />
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input
          v-model="ruleForm.name"
          clearable
          :maxlength="24"
          placeholder="姓名"
        />
      </el-form-item>
      <el-form-item label="省市区" prop="provinceCode">
        <region-picker
          ref="region"
          :default-text="getRegionText('address')"
          :enable="enablePicker"
          placeholder="请选择省/市/区"
          style="width: 100%"
          @change="handleRegionChange"
        />
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input
          v-model="ruleForm.address"
          clearable
          :maxlength="64"
          placeholder="xxx街道x号"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="onSubmit">
        确定
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { getRegion } from '@/api/common/index'
  import { addDistributor, getDistributorPage } from '@/api/distributor/index'
  import RegionPicker from './RegionPicker.vue'

  export default {
    name: 'DistributorAdd',
    components: {
      RegionPicker,
    },
    data() {
      const validatorPhone = async function (rule, value, callback) {
        if (value === '') {
          callback(new Error('请输入手机号码'))
        } else if (!/^1\d{10}$/.test(value)) {
          callback(new Error('手机号格式错误'))
        } else {
          // 调用接口检测是否已被注册
          let res = await getDistributorPage({
            pageNumber: 1,
            pageSize: 1,
            phone: value,
          }).catch(() => {
            callback()
          })
          if (res && res.code === 0 && res.data.totalRow > 0) {
            callback('当前手机号已注册过渠道商，请更换手机号')
          } else {
            callback()
          }
        }
      }
      return {
        dialogFormVisible: false,
        loading: false,
        ruleForm: {
          id: null,
          name: '',
          phone: '',
          address: '',
          province: '',
          provinceCode: '',
          city: '',
          cityCode: '',
          district: '',
          districtCode: '',
        },
        rules: {
          name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
          phone: [
            { required: true, validator: validatorPhone, trigger: 'blur' },
          ],

          provinceCode: [
            { required: true, message: '请选择省市区', trigger: 'change' },
          ],
          address: [
            { required: true, message: '请输入详细地址', trigger: 'blur' },
          ],
        },
        regionOptions: [],
        info: {},
        props: {
          lazy: true,
          async lazyLoad(node, resolve) {
            console.log('node', node)
            const { level } = node
            let res = await getRegion(level === 0 ? 0 : node.value)
            if (res.code === 0) {
              const nodes = res.data.map((o) => {
                return {
                  label: o.name,
                  value: `${o.name},${o.code}`,
                  leaf: level >= 2,
                }
              })
              resolve(nodes)
            }
          },
        },
        enablePicker: true,
      }
    },
    methods: {
      async showEdit() {
        this.loading = false
        this.ruleForm = this.$options.data().ruleForm
        this.enablePicker = true
        this.dialogFormVisible = true
      },
      close() {
        this.dialogFormVisible = false
        setTimeout(() => {
          this.$refs.editForm.resetFields()
          // 重置地区选择
          this.enablePicker = false
        }, 0)
      },
      onSubmit() {
        this.$refs['editForm'].validate(async (valid) => {
          if (valid) {
            this.loading = true
            addDistributor(this.ruleForm)
              .then((res) => {
                this.loading = false
                if (res.code === 0) {
                  this.$baseMessage(
                    '保存成功',
                    'success',
                    'vab-hey-message-success'
                  )
                  this.$emit('fetch-data')
                  this.close()
                }
              })
              .catch((err) => {
                console.log('请求失败：', err && err.msg)
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            return false
          }
        })
      },
      handleRegionChange(e) {
        let { value } = e
        this.ruleForm = Object.assign({}, this.ruleForm, {
          province: value.province.name,
          provinceCode: value.province.code,
          city: value.city.name,
          cityCode: value.city.code,
          district: value.district.name,
          districtCode: value.district.code,
        })
        setTimeout(() => {
          this.$refs.editForm.validateField('provinceCode')
        }, 0)
      },
      getRegionText() {
        let { ruleForm } = this
        return (
          (ruleForm.province ? ruleForm.province : '') +
          (ruleForm.city ? ` / ${ruleForm.city}` : '') +
          (ruleForm.district ? ` / ${ruleForm.district}` : '')
        )
      },
    },
  }
</script>

<style lang="scss" scoped></style>
<style lang="scss">
  @import '../../../dialog.scss';

  .custom-dialog {
    @include setDialog();
  }
</style>
