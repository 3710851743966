<!-- 商户详情 -->
<template>
  <el-dialog
    append-to-body
    class="custom-dialog"
    stripe
    title="账单详情"
    :visible.sync="dialogFormVisible"
    width="720px"
    @close="close"
  >
    <el-descriptions :column="2" size="medium">
      <el-descriptions-item label="商户名称">
        {{ info.shopName || '--' }}
      </el-descriptions-item>
      <el-descriptions-item label="手机号">
        {{ info.shopPhone || '--' }}
      </el-descriptions-item>
      <el-descriptions-item label="地址" :span="2">
        {{ info.registerAddress || '--' }}
      </el-descriptions-item>
    </el-descriptions>
    <el-table
      v-loading="loading"
      border
      :cell-style="{ padding: '5px 8px' }"
      :data="tableData"
      max-height="300"
      style="margin-top: 10px"
    >
      <el-table-column
        align="center"
        label="账单号/流水号"
        min-width="140"
        prop="billId"
      >
        <template #default="{ row }">
          {{ row.id || '--' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="账单周期" min-width="160">
        <template #default="{ row }">
          {{ row.startDate ? parseTime(row.startDate, '{y}-{m}-{d}') : '--' }}
          -
          {{ row.endDate ? parseTime(row.endDate, '{y}-{m}-{d}') : '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="房源"
        min-width="100"
        prop="houseName"
      >
        <template #default="{ row }">
          {{ row.communityName }}{{ row.buildingName }}{{ row.houseName }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="状态"
        min-width="100"
        prop="houseName"
      >
        <template #default="{ row }">
          {{ row.status | statusFilterName }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="账单金额(元)"
        min-width="90"
        prop="receivableAmount"
      >
        <template #default="{ row }">
          {{ (row.receivableAmount || 0).toFixed(2) }}
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNumber"
      layout="prev, pager, next"
      :page-size="queryForm.pageSize"
      small
      :total="totalCount"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <el-button
      class="btn-merchant-info"
      plain
      type="primary"
      @click="toMerchantInfo"
    >
      商户详情
    </el-button>
  </el-dialog>
</template>

<script>
  import { getList } from '@/api/bill/list'
  import { parseTime } from '@/utils/index'

  export default {
    name: 'ShopBillInfo',
    filters: {
      statusFilterName(status) {
        const statusNameMap = {
          0: '已完成',
          20: '待回款',
          30: '待生效',
          40: '已逾期',
          50: '已作废',
        }
        return statusNameMap[status]
      },
    },
    data() {
      return {
        dialogFormVisible: false,
        info: {},
        parseTime,
        loading: false,
        queryForm: {
          pageNumber: 1,
          pageSize: 20,
          subtotalId: null,
        },
        totalCount: 0,
        tableData: [],
        QiniuData: {
          key: '', //图片名字处理
          token: '', //七牛云token
          data: {},
        },
        domain: '', // 七牛云的上传地址（华东区）
        uploadUrl: '', //提交到后台图片地址
        fileList: [],
        urlList: [
          /** {uid, url} */
        ],
      }
    },
    created() {},
    methods: {
      async showEdit(row) {
        this.info = row
        this.tableData = []
        this.dialogFormVisible = true
        this.queryForm = {
          ...this.queryForm,
          pageNumber: 1,
          shopId: row.shopId,
        }
        this.fetchData()
      },
      fetchData() {
        this.loading = true
        getList({
          ...this.queryForm,
        }).then((res) => {
          if (res.code === 0) {
            const { list, totalRow } = res.data
            this.tableData = list
            this.totalCount = totalRow
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      close() {
        this.dialogFormVisible = false
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNumber = val
        this.fetchData()
      },
      toMerchantInfo() {
        this.$router.push({
          name: 'DistributorMerchantDeatail',
          query: {
            id: this.info.shopId,
          },
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .btn-merchant-info {
    position: absolute;
    top: 70px;
    right: 20px;
  }
</style>
<style lang="scss">
  @import '../../../dialog.scss';

  .custom-dialog {
    @include setDialog();
  }
</style>
