var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detail-container" },
    [
      _c("el-page-header", {
        attrs: { content: "渠道商详情" },
        on: { back: _vm.goBack },
      }),
      _c(
        "el-button",
        {
          attrs: {
            disabled: _vm.loading,
            icon: "el-icon-edit",
            type: "primary",
          },
          on: { click: _vm.showEdit },
        },
        [_vm._v(" 修改信息 ")]
      ),
      _c(
        "el-descriptions",
        {
          staticClass: "margin-top",
          attrs: {
            border: "",
            column: 2,
            "content-style": { "work-break": "break-all" },
            "label-style": { width: "160px", "word-break": "keep-all" },
            size: "medium",
          },
        },
        [
          _c(
            "el-descriptions-item",
            { attrs: { label: "渠道商名称" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "margin-right": "10px",
                    "line-height": "20px",
                    display: "inline-block",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.info.name || "--") + " ")]
              ),
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: _vm.info.status == 0 ? "点击开启" : "点击禁用",
                    enterable: false,
                    placement: "top",
                  },
                },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": 1,
                      disabled: _vm.statusChangeLoading,
                      "inactive-value": 0,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onSwitchChange(_vm.row)
                      },
                    },
                    model: {
                      value: _vm.info.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "status", $$v)
                      },
                      expression: "info.status",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "上级" } },
            [
              _vm.info.superiorDistributorName
                ? _c(
                    "el-link",
                    {
                      staticStyle: {
                        "margin-right": "30px",
                        cursor: "default",
                      },
                      attrs: { type: "primary" },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.info.superiorDistributorName) + " "
                      ),
                    ]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.onChangeSuperior },
                    },
                    [_vm._v(" 设置上级 ")]
                  ),
              _vm.info.superiorDistributorName
                ? [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.onChangeSuperior },
                      },
                      [_vm._v(" 修改上级 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "danger" },
                        on: { click: _vm.removeSuperior },
                      },
                      [_vm._v(" 移除 ")]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c("el-descriptions-item", { attrs: { label: "申请时间" } }, [
            _vm._v(" " + _vm._s(_vm.info.applyTime || "--") + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "申请通过时间" } }, [
            _vm._v(" " + _vm._s(_vm.info.applySuccessTime || "--") + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "手机号" } }, [
            _vm._v(" " + _vm._s(_vm.info.phone) + " "),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "分佣配置" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.showSetting },
                },
                [_vm._v(" 查看/修改 ")]
              ),
            ],
            1
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "联系地址", span: 2 } },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.info.province || "") +
                  _vm._s(_vm.info.city || "") +
                  _vm._s(_vm.info.district || "") +
                  _vm._s(_vm.info.address || "") +
                  " "
              ),
            ]
          ),
          _c("el-descriptions-item", { attrs: { label: "银行名称" } }, [
            _vm._v(" " + _vm._s(_vm.info.bankName || "--") + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "结算账户名" } }, [
            _vm._v(" " + _vm._s(_vm.info.settleAccount || "--") + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "结算账户" } }, [
            _vm._v(" " + _vm._s(_vm.info.settleAccountNo || "--") + " "),
          ]),
          _vm.info.openBankProvince
            ? _c(
                "el-descriptions-item",
                { attrs: { label: "地址/支行", span: 2 } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.info.openBankProvince || "") +
                      _vm._s(_vm.info.openBankCity || "") +
                      _vm._s(_vm.info.openBank || "") +
                      " "
                  ),
                ]
              )
            : _c(
                "el-descriptions-item",
                { attrs: { label: "地址/支行", span: 2 } },
                [_vm._v(" -- ")]
              ),
        ],
        1
      ),
      _c(
        "el-descriptions",
        {
          staticClass: "margin-top",
          attrs: {
            border: "",
            column: 2,
            "content-style": { "work-break": "break-all" },
            "label-style": { width: "160px", "word-break": "keep-all" },
            size: "medium",
          },
        },
        [
          _c("el-descriptions-item", { attrs: { label: "推广商户数" } }, [
            _vm._v(" " + _vm._s(_vm.info.shopCount) + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "佣金收入(元)" } }, [
            _vm._v(
              " " + _vm._s((_vm.info.commissionAmount || 0).toFixed(2)) + " "
            ),
          ]),
          _c("el-descriptions-item", { attrs: { label: "下级数" } }, [
            _vm._v(" " + _vm._s(_vm.info.lowerDistributorCount) + " "),
          ]),
          _c("el-descriptions-item"),
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          staticStyle: { "margin-top": "30px" },
          attrs: { type: "border-card" },
          model: {
            value: _vm.tabs,
            callback: function ($$v) {
              _vm.tabs = $$v
            },
            expression: "tabs",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "推广商户", name: "1" } },
            [
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _c("ShopTable", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tabs === "1",
                        expression: "tabs === '1'",
                      },
                    ],
                    attrs: { id: _vm.id },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "下级列表", name: "2" } },
            [
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _c("SecondarTable", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tabs === "2",
                        expression: "tabs === '2'",
                      },
                    ],
                    attrs: { id: _vm.id },
                    on: { "fetch-data": _vm.setInfo },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "收入记录", name: "3" } },
            [
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _c("CommissionTable", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tabs === "3",
                        expression: "tabs === '3'",
                      },
                    ],
                    attrs: { id: _vm.id },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "结算记录", name: "4" } },
            [
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _c("BillTable", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tabs === "4",
                        expression: "tabs === '4'",
                      },
                    ],
                    attrs: { id: _vm.id },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("distributor-select", {
        ref: "distributorSelect",
        on: { "fetch-data": _vm.setInfo },
      }),
      _c("commission-setting", {
        ref: "commissionSetting",
        attrs: { id: _vm.id },
      }),
      _c("distributor-edit", {
        ref: "distributorEdit",
        on: { "fetch-data": _vm.setInfo },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }