var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "custom-dialog",
      attrs: {
        "append-to-body": "",
        title: "分佣单详情",
        visible: _vm.dialogFormVisible,
        width: "720px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-descriptions",
        { attrs: { border: "", column: 2, size: "medium" } },
        [
          _c("el-descriptions-item", { attrs: { label: "渠道商" } }, [
            _vm._v(" " + _vm._s(_vm.info.distributorName || "--") + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "渠道商手机号" } }, [
            _vm._v(" " + _vm._s(_vm.info.distributorPhone) + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "流水号" } }, [
            _vm._v(" " + _vm._s(_vm.info.settleNo) + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "生成时间" } }, [
            _vm._v(" " + _vm._s(_vm.info.createTime || "--") + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "结算状态" } }, [
            _vm._v(
              " " + _vm._s(_vm._f("statusFilterName")(_vm.info.status)) + " "
            ),
          ]),
          _c("el-descriptions-item", { attrs: { label: "结算时间" } }, [
            _vm._v(" " + _vm._s(_vm.info.settleTime || "--") + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "佣金来源" } }, [
            _vm._v(
              " " + _vm._s(_vm._f("sourceFilterName")(_vm.info.source)) + " "
            ),
          ]),
          _c("el-descriptions-item", { attrs: { label: "佣金金额(元)" } }, [
            _c("span", { staticStyle: { color: "#f56c6c" } }, [
              _vm._v(
                " " + _vm._s((_vm.info.commissionAmount || 0).toFixed(2)) + " "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { "margin-top": "20px" },
          attrs: {
            border: "",
            "cell-style": { padding: "5px 8px" },
            data: [_vm.info],
            stripe: "",
          },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png"),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "来源账单号/流水号",
              "min-width": "140",
              prop: "billId",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_vm._v(" " + _vm._s(row.billId || "--") + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "类型",
              "min-width": "100",
              prop: "orderType",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.orderTypeFilterName(row.orderType) || "--") +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "来源商户",
              "min-width": "160",
              prop: "shopName",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(row.shopName || "--") +
                        " " +
                        _vm._s(row.shopPhone) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "账单金额(元)",
              "min-width": "90",
              prop: "billAmount",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " + _vm._s((row.billAmount || 0).toFixed(2)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "佣金金额(元)",
              "min-width": "90",
              prop: "commissionAmount",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " + _vm._s((row.commissionAmount || 0).toFixed(2)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }