<template>
  <Dialog
    :height="dailog.height"
    :model-value="dailog.visible"
    :title="title + dailog.title"
    :visible="dailog.visible"
    :width="dailog.width"
    @handleCloseDialog="handleCloseDialog"
    @handleConfirm="handleConfirm"
  >
    <template #content>
      <el-form
        ref="form"
        label-width="168px"
        :model="formData"
        :rules="rules"
        size="small"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="" prop="sortRank">
              <!-- <el-input
                v-model="formData.sortRank"
                clearable
                :disabled="disabled"
                placeholder="请输入..."
                style="max-width: 300px"
              /> -->
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="设备品牌编号" prop="number">
              <el-input
                v-model="formData.number"
                clearable
                :disabled="disabled"
                placeholder="请输入..."
                style="max-width: 300px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="设备品牌名称" prop="name">
              <el-input
                v-model="formData.name"
                clearable
                :disabled="disabled"
                placeholder="请输入..."
                style="max-width: 300px"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </template>
  </Dialog>
</template>

<script>
  import { translateTitle } from '@/utils/i18n'
  import {
    SaveDeviceBrand,
    UpdateDeviceBrand,
    DetailDeviceBrand,
  } from '@/api/device/brand'
  import Dialog from '@/components/dialog.vue'
  // import { isNumber } from '@/utils/validate'

  export default {
    name: 'TableEdit',
    components: {
      Dialog,
    },
    data() {
      /* const validateNumber = (rule, value, callback) => {
        if (!isNumber(value)) {
          callback(new Error(this.translateTitle('请输入数字')))
        } else {
          callback()
        }
      } */
      return {
        title: '添加',
        dailog: {
          height: 200,
          title: '设备品牌',
          visible: false,
          width: '600px',
        },
        disabled: false,
        formData: {
          sortRank: '',
          number: '',
          name: '',
        },
        rules: {
          /* sortRank: [
            {
              required: true,
              message: '请输入序号',
              trigger: 'blur',
            },
            { validator: validateNumber, trigger: 'blur' },
          ], */
          number: [
            {
              required: true,
              message: '请输入设备品牌编号',
              trigger: 'blur',
            },
          ],
          name: [
            {
              required: true,
              message: '请输入设备品牌名称',
              trigger: 'blur',
            },
          ],
        },
        id: '',
        loadingSure: false,
      }
    },
    created() {},
    methods: {
      translateTitle,
      showEdit(row) {
        if (!row) {
          this.title = '添加'
        } else {
          this.title = '编辑'
          this.fetchData(row.id)
        }
        this.dailog.visible = true
      },
      async fetchData(id) {
        const {
          data: { sortRank, name, number },
        } = await DetailDeviceBrand({ id })
        this.id = id
        this.formData.sortRank = sortRank
        this.formData.name = name
        this.formData.number = number
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.$refs['form'].resetFields()
        this.formData = this.$options.data().formData
      },
      handleConfirm() {
        this.$refs['form'].validate(async (valid) => {
          if (valid && !this.loadingSure) {
            try {
              this.loadingSure = true
              if (this.title == '添加') {
                await SaveDeviceBrand(this.formData)
              } else {
                await UpdateDeviceBrand({
                  ...this.formData,
                  ...{ id: this.id },
                })
              }
              this.$baseMessage(
                this.title + '成功',
                'success',
                'vab-hey-message-success'
              )
              this.$emit('fetch-data')
              this.handleCloseDialog()
            } finally {
              this.loadingSure = false
            }
          }
        })
      },
    },
  }
</script>
