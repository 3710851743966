<template>
  <Dialog
    :height="dailog.height"
    :model-value="dailog.visible"
    :title="dailog.title"
    :visible="dailog.visible"
    :width="dailog.width"
    @handleCloseDialog="handleCloseDialog"
    @handleConfirm="handleCloseDialog"
  >
    <template #content>
      <el-tabs v-model="activeName" @tab-click="handleQuery">
        <el-tab-pane label="电表" name="electric" />
        <el-tab-pane label="水表" name="water" />
      </el-tabs>
      <vab-query-form>
        <vab-query-form-top-panel>
          <el-form
            ref="form"
            :inline="true"
            label-width="80px"
            :model="queryForm"
            @submit.native.prevent
          >
            <el-form-item label="抄表方式">
              <el-select
                v-model="queryForm.readType"
                clearable
                placeholder="抄表方式"
              >
                <el-option
                  v-for="item in MeterMethod"
                  :key="'MeterMethod' + item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="抄表场景">
              <el-select
                v-model="queryForm.readReason"
                clearable
                placeholder="抄表场景"
              >
                <el-option
                  v-for="item in MeterReasons"
                  :key="'MeterReasons' + item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="抄表时间">
              <el-date-picker
                v-model="queryForm.date"
                end-placeholder="结束日期"
                range-separator="至"
                start-placeholder="开始日期"
                type="daterange"
              />
            </el-form-item>
            <el-form-item>
              <el-button
                icon="el-icon-search"
                native-type="submit"
                type="primary"
                @click="handleQuery"
              >
                查询
              </el-button>
              <el-button icon="el-icon-refresh-left" @click="resetForm('form')">
                重置
              </el-button>
              <!-- <el-button type="text" @click="handleFold">
              <span v-if="fold">展开</span>
              <span v-else>合并</span>
              <vab-icon
                class="vab-dropdown"
                :class="{ 'vab-dropdown-active': fold }"
                icon="arrow-up-s-line"
              />
            </el-button> -->
            </el-form-item>
          </el-form>
        </vab-query-form-top-panel>
      </vab-query-form>

      <el-table
        v-loading="loading"
        border
        :data="tableData"
        stripe
        @selection-change="setSelectRows"
      >
        <el-table-column align="center" type="selection" width="50" />
        <el-table-column align="center" label="抄表序号" width="85">
          <template #default="{ $index }">
            <span>
              {{ (listQuery.pageNumber - 1) * listQuery.pageSize + $index + 1 }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          :label="`总用${activeName === 'electric' ? '电' : '水'}量`"
          prop="totalUsedVal"
          sortable
        />
        <el-table-column
          align="center"
          :label="`剩余${activeName === 'electric' ? '电' : '水'}量`"
          prop="surplusVal"
        />
        <el-table-column align="center" label="上期读数" prop="lastMeterVal">
          <template slot-scope="scope">
            {{ scope.row.lastMeterVal == null ? '-' : scope.row.lastMeterVal }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="本期读数"
          prop="meterVal"
          sortable
        />
        <el-table-column align="center" label="用量" prop="usedVal" />
        <el-table-column align="center" label="抄表场景" prop="readReason">
          <template slot-scope="scope">
            {{ returnLabel(scope.row.readReason) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="抄表方式"
          prop="readType"
          width="85"
        >
          <template slot-scope="scope">
            {{ scope.row.readType === 1 ? '自动获取' : '手动录入' }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="入账状态"
          prop="readType"
          width="85"
        >
          <template slot-scope="scope">
            {{ scope.row.isUsed === 1 ? '已入账' : '未入账' }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="抄表时间"
          prop="meterDate"
          width="180"
        >
          <template slot-scope="scope">
            {{ scope.row.meterDate || '-' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="是否修改" width="180">
          <template slot-scope="scope">
            {{ scope.row.isUpdatedMeterVal ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="更新时间"
          prop="lastUpdatedMeterValTime"
          width="180"
        />
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>
      <Pagination
        :limit.sync="listQuery.pageSize"
        :page.sync="listQuery.pageNumber"
        :total-count="totalCount"
        @pagination="fetchData"
      />
    </template>
  </Dialog>
</template>

<script>
  import dayjs from 'dayjs'
  import Dialog from '@/components/dialog.vue'
  import { MeterMethod, MeterReasons, MeterSubject } from '@/utils/constant'
  import { PageHouseMeterRecord } from '@/api/device/merchant'
  import Pagination from '@/components/pagination.vue'

  export default {
    name: 'MeterLog',
    components: {
      Dialog,
      Pagination,
    },
    data() {
      return {
        dailog: {
          height: null,
          title: '抄表记录',
          visible: false,
          width: '1350px',
        },
        id: '',
        activeName: 'electric',
        MeterMethod,
        MeterReasons,
        queryForm: {
          readType: '',
          readReason: '',
          date: [],
        },
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        fold: false,
      }
    },
    beforeMount() {
      window.addEventListener('resize', this.handleHeight)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleHeight)
    },
    methods: {
      dayjs,
      setSelectRows(val) {
        this.selectRows = val
      },
      showView(id, activeName) {
        this.id = id
        this.dailog.visible = true
        this.listQuery.pageNumber = 1
        this.activeName = activeName
        this.fetchData()
      },
      handleFold() {
        this.fold = !this.fold
        this.handleHeight()
      },
      handleHeight() {
        if (this.fold) this.height = this.$baseTableHeight(2) - 47
        else this.height = this.$baseTableHeight(3) - 30
      },
      handleQuery() {
        this.listQuery.pageNumber = 1
        this.fetchData()
      },
      async fetchData() {
        if (this.loading) return
        try {
          this.loading = true
          const { readType, readReason, date } = this.queryForm
          const {
            data: { records, total },
          } = await PageHouseMeterRecord({
            ...this.listQuery,
            readType: readType === 'all' ? '' : readType,
            readReason: readReason === 'all' ? '' : readReason,
            startMeterDate: date[0] ? dayjs(date[0]).format('YYYY-MM-DD') : '',
            endMeterDate: date[1] ? dayjs(date[1]).format('YYYY-MM-DD') : '',
            subjectId: MeterSubject[this.activeName],
            houseId: this.id,
          })
          this.tableData = records
          this.totalCount = +total
        } finally {
          this.loading = false
        }
      },
      toggleSelection(rows) {
        if (rows) {
          rows.forEach((row) => {
            this.$refs.tableSort.toggleRowSelection(row)
          })
        } else {
          this.$refs.tableSort.clearSelection()
        }
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.$refs['form'].resetFields()
        this.queryForm = this.$options.data().queryForm
      },
      returnLabel(value) {
        return (
          this.MeterReasons.find((item) => item.value == value)?.label || '--'
        )
      },
      resetForm(formName) {
        this.$refs[formName].resetFields()
        this.queryForm = this.$options.data().queryForm
        this.fetchData()
      },
    },
  }
</script>

<style scoped lang="scss">
  ::v-deep(.container) {
    padding: 0 20px;
  }
</style>
