<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="90px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="退款流水号">
            <el-input
              v-model="listQuery.refundNo"
              clearable
              placeholder="退款流水号"
            />
          </el-form-item>
          <el-form-item label="业务订单ID">
            <el-input
              v-model="listQuery.orderId"
              clearable
              placeholder="业务订单ID"
            />
          </el-form-item>

          <el-form-item label="退款状态">
            <el-select
              v-model="listQuery.isRefund"
              clearable
              placeholder="退款状态"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="getList"
            >
              查询
            </el-button>

            <el-button icon="el-icon-refresh-left" @click="searchReset">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel :span="24">
        <!-- <el-button icon="el-icon-refresh" type="primary" @click="refresh">
          刷新
        </el-button> -->
      </vab-query-form-left-panel>
    </vab-query-form>

    <!-- <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input
            v-model="listQuery.refundNo"
            clearable
            placeholder="退款流水号"
          />
        </el-col>
        <el-col :span="6">
          <el-input
            v-model="listQuery.orderId"
            clearable
            placeholder="业务订单ID"
          />
        </el-col>

        <el-col :span="6">
          <el-select
            v-model="listQuery.isRefund"
            clearable
            placeholder="退款状态"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-col>

        <el-button icon="el-icon-search" type="primary" @click="getList">
          查询
        </el-button>
      </el-row>

      <el-row style="margin-top: 10px; margin-bottom: 10px">
        <el-button icon="el-icon-refresh" type="primary" @click="refresh">
          刷新
        </el-button>
      </el-row> -->

    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @select="selectRow"
      @select-all="selectRow"
    >
      <!-- <el-table-column align="center" type="selection" width="50" /> -->
      <el-table-column align="center" label="序号" width="50">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="支付记录"
        prop="payId"
        width="120"
      />
      <el-table-column
        align="center"
        label="退款流水号"
        prop="refundNo"
        width="120"
      />
      <el-table-column
        align="center"
        label="交易订单类型"
        prop="orderType"
        width="120"
      />
      <el-table-column
        align="center"
        label="业务订单ID"
        prop="orderId"
        width="120"
      />
      <el-table-column
        align="center"
        label="退款类型"
        prop="refundType"
        width="120"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.refundType === 1">交易订单取消</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="是否全额退款"
        prop="isFullRefund"
        width="120"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isFullRefund" type="success">是</el-tag>
          <el-tag v-else type="danger">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="退款金额"
        prop="refundAmount"
        width="120"
      />
      <el-table-column
        align="center"
        label="退款状态"
        prop="isRefund"
        width="120"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isRefund == 1" type="success">已退款</el-tag>
          <el-tag v-else>未退款</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        width="200"
      />
      <el-table-column
        align="center"
        label="退款时间"
        prop="refundTime"
        width="200"
      />
      <el-table-column align="center" fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleDetail(scope.row)">
            详情
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>

    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />

    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-descriptions border class="margin-top" :column="2" size="medium">
          <el-descriptions-item>
            <template slot="label">支付记录</template>
            {{ formData.payId }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">退款流水号</template>
            {{ formData.refundNo }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">交易订单类型</template>
            {{ formData.orderType }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">业务订单</template>
            {{ formData.orderId }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">退款类型</template>
            {{ formData.refundType == 1 ? '交易订单取消	' : '' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">是否全额退款</template>
            {{ formData.isFullRefund ? '是	' : '否' }}
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </Dialog>
    <!-- </el-card> -->
  </div>
</template>

<script>
  import { refundlogList } from '@/api/refundlog/refundlog'
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialog.vue'
  export default {
    name: 'Refund',
    components: {
      Pagination,
      Dialog,
    },
    data() {
      return {
        disabled: false,

        dailog: {
          height: 140,
          title: '',
          visible: false,
          width: '700px',
        },
        formData: {
          parentName: '',
          name: '',
          pid: '',
          path: '',
          type: null, // 1菜单 2按钮
          icon: '',
          time: '',
        },

        ddd: 'zc',
        pay: [
          {
            value: '',
            label: '请选择消费类型',
          },
          {
            value: '1',
            label: '充值',
          },
          {
            value: '2',
            label: '扣费',
          },
        ],
        options: [
          {
            value: '0',
            label: '未退款',
          },
          {
            value: '1',
            label: '已退款',
          },
        ],
        value: '',
        payType: '',
        businessType: '',
        input: '',
        listQuery: {
          name: '',
          pageNumber: 1,
          pageSize: 20,
        },
        totalCount: 0,
        tableData: [],
        tableData1: [
          {
            date: '2016-05-02',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1518 弄',
          },
        ],
        loading: false,
        selection: [],
      }
    },
    created() {
      this.getList()
    },
    methods: {
      searchReset() {
        this.listQuery = {}
        this.getList()
      },
      //查询
      getList() {
        this.loading = true
        refundlogList(this.listQuery).then((res) => {
          if (res.code === 0) {
            console.log('res>>>', res.data)
            this.tableData = res.data.records
            this.totalCount = +res.data.total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },

      getList1() {},
      //表格选择
      selectRow(selection, row) {
        this.selection = selection
        console.log('单选===', selection)
        console.log('单选===', row)
      },

      //编辑
      handleDetail(row) {
        this.dailog.visible = true
        this.dailog.title = '退款记录详情'
        this.$nextTick(() => {
          this.formData = JSON.parse(JSON.stringify(row))
        })
      },
      handleCloseDialog() {
        this.dailog.visible = false
      },
      // 确认新增
      handleConfirm() {
        this.dailog.visible = false
      },
      //添加商品信息
      addInfo() {},

      handleRead() {},

      //删除
      deleteRow() {
        if (this.selection.length == 0) {
          this.$message({
            message: '请至少选择一行数据',
            type: 'warning',
          })
          return false
        }

        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
          })
          .catch(() => {})
      },

      //刷新
      refresh() {
        this.getList()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-range-editor.el-input__inner {
    width: 105%;
  }
</style>
