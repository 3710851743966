<!-- 渠道商申请记录 -->
<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="queryForm"
          :inline="true"
          :model="queryForm"
          @submit.native.prevent
        >
          <el-form-item label="姓名/邀请人" prop="keywords">
            <el-input
              v-model="queryForm.keywords"
              placeholder="请输入姓名/邀请人"
            />
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="queryForm.phone" placeholder="请输入手机号" />
          </el-form-item>
          <el-form-item label="审核状态" prop="status">
            <el-select v-model="queryForm.status" placeholder="请选择审核状态">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQueryForm"
            >
              查询
            </el-button>
            <el-button
              icon="el-icon-refresh-left"
              native-type="submit"
              @click="resetQueryForm"
            >
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel :span="24">
        <el-button icon="el-icon-edit" type="primary" @click="updateBatch">
          批量通过
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :cell-style="{ padding: '5px 8px' }"
      :data="tableData"
      stripe
      @selection-change="setSelectRows"
    >
      <el-table-column
        align="center"
        :selectable="
          (row) => {
            return row.status === 0
          }
        "
        show-overflow-tooltip
        type="selection"
        width="55"
      />
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          <span>
            {{ (queryForm.pageNumber - 1) * queryForm.pageSize + $index + 1 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="手机号"
        min-width="120"
        prop="phone"
      />
      <el-table-column
        align="center"
        label="姓名"
        min-width="100"
        prop="name"
      />
      <el-table-column
        align="center"
        label="地址"
        min-width="220"
        prop="address"
      >
        <template #default="{ row }">
          {{ row.province || '' }}{{ row.city || '' }}{{ row.district || ''
          }}{{ row.address || '' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="推荐人"
        min-width="100"
        prop="recommendDistributorName"
      >
        <template #default="{ row }">
          {{ row.recommendDistributorName || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="推荐人手机号码"
        min-width="120"
        prop="recommendDistributorPhone"
      >
        <template #default="{ row }">
          {{ row.recommendDistributorPhone || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="申请时间"
        prop="createTime"
        width="140"
      >
        <template #default="{ row }">
          {{
            row.createTime
              ? parseTime(row.createTime, '{y}-{m}-{d} {h}:{i}')
              : '--'
          }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="通过时间"
        prop="auditTime"
        width="140"
      >
        <template #default="{ row }">
          {{
            row.status === 1 && row.auditTime
              ? parseTime(row.auditTime, '{y}-{m}-{d} {h}:{i}')
              : '--'
          }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="审核意见"
        min-width="200"
        prop="comment"
      >
        <template #default="{ row }">
          <!-- status:0 待审核 1已同意 2审核不通过 -->
          <span v-if="row.status === 0">--</span>
          <span v-if="row.status === 1">已同意</span>
          <span v-if="row.status === 2">{{ row.comment }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="审核状态"
        min-width="80"
        prop="status"
      >
        <template #default="{ row }">
          <el-tag :type="row.status | statusFilter">
            {{ row.status | statusFilterName }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        fixed="right"
        label="操作"
        show-overflow-tooltip
        width="100"
      >
        <template #default="{ row }">
          <el-button
            :disabled="row.status !== 0"
            type="text"
            @click="handleEdit(row, statusOpt.pass)"
          >
            通过
          </el-button>
          <el-button
            :disabled="row.status !== 0"
            type="text"
            @click="handleEdit(row, statusOpt.reject)"
          >
            不通过
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="queryForm.pageSize"
      :page.sync="queryForm.pageNumber"
      :total-count="totalCount"
      @pagination="fetchData"
    />
    <audit-edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>
<script>
  import {
    getChannelApplyPage,
    postChannelApplyAuditBatch,
  } from '@/api/distributor/apply'
  import { parseTime } from '@/utils/index'
  import Pagination from '@/components/pagination.vue'
  import AuditEdit from './components/AuditEdit.vue'

  export default {
    name: 'DistributorManageApply',
    components: {
      Pagination,
      AuditEdit,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          0: '',
          1: 'success',
          2: 'danger',
        }
        return statusMap[status]
      },
      statusFilterName(status) {
        const statusNameMap = {
          0: '待审核',
          1: '通过',
          2: '未通过',
        }
        return statusNameMap[status]
      },
    },
    data() {
      return {
        queryForm: {
          pageNumber: 1,
          pageSize: 20,
          phone: null,
          status: '',
          keywords: null,
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        options: [
          {
            label: '全部',
            value: '',
          },
          {
            label: '待审核',
            value: 0,
          },
          {
            label: '通过',
            value: 1,
          },
          {
            label: '未通过',
            value: 2,
          },
        ],
        statusOpt: {
          pass: 1,
          reject: 2,
        },
        selectRows: [],
      }
    },

    mounted() {
      if (this.$route.query?.status == 0) {
        this.queryForm.status = 0
      }
      this.fetchData()
    },
    methods: {
      handleQueryForm() {
        this.queryForm = Object.assign({}, this.queryForm, this.queryForm, {
          pageNumber: 1,
        })
        this.fetchData()
      },
      resetQueryForm() {
        this.$refs['queryForm'].resetFields()
        this.handleQueryForm()
      },
      fetchData() {
        this.loading = true
        getChannelApplyPage(this.queryForm).then((res) => {
          if (res.code === 0) {
            const { records, total } = res.data
            this.tableData = records
            this.totalCount = +total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      setSelectRows(val) {
        console.log('val', val)
        this.selectRows = val
      },
      updateBatch() {
        if (this.selectRows.length > 0) {
          const ids = this.selectRows.map((item) => item.id).join()
          this.$baseConfirm('你确定要批量通过选中项吗？', null, async () => {
            const res = await postChannelApplyAuditBatch({
              ids,
              status: this.statusOpt.pass,
            })
            if (res.code === 0) {
              this.$baseMessage(
                '提交成功',
                'success',
                'vab-hey-message-success'
              )
              await this.fetchData()
            }
          })
        } else {
          this.$baseMessage('未选中任何行', 'error', 'vab-hey-message-error')
        }
      },
      handleEdit(row, status) {
        this.$refs['edit'].showEdit(row, status)
      },
      parseTime,
    },
  }
</script>

<style lang="scss" scoped>
  :deep() {
    .el-table .cell.el-tooltip {
      min-width: auto;
    }
    .el-table--border .el-table__cell:first-child .cell {
      padding: 0;
    }
    .el-table .cell {
      padding: 0;
    }
    .vab-query-form .el-form-item + .el-form-item {
      margin-left: 15px !important;
    }
  }
</style>
