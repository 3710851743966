<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="78px"
          :model="queryForm"
          @submit.native.prevent
        >
          <el-form-item label="订单编号">
            <el-input
              v-model="queryForm.orderNo"
              clearable
              placeholder="订单编号"
            />
          </el-form-item>
          <el-form-item label="商户名称">
            <el-input
              v-model="queryForm.shopName"
              clearable
              placeholder="商户名称"
            />
          </el-form-item>
          <el-form-item label="设备编号">
            <el-input
              v-model="queryForm.deviceNo"
              clearable
              placeholder="设备编号"
            />
          </el-form-item>
          <el-form-item label="出货时间">
            <el-date-picker
              v-model="queryForm.date"
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetForm('form')">
              重置
            </el-button>
            <!-- <el-button type="text" @click="handleFold">
              <span v-if="fold">展开</span>
              <span v-else>合并</span>
              <vab-icon
                class="vab-dropdown"
                :class="{ 'vab-dropdown-active': fold }"
                icon="arrow-up-s-line"
              />
            </el-button> -->
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel :span="24">
        <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
          新增出货记录
        </el-button>
        <el-button
          icon="el-icon-delete"
          type="danger"
          @click="handleDelete($event)"
        >
          删除
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @selection-change="setSelectRows"
    >
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          <span>
            {{ (listQuery.pageNumber - 1) * listQuery.pageSize + $index + 1 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="订单编号" prop="orderNo" />
      <el-table-column align="center" label="商户名称" prop="shopName" />
      <el-table-column align="center" label="收货人" prop="receiver" />
      <el-table-column align="center" label="收货地址" prop="receiverAddress" />
      <el-table-column
        align="center"
        label="订单商品(台)"
        prop="productCount"
      />
      <!-- <el-table-column align="center" label="订单金额(元)" prop="orderPrice" /> -->
      <el-table-column align="center" label="订单状态" prop="status">
        <template slot-scope="">
          <el-tag type="success">已发货</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="出货时间"
        prop="outWarehouseTime"
        sortable
      />

      <el-table-column align="center" fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleEdit(scope.row)">
            编辑
          </el-button>
          <el-button size="mini" type="text" @click="handleDetail(scope.row)">
            详情
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="fetchData"
    />
  </div>
</template>

<script>
  import dayjs from 'dayjs'
  import {
    PageDeviceOrder,
    DeleteDeviceOrder,
    DeleteMultipleDeviceOrder,
  } from '@/api/device/shipment'
  import Pagination from '@/components/pagination.vue'
  export default {
    name: 'DeviceShipment',
    components: {
      Pagination,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now()
          },
          shortcuts: [
            {
              text: '今天',
              onClick(picker) {
                picker.$emit('pick', new Date())
              },
            },
            {
              text: '昨天',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', date)
              },
            },
            {
              text: '一周前',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', date)
              },
            },
          ],
        },
        queryForm: {
          orderNo: '',
          shopName: '',
          deviceNo: '',
          date: '',
        },
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        fold: false,
        imgShow: true,
        list: [],
        imageList: [],
        listLoading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
      }
    },
    beforeMount() {
      window.addEventListener('resize', this.handleHeight)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleHeight)
    },
    created() {
      this.fetchData()
    },
    methods: {
      handleFold() {
        this.fold = !this.fold
        this.handleHeight()
      },
      handleHeight() {
        if (this.fold) this.height = this.$baseTableHeight(2) - 47
        else this.height = this.$baseTableHeight(3) - 30
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleAdd() {
        this.$router.push({
          name: 'DeviceShipmentAdd',
        })
      },
      handleEdit(row) {
        this.$router.push({
          name: 'DeviceShipmentEdit',
          query: {
            id: row.id,
          },
        })
      },
      handleDetail(row) {
        this.$router.push({
          name: 'DeviceShipmentDetail',
          query: {
            id: row.id,
          },
        })
      },
      handleDelete(row) {
        if (row.id) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            await DeleteDeviceOrder({ ids: row.id })
            this.$baseMessage('删除成功', 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        } else {
          if (this.selectRows.length > 0) {
            const ids = this.selectRows.map((item) => item.id).join()
            this.$baseConfirm('你确定要删除选中项吗', null, async () => {
              const { msg } = await DeleteMultipleDeviceOrder({ ids: ids })
              this.$baseMessage(msg, 'success', 'vab-hey-message-success')
              await this.fetchData()
            })
          } else {
            this.$baseMessage('未选中任何行', 'error', 'vab-hey-message-error')
          }
        }
      },
      handleQuery() {
        this.listQuery.pageNumber = 1
        this.fetchData()
      },
      async fetchData() {
        if (this.listLoading) return
        try {
          const { orderNo, shopName, deviceNo, date } = this.queryForm
          this.listLoading = true
          const {
            data: { records, total },
          } = await PageDeviceOrder({
            orderNo,
            shopName,
            deviceNo,
            startDate: date[0] ? dayjs(date[0]).format('YYYY-MM-DD') : '',
            endDate: date[1] ? dayjs(date[1]).format('YYYY-MM-DD') : '',
            ...this.listQuery,
          })
          this.tableData = records
          this.totalCount = +total
        } finally {
          this.listLoading = false
        }
      },
      toggleSelection(rows) {
        if (rows) {
          rows.forEach((row) => {
            this.$refs.tableSort.toggleRowSelection(row)
          })
        } else {
          this.$refs.tableSort.clearSelection()
        }
      },
      resetForm(formName) {
        this.$refs[formName].resetFields()
        this.queryForm = this.$options.data().queryForm
        this.fetchData()
      },
    },
  }
</script>
