<template>
  <div class="index-container">
    <el-form label-width="80px" :model="listQuery" size="small">
      <el-row>
        <el-col
          :md="{ span: 6 }"
          :sm="{ span: 6 }"
          :xl="{ span: 6 }"
          :xs="{ span: 24 }"
        >
          <el-form-item label="模板名称">
            <el-input
              v-model="listQuery.contractTemplateName"
              clearable
              placeholder="模板名称"
            />
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 6 }"
          :sm="{ span: 6 }"
          :xl="{ span: 6 }"
          :xs="{ span: 24 }"
        >
          <el-form-item label="适用类型">
            <el-select v-model="listQuery.houseType" placeholder="适用类型">
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 6 }"
          :sm="{ span: 6 }"
          :xl="{ span: 6 }"
          :xs="{ span: 24 }"
        >
          <el-form-item label="模板状态">
            <el-select v-model="listQuery.enabled" placeholder="模板状态">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 6 }"
          :sm="{ span: 6 }"
          :xl="{ span: 6 }"
          :xs="{ span: 24 }"
        >
          <el-form-item>
            <el-button
              icon="el-icon-search"
              size="mini"
              type="primary"
              @click="getList"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" size="mini" @click="reset">
              重置
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <vab-query-form-left-panel :span="24">
      <el-form ref="form" :inline="true">
        <el-form-item>
          <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
            添加模板
          </el-button>
        </el-form-item>
        <el-form-item>
          1、点击“下载模板” 2、在
          <el-link
            href="https://open.esign.cn/tools/seal-position"
            target="_blank"
            type="primary"
          >
            https://open.esign.cn/tools/seal-position
          </el-link>
          上传模板文件 3、获取签章位置后“编辑模板”填入位置信息
        </el-form-item>
      </el-form>
    </vab-query-form-left-panel>
    <el-table v-loading="loading" border :data="tableData" stripe>
      <el-table-column align="center" label="序号" width="50">
        <template slot-scope="scope">
          <span>
            {{
              (listQuery.pageNumber - 1) * listQuery.pageSize + scope.$index + 1
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="模板名称"
        prop="contractTemplateName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="适用类型"
        prop="houseTypeName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="合同模板ID"
        prop="contractTemplateId"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="更新时间"
        prop="updateTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="状态"
        prop="createTime"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <el-tooltip
            :content="!row.enabled ? '点击开启' : '点击禁用'"
            :enterable="false"
            placement="top"
          >
            <el-switch
              :active-value="true"
              :disabled="statusChangeLoading"
              :inactive-value="false"
              :value="row.enabled"
              @change="onSwitchChange(row)"
            />
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="操作"
        prop="createTime"
        show-overflow-tooltip
        width="330"
      >
        <template #default="{ row }">
          <el-button size="mini" type="text" @click="handleEsignEdit(row)">
            去E签宝编辑
          </el-button>
          <el-button size="mini" type="text" @click="handleBindFiled(row)">
            绑定字段
          </el-button>
          <el-button size="mini" type="text" @click="handleDown(row)">
            下载模板
          </el-button>
          <el-button size="mini" type="text" @click="handleEdit(row)">
            编辑模板
          </el-button>
          <el-button
            v-if="!row.enabled"
            size="mini"
            type="text"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />
    <addTemplete ref="refAddTemplete" @ok="getList" />
    <filedsTemplete ref="refiledsTemplete" />
  </div>
</template>

<script>
  import { downLoadPDF } from '@/utils/excel'
  import { CONTRACTTEMPLETETYPE } from '@/utils/contract'
  import {
    getEsignEditUrl,
    getEsignContractTemplate,
    openEsignContractTemplate,
    closeEsignContractTemplate,
    deleteEsignContractTemplate,
    getEsignContractFileUrl,
  } from '@/api/esign/contract'
  import Pagination from '@/components/pagination.vue'
  import addTemplete from './components/add.vue'
  import filedsTemplete from './components/fileds.vue'
  export default {
    name: 'Contract',
    components: {
      Pagination,
      addTemplete,
      filedsTemplete,
    },
    data() {
      return {
        typeOptions: CONTRACTTEMPLETETYPE.dict,
        options: [
          { value: true, label: '启用' },
          { value: false, label: '禁用' },
        ],
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          contractTemplateName: '',
          houseType: '',
          enabled: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        direction: 'rtl',
        drawer: false,
        size: '',
        drawerData: {},
        ids: '',
        statusChangeLoading: false,
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      async onSwitchChange(row) {
        try {
          this.statusChangeLoading = true
          let res = !row.enabled
            ? await openEsignContractTemplate(row.id)
            : await closeEsignContractTemplate(row.id)
          if (res.code === 0) {
            row.enabled = !row.enabled
          }
        } finally {
          this.statusChangeLoading = false
        }
      },
      getList() {
        this.loading = true
        getEsignContractTemplate(this.listQuery).then((res) => {
          if (res.code === 0) {
            const { records, total } = res.data
            this.tableData = records.map((item) => ({
              ...item,
              houseTypeName: CONTRACTTEMPLETETYPE.getLabelByValue(
                item.houseType
              ),
            }))
            this.totalCount = +total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      handleAdd() {
        this.$refs.refAddTemplete.showModal()
      },
      handleDown(row) {
        getEsignContractFileUrl(row.id).then((res) => {
          if (res.data) {
            downLoadPDF(res.data, row.contractTemplateName)
          }
        })
      },
      handleEsignEdit(row) {
        getEsignEditUrl(row.id).then((res) => {
          if (res.data) {
            window.open(res.data, '_blank')
          }
        })
      },
      handleEdit(row) {
        this.$refs.refAddTemplete.showModal(row)
      },
      handleCheckDetail(id) {
        console.log(id)
        this.$router.push({
          path: '/admin/house/contract/detail',
          query: { id },
        })
      },
      reset() {
        for (let key in this.listQuery) {
          this.listQuery[key] = ''
        }
        this.listQuery.pageNumber = 1
        this.listQuery.pageSize = 20
        this.getList()
      },
      handleDelete(row) {
        this.$confirm('是否删除该模板?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          deleteEsignContractTemplate(row.id).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.getList()
          })
        })
      },
      handleBindFiled(row) {
        this.$refs.refiledsTemplete.showModal(row.id)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .left {
      display: flex;
    }
  }
</style>
