var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "custom-dialog",
      attrs: {
        "append-to-body": "",
        stripe: "",
        title: "账单详情",
        visible: _vm.dialogFormVisible,
        width: "720px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u(
        [
          _vm.enableSettle && _vm.info.status === 0
            ? {
                key: "footer",
                fn: function () {
                  return [
                    _c("el-button", { on: { click: _vm.close } }, [
                      _vm._v("取 消"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.onSubmit },
                      },
                      [_vm._v("结算并推送")]
                    ),
                  ]
                },
                proxy: true,
              }
            : null,
        ],
        null,
        true
      ),
    },
    [
      _c(
        "el-descriptions",
        { attrs: { column: 2, size: "medium" } },
        [
          _c("el-descriptions-item", { attrs: { label: "名字" } }, [
            _vm._v(" " + _vm._s(_vm.info.distributorName || "--") + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "手机号" } }, [
            _vm._v(" " + _vm._s(_vm.info.distributorPhone || "--") + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "结算范围" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.info.startDate
                    ? _vm.parseTime(_vm.info.startDate, "{y}-{m}-{d}")
                    : "--"
                ) +
                " - " +
                _vm._s(
                  _vm.info.endDate
                    ? _vm.parseTime(_vm.info.endDate, "{y}-{m}-{d}")
                    : "--"
                ) +
                " "
            ),
          ]),
          _c("el-descriptions-item", { attrs: { label: "结算状态" } }, [
            _vm._v(
              " " + _vm._s(_vm._f("statusFilterName")(_vm.info.status)) + " "
            ),
          ]),
          _c("el-descriptions-item", { attrs: { label: "结算账户名" } }, [
            _vm._v(" " + _vm._s(_vm.info.settleAccount || "--") + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "结算账户" } }, [
            _vm._v(" " + _vm._s(_vm.info.settleAccountNo || "--") + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "生成时间" } }, [
            _vm._v(" " + _vm._s(_vm.info.createTime || "--") + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "结算时间" } }, [
            _vm._v(" " + _vm._s(_vm.info.settlementTime || "--") + " "),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "结算凭证" } },
            [
              _vm.info.settleVoucher
                ? _c("el-image", {
                    staticStyle: { width: "50px", height: "50px" },
                    attrs: {
                      "preview-src-list": [_vm.info.settleVoucher],
                      src: _vm.info.settleVoucher,
                    },
                  })
                : _c("el-image", {
                    staticStyle: { width: "50px", height: "50px" },
                    attrs: {
                      "preview-src-list": [_vm.defaultImg],
                      src: _vm.defaultImg,
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { "margin-top": "10px" },
          attrs: {
            border: "",
            "cell-style": { padding: "5px 8px" },
            data: _vm.tableData,
            "max-height": "300",
          },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png"),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "来源账单号",
              "min-width": "140",
              prop: "billId",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_vm._v(" " + _vm._s(row.billId || "--") + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "来源商户",
              "min-width": "160",
              prop: "shopName",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(row.shopName || "--") +
                        " " +
                        _vm._s(row.shopPhone || "--") +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "来源",
              "min-width": "100",
              prop: "source",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("sourceFilterName")(row.source)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "订单金额(元)",
              "min-width": "90",
              prop: "billAmount",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " + _vm._s((row.billAmount || 0).toFixed(2)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "佣金金额(元)",
              "min-width": "90",
              prop: "commissionAmount",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " + _vm._s((row.commissionAmount || 0).toFixed(2)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.totalCount > _vm.queryForm.pageSize
        ? _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.queryForm.pageNumber,
              layout: "prev, pager, next",
              "page-size": _vm.queryForm.pageSize,
              small: "",
              total: _vm.totalCount,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          })
        : _vm._e(),
      (_vm.enableSettle && _vm.info.status === 0) || _vm.info.status === 1
        ? _c("div", { staticClass: "total" }, [
            _c("div", { staticClass: "total__item" }, [
              _c("span", [_vm._v("结算金额(元)")]),
              _c("span", { staticClass: "price" }, [
                _vm._v(_vm._s((_vm.info.commission || 0).toFixed(2))),
              ]),
            ]),
            _c("div", { staticClass: "total__item" }, [
              _c("span", [_vm._v("结算订单数")]),
              _c("span", { staticClass: "price" }, [
                _vm._v(_vm._s(_vm.info.unsettledCount)),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.enableSettle && _vm.info.status === 0
        ? _c(
            "div",
            { staticClass: "upload-btn" },
            [
              _c("span", { staticClass: "upload-btn__prefix" }, [_vm._v("*")]),
              _c("span", { staticClass: "upload-btn__title" }, [
                _vm._v("上传付费凭证"),
              ]),
              _c(
                "el-upload",
                {
                  attrs: {
                    action: _vm.domain,
                    "before-upload": _vm.beforeUpload,
                    data: _vm.QiniuData,
                    "file-list": _vm.fileList,
                    limit: 3,
                    multiple: "",
                    "on-error": _vm.uploadError,
                    "on-exceed": _vm.onExceed,
                    "on-remove": _vm.onRemove,
                    "on-success": _vm.uploadSuccess,
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("点击上传")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }