<template>
  <Dialog
    :height="dailog.height"
    :model-value="dailog.visible"
    :title="dailog.title"
    :visible="dailog.visible"
    :width="dailog.width"
    @handleCloseDialog="handleCloseDialog"
    @handleConfirm="handleCloseDialog"
  >
    <template #content>
      <el-tabs v-model="activeName" @tab-click="handleQuery">
        <el-tab-pane label="电表" name="electric" />
        <el-tab-pane label="水表" name="water" />
      </el-tabs>
      <vab-query-form>
        <vab-query-form-top-panel>
          <el-form
            ref="form"
            :inline="true"
            label-width="78px"
            :model="queryForm"
            @submit.native.prevent
          >
            <el-form-item label="设备品牌" prop="brandId">
              <el-select
                v-model="queryForm.brandId"
                clearable
                placeholder="设备品牌"
                @change="fetchModel"
              >
                <el-option
                  v-for="item in optionsBrand"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="设备型号" prop="modelId">
              <el-select
                v-model="queryForm.modelId"
                clearable
                placeholder="设备型号"
              >
                <el-option
                  v-for="item in optionsModel"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="设备编号">
              <el-input
                v-model="queryForm.deviceNo"
                clearable
                placeholder="请输入设备编号"
              />
            </el-form-item>
            <el-form-item>
              <el-button
                icon="el-icon-search"
                native-type="submit"
                type="primary"
                @click="handleQuery"
              >
                查询
              </el-button>
              <el-button icon="el-icon-refresh-left" @click="resetForm('form')">
                重置
              </el-button>
              <!-- <el-button type="text" @click="handleFold">
              <span v-if="fold">展开</span>
              <span v-else>合并</span>
              <vab-icon
                class="vab-dropdown"
                :class="{ 'vab-dropdown-active': fold }"
                icon="arrow-up-s-line"
              />
            </el-button> -->
            </el-form-item>
          </el-form>
        </vab-query-form-top-panel>
      </vab-query-form>

      <el-table
        v-loading="loading"
        border
        :data="tableData"
        stripe
        @selection-change="setSelectRows"
      >
        <el-table-column align="center" type="selection" width="50" />
        <el-table-column align="center" label="绑定序号" width="85">
          <template #default="{ $index }">
            <span>
              {{ (listQuery.pageNumber - 1) * listQuery.pageSize + $index + 1 }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="设备编号" prop="deviceNo" />
        <el-table-column align="center" label="设备品牌" prop="brandName" />
        <el-table-column align="center" label="设备型号" prop="modelName" />
        <el-table-column align="center" label="绑定时间" prop="bindTime" />
        <el-table-column align="center" label="解绑时间" prop="unbindTime" />
        <el-table-column align="center" label="操作" prop="remark" />
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>
      <Pagination
        :limit.sync="listQuery.pageSize"
        :page.sync="listQuery.pageNumber"
        :total-count="totalCount"
        @pagination="fetchData"
      />
    </template>
  </Dialog>
</template>

<script>
  import Dialog from '@/components/dialog.vue'
  import { PageShopBindLogDevice } from '@/api/device/merchant'
  import Pagination from '@/components/pagination.vue'
  import { SelectDeviceBrand } from '@/api/device/brand'
  import { SelectDeviceModel } from '@/api/device/model'

  export default {
    name: 'DeviceBindLog',
    components: {
      Dialog,
      Pagination,
    },
    data() {
      return {
        id: '',
        dailog: {
          height: null,
          title: '设备详情',
          visible: false,
          width: '1150px',
        },
        activeName: 'electric',
        optionsBrand: [],
        optionsModel: [],
        queryForm: {
          deviceNo: '',
          brandId: '',
          modelId: '',
        },
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        fold: false,
      }
    },
    beforeMount() {
      window.addEventListener('resize', this.handleHeight)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleHeight)
    },
    created() {
      this.fetchBrand()
    },
    methods: {
      showView(id, activeName) {
        this.id = id
        this.dailog.visible = true
        this.listQuery.pageNumber = 1
        this.activeName = activeName
        this.fetchData()
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      async fetchBrand() {
        const { data } = await SelectDeviceBrand()
        this.optionsBrand = data.map((item) => {
          return {
            label: item.name,
            value: item.id,
          }
        })
      },
      async fetchModel(val) {
        const { data } = await SelectDeviceModel({
          brandId: val,
        })
        this.optionsModel = data.map((item) => {
          return {
            label: item.name,
            value: item.id,
          }
        })
      },
      handleFold() {
        this.fold = !this.fold
        this.handleHeight()
      },
      handleHeight() {
        if (this.fold) this.height = this.$baseTableHeight(2) - 47
        else this.height = this.$baseTableHeight(3) - 30
      },
      handleQuery() {
        this.listQuery.pageNumber = 1
        this.fetchData()
      },
      async fetchData() {
        if (this.loading) return
        try {
          this.loading = true
          const { deviceNo, brandId, modelId } = this.queryForm
          const { pageNumber, pageSize } = this.listQuery
          const {
            data: { list, totalRow },
          } = await PageShopBindLogDevice({
            pageNumber,
            pageSize,
            deviceNo,
            brandId,
            modelId,
            typeNumber:
              this.activeName === 'electric'
                ? 'SMART_ELECTRIC_METER'
                : 'SMART_WATER_METER',
            houseId: this.id,
          })
          this.tableData = list
          this.totalCount = totalRow
        } finally {
          this.loading = false
        }
      },
      toggleSelection(rows) {
        if (rows) {
          rows.forEach((row) => {
            this.$refs.tableSort.toggleRowSelection(row)
          })
        } else {
          this.$refs.tableSort.clearSelection()
        }
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.$refs['form'].resetFields()
        this.queryForm = this.$options.data().queryForm
      },
      resetForm(formName) {
        this.$refs[formName].resetFields()
        this.queryForm = this.$options.data().queryForm
        this.fetchData()
      },
    },
  }
</script>

<style scoped lang="scss">
  ::v-deep(.container) {
    padding: 0 20px;
  }
</style>
