<!-- 分佣明细 -->
<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="searchForm"
          :inline="true"
          :model="searchForm"
          @submit.native.prevent
        >
          <el-form-item label="分佣流水号" prop="settleNo">
            <el-input
              v-model="searchForm.settleNo"
              placeholder="请输入分佣流水号"
            />
          </el-form-item>
          <el-form-item label="来源订单号" prop="billId">
            <el-input
              v-model="searchForm.billId"
              placeholder="请输入来源订单号"
            />
          </el-form-item>
          <el-form-item label="渠道商" prop="distributorName">
            <el-input
              v-model="searchForm.distributorName"
              placeholder="请输入渠道商"
            />
          </el-form-item>
          <el-form-item label="结算状态" prop="status">
            <el-select v-model="searchForm.status" placeholder="请选择结算状态">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQueryForm"
            >
              查询
            </el-button>
            <el-button
              icon="el-icon-refresh-left"
              native-type="submit"
              @click="resetQueryForm"
            >
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :cell-style="{ padding: '5px 8px' }"
      :data="tableData"
      stripe
    >
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          <span>
            {{ (queryForm.pageNumber - 1) * queryForm.pageSize + $index + 1 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="分佣单流水号"
        min-width="184"
        prop="settleNo"
      >
        <template #default="{ row }">
          {{ row.settleNo || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="生成时间"
        prop="createTime"
        width="160"
      />
      <el-table-column
        align="center"
        label="渠道商"
        min-width="100"
        prop="distributorName"
      >
        <template #default="{ row }">
          {{ row.distributorName || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="渠道商手机号"
        min-width="120"
        prop="distributorPhone"
      >
        <template #default="{ row }">
          {{ row.distributorPhone || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="来源订单号"
        min-width="170"
        prop="billId"
      >
        <template #default="{ row }">
          <el-link type="primary" @click="showAssBill(row)">
            {{ row.billId || '--' }}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="佣金来源"
        min-width="120"
        prop="source"
      >
        <template #default="{ row }">
          {{ row.source | sourceFilterName }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="佣金金额(元)"
        min-width="120"
        prop="commissionAmount"
      >
        <template #default="{ row }">
          {{ (row.commissionAmount || 0).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="结算状态"
        min-width="80"
        prop="status"
      >
        <template #default="{ row }">
          <el-tag :type="row.status | statusFilter">
            {{ row.status | statusFilterName }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="结算时间"
        prop="settleTime"
        width="160"
      >
        <template #default="{ row }">
          {{ row.settleTime || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        fixed="right"
        label="操作"
        show-overflow-tooltip
        width="80"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">详情</el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="queryForm.pageSize"
      :page.sync="queryForm.pageNumber"
      :total-count="totalCount"
      @pagination="fetchData"
    />
    <!-- 关联账单 -->
    <bill-info ref="bill" />
    <commission-info ref="commission" />
  </div>
</template>
<script>
  import { getChannelCommissionPage } from '@/api/distributor/finance'
  import { parseTime } from '@/utils/index'
  import Pagination from '@/components/pagination.vue'
  import BillInfo from './components/BillInfo.vue'
  import CommissionInfo from './components/CommissionInfo.vue'

  export default {
    name: 'DistributorFinanceCommission',
    components: {
      Pagination,
      BillInfo,
      CommissionInfo,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          0: 'primary',
          1: 'success',
          2: 'info',
        }
        return statusMap[status]
      },
      statusFilterName(status) {
        const statusNameMap = {
          0: '未结算',
          1: '已结算',
          2: '已失效',
        }
        return statusNameMap[status]
      },
      sourceFilterName(source) {
        const sourceNameMap = {
          1: '所属渠道佣金',
          2: '上级渠道佣金',
        }
        return sourceNameMap[source]
      },
    },

    data() {
      const searchForm = {
        settleNo: null, // 流水号
        status: null, // 结算状态
        billId: null, // 订单ID
        distributorName: null, // 渠道商姓名
      }
      return {
        searchForm,
        queryForm: {
          pageNumber: 1,
          pageSize: 20,
          ...searchForm,
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        options: [
          {
            label: '全部',
            value: '',
          },
          {
            label: '未结算',
            value: 0,
          },
          {
            label: '已结算',
            value: 1,
          },
          {
            label: '已失效',
            value: 2,
          },
        ],
      }
    },
    mounted() {
      this.fetchData()
    },
    methods: {
      handleQueryForm() {
        this.queryForm = Object.assign({}, this.queryForm, this.searchForm, {
          pageNumber: 1,
        })
        this.fetchData()
      },
      resetQueryForm() {
        this.$refs['searchForm'].resetFields()
        this.handleQueryForm()
      },
      fetchData() {
        this.loading = true
        getChannelCommissionPage(this.queryForm).then((res) => {
          if (res.code === 0) {
            const { records, total } = res.data
            this.tableData = records
            this.totalCount = +total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      updateBatch() {},
      handleEdit(row) {
        this.$refs['commission'].showEdit(row)
      },
      showAssBill(row) {
        this.$refs['bill'].showEdit(row)
      },
      parseTime,
    },
  }
</script>

<style lang="scss" scoped>
  :deep() {
    .el-table .cell.el-tooltip {
      min-width: auto;
    }
    .el-table--border .el-table__cell:first-child .cell {
      padding: 0;
    }
    .el-table .cell {
      padding: 0;
    }
    .vab-query-form .el-form-item + .el-form-item {
      margin-left: 15px !important;
    }
  }
</style>
