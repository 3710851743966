var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "custom-dialog",
      attrs: {
        "append-to-body": "",
        stripe: "",
        title: "更改上级渠道商",
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v(" 确定 ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          staticStyle: { "padding-left": "30px" },
          attrs: {
            "label-position": "top",
            "label-width": "80px",
            model: _vm.queryParams,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "上级名称/手机号" } },
            [
              _c("el-autocomplete", {
                attrs: {
                  "fetch-suggestions": _vm.querySearchAsync,
                  "highlight-first-item": "",
                  placeholder: "请输上级名称/手机号",
                },
                on: { select: _vm.handleSelect },
                model: {
                  value: _vm.queryParams.keywords,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "keywords", $$v)
                  },
                  expression: "queryParams.keywords",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }