<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="110px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="开始与截止时间">
            <el-date-picker
              v-model="timeFrom"
              clearable
              end-placeholder="创建截止日期"
              range-separator="至"
              start-placeholder="创建开始日期"
              type="daterange"
            />
          </el-form-item>
          <el-form-item label="充值金额">
            <el-input
              v-model="listQuery.amount"
              clearable
              placeholder="充值金额"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="getList"
            >
              查询
            </el-button>

            <el-button icon="el-icon-refresh-left" @click="searchReset">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel :span="24">
        <el-button icon="el-icon-circle-plus" type="primary" @click="addList">
          新增
        </el-button>

        <el-button
          icon="el-icon-edit-outline"
          type="primary"
          @click="auditList"
        >
          修改
        </el-button>

        <el-button icon="el-icon-delete-solid" type="danger" @click="deleteRow">
          删除
        </el-button>
        <!-- <el-button
          icon="el-icon-refresh"
          type="primary"
          @click="refresh($event)"
        >
          刷新
        </el-button> -->
      </vab-query-form-left-panel>
    </vab-query-form>

    <!-- <el-card class="box-card"> -->
    <!-- <el-row :gutter="20">
        <el-col :span="8">
          <el-date-picker
            v-model="timeFrom"
            end-placeholder="创建截止日期"
            range-separator="至"
            start-placeholder="创建开始日期"
            type="daterange"
          />
        </el-col>
        <el-col :span="6">
          <el-input
            v-model="listQuery.amount"
            clearable
            placeholder="充值金额"
          />
        </el-col>
        <el-button
          icon="el-icon-search"
          size="mini"
          type="primary"
          @click="getList"
        >
          查询
        </el-button>
      </el-row>

      <el-row style="margin-bottom: 10px; margin-top: 10px">
        <el-button
          icon="el-icon-circle-plus"
          size="mini"
          type="primary"
          @click="addList"
        >
          新增
        </el-button>
        <el-button
          icon="el-icon-edit-outline"
          size="mini"
          type="primary"
          @click="auditList()"
        >
          修改
        </el-button>
        <el-button
          icon="el-icon-delete-solid"
          size="mini"
          type="primary"
          @click="deleteRow"
        >
          删除
        </el-button>
        <el-button
          icon="el-icon-refresh"
          size="mini"
          type="primary"
          @click="refresh"
        >
          刷新
        </el-button>
      </el-row> -->

    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @select="selectRow"
      @select-all="selectRow"
    >
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" label="序号" width="50">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="充值金额" prop="rechargeAmount" />
      <el-table-column align="center" label="赠送额度" prop="givingAmount" />
      <el-table-column align="center" label="备注 " prop="remark" />
      <el-table-column align="center" label="创建时间" prop="createTime" />
      <el-table-column align="center" label="状态" prop="status">
        <template #default="{ row }">
          <el-tag :type="row.status == 1 ? 'success' : 'danger'">
            {{ row.status == 1 ? '启用' : '禁用' }}
          </el-tag>
        </template>
      </el-table-column>
      <!-- :type="scope.row.status == 1 ? 'danger' : 'success'" -->
      <el-table-column align="center" fixed="right" label="操作" width="80">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleDetail(scope.row)">
            {{ scope.row.status == 1 ? '禁用' : '启用' }}
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>

    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />

    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form
          ref="formData1"
          label-width="100px"
          :model="formData1"
          :rules="rules"
          size="small"
        >
          <el-form-item label="充值金额" prop="rechargeAmount">
            <el-input
              v-model="formData1.rechargeAmount"
              clearable
              :disabled="disabled"
              placeholder="请输入充值金额"
              style="max-width: 300px"
            />
          </el-form-item>
          <el-form-item label="赠送额度" prop="givingAmount">
            <el-input
              v-model="formData1.givingAmount"
              clearable
              :disabled="disabled"
              placeholder="请输入赠送额度"
              style="max-width: 300px"
            />
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              v-model="formData1.remark"
              clearable
              :disabled="disabled"
              placeholder="请输入备注"
              style="max-width: 300px"
            />
          </el-form-item>
        </el-form>
      </template>
    </Dialog>
    <!-- </el-card> -->
  </div>
</template>

<script>
  import {
    walletList,
    rechargeSave,
    rechargeupdate,
    rechargeRemove,
  } from '@/api/wallet/wallet'
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialog.vue'
  export default {
    name: 'Preferential',
    components: {
      Pagination,
      Dialog,
    },
    data() {
      return {
        disabled: false,
        value1: '',
        dailog: {
          height: 300,
          title: '',
          visible: false,
          width: '500px',
        },
        formData: {
          parentName: '',
          name: '',
          pid: '',
          path: '',
          type: null, // 1菜单 2按钮
          icon: '',
          time: '',
        },
        formData1: {
          rechargeAmount: '',
          givingAmount: '',
        },

        ddd: 'zc',
        pay: [
          {
            value: '',
            label: '请选择消费类型',
          },
          {
            value: '1',
            label: '充值',
          },
          {
            value: '2',
            label: '扣费',
          },
        ],
        options: [
          {
            value: '',
            label: '退款状态',
          },
          {
            value: '0',
            label: '未退款',
          },
          {
            value: '1',
            label: '已退款',
          },
        ],
        value: '',
        payType: '',
        businessType: '',
        input: '',
        listQuery: {
          amount: '',
          startTime: '',
          endTime: '',
          pageNumber: 1,
          pageSize: 20,
        },
        timeFrom: [],
        totalCount: 0,
        tableData: [],
        rules: {
          rechargeAmount: [
            {
              required: true,
              message: '请输入金额',
              trigger: 'blur',
            },
          ],
          givingAmount: [
            {
              required: true,
              message: '请输入金额',
              trigger: 'blur',
            },
          ],
        },
        loading: false,
        selection: [],
        check: {},
        ids: '',
      }
    },
    created() {
      this.getList()
    },
    methods: {
      searchReset() {
        this.listQuery = {}
        this.getList()
      },
      //查询
      getList() {
        console.log('this.timeFrom', this.timeFrom)
        this.loading = true
        if (this.timeFrom && this.timeFrom.length > 0) {
          this.listQuery.startTime = this.timeFrom[0]
          this.listQuery.endTime = this.timeFrom[1]
        } else {
          this.listQuery.startTime = ''
          this.listQuery.endTime = ''
        }
        walletList(this.listQuery).then((res) => {
          if (res.code === 0) {
            console.log('walletList>>', res.data)
            this.tableData = res.data.records
            this.totalCount = +res.data.total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },

      //表格选择
      selectRow(selection, row) {
        this.selection = selection
        console.log('单选===', selection)
        console.log('单选===', row)
        this.check = row
        this.ids = ''
        selection.forEach((i) => {
          this.ids += i.id + ','
        })
        console.log(this.ids)
      },
      //新增
      addList() {
        this.formData1 = {}
        this.dailog.visible = true
        this.dailog.title = '新增平台充值优惠配置'
      },
      //修改
      auditList() {
        if (this.selection.length == 0) {
          this.$message({
            message: '请至少选择一行数据',
            type: 'warning',
          })
          return false
        }
        this.dailog.visible = true
        this.dailog.title = '修改平台充值优惠配置'
        this.formData1 = this.check
      },
      //编辑
      handleDetail(row) {
        console.log(row.status)
        var tit = row.status == 1 ? '禁用' : '启用'
        this.$confirm('确定' + tit + '吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            const params = row
            params.status = row.status == 1 ? 2 : 1
            rechargeupdate(params).then((res) => {
              console.log('res', res)
              this.dailog.visible = false
              this.check = {}
              this.selection = []
              this.getList()
            })

            this.$message({
              type: 'success',
              message: tit + '成功!',
            })
          })
          .catch(() => {})
      },
      handleCloseDialog() {
        this.dailog.visible = false
      },
      // 确认新增
      handleConfirm() {
        console.log(this.formData1, 588)
        this.$refs['formData1'].validate((valid) => {
          console.log(valid)
          if (valid) {
            if (this.check.id) {
              console.log('修改方法')
              this.formData1.id = this.check.id
              rechargeupdate(this.formData1).then((res) => {
                console.log('res', res)
                this.dailog.visible = false
                this.check = {}
                this.selection = []
                this.getList()
              })
            } else {
              rechargeSave(this.formData1).then((res) => {
                //   this.commonMessage(res)
                console.log('res', res)
                this.dailog.visible = false
                this.getList()
              })
            }
          }
        })
      },
      //添加商品信息
      addInfo() {},

      handleRead() {},

      //删除
      deleteRow() {
        if (this.selection.length == 0) {
          this.$message({
            message: '请至少选择一行数据',
            type: 'warning',
          })
          return false
        }

        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            rechargeRemove({ ids: this.ids }).then((res) => {
              console.log('res', res)
              if (res.code == 0) {
                this.check = {}
                this.selection = []
                this.$message({
                  type: 'success',
                  message: '删除成功!',
                })
                this.getList()
              }
            })
          })
          .catch(() => {})
      },

      //刷新
      refresh() {
        this.getList()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-range-editor.el-input__inner {
    width: 105%;
  }
</style>
