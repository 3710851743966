<template>
  <div class="index-container">
    <el-radio-group v-model="tabLevel">
      <el-radio-button label="first">上级渠道</el-radio-button>
      <el-radio-button label="second">所属渠道</el-radio-button>
    </el-radio-group>

    <div style="position: relative; height: 240px">
      <transition name="fade">
        <el-table
          v-show="tabLevel === 'first'"
          border
          class="table"
          :data="firstList"
          stripe
        >
          <el-table-column align="center" label="分佣项" prop="name" />
          <el-table-column align="center" label="分佣费率">
            <template #default="{ row }">
              <template v-if="row.edit">
                <el-input
                  v-model="row.value"
                  :readonly="row.loading"
                  size="mini"
                  style="width: 60px"
                />
                <el-button
                  class="cancel-btn"
                  :disabled="row.loading"
                  size="mini"
                  style="margin-left: 5px"
                  type="text"
                  @click="cancelEdit(row)"
                >
                  取消
                </el-button>
              </template>
              <span v-else>{{ row.value }}</span>
              <span :style="row.edit ? { marginLeft: '8px' } : ''">
                {{ row.unit }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            fixed="right"
            label="操作"
            width="120"
          >
            <template #default="{ row }">
              <el-button
                :disabled="!row.edit"
                icon="el-icon-edit"
                :loading="row.loading"
                size="mini"
                type="text"
                @click="confirmEdit(row)"
              >
                保存
              </el-button>
              <el-button
                :disabled="row.edit"
                icon="el-icon-edit"
                size="mini"
                type="text"
                @click="row.edit = !row.edit"
              >
                修改
              </el-button>
            </template>
          </el-table-column>
          <template #empty>
            <el-image
              class="vab-data-empty"
              :src="require('@/assets/empty_images/data_empty.png')"
            />
          </template>
        </el-table>
      </transition>

      <transition name="fade">
        <el-table
          v-show="tabLevel === 'second'"
          border
          class="table"
          :data="secondList"
        >
          <el-table-column
            align="center"
            label="分佣项"
            prop="name"
            width="130"
          />
          <el-table-column align="center" label="分佣费率" width="300px">
            <template #default="{ row }">
              <template v-if="row.edit">
                <el-input
                  v-model="row.value"
                  :readonly="row.loading"
                  size="mini"
                  style="width: 60px"
                />
                <el-button
                  class="cancel-btn"
                  :disabled="row.loading"
                  size="mini"
                  style="margin-left: 5px"
                  type="text"
                  @click="cancelEdit(row)"
                >
                  取消
                </el-button>
              </template>
              <span v-else>{{ row.value }}</span>
              <span :style="row.edit ? { marginLeft: '8px' } : ''">
                {{ row.unit }}
              </span>
            </template>
          </el-table-column>

          <el-table-column align="center" label="操作" width="120">
            <template #default="{ row }">
              <el-button
                :disabled="!row.edit"
                icon="el-icon-edit"
                :loading="row.loading"
                size="mini"
                type="text"
                @click="confirmEdit(row)"
              >
                保存
              </el-button>
              <el-button
                :disabled="row.edit"
                icon="el-icon-edit"
                size="mini"
                type="text"
                @click="row.edit = !row.edit"
              >
                修改
              </el-button>
            </template>
          </el-table-column>
          <template #empty>
            <el-image
              class="vab-data-empty"
              :src="require('@/assets/empty_images/data_empty.png')"
            />
          </template>
        </el-table>
      </transition>
    </div>
  </div>
</template>

<script>
  import {
    getChannelCommissionDefaultConfig,
    updateChannelCommissionDefaultConfig,
  } from '@/api/distributor/setting'

  export default {
    name: 'DistributorSettingReturn',
    data() {
      return {
        tabLevel: 'first',
        firstList: [
          {
            key: 'houseManagementFee',
            name: '房屋管理费',
            originalValue: 0,
            value: 0,
            unit: '元/间/月',
            edit: false,
            loading: false,
          },
          {
            key: 'withdrawalServiceFee',
            name: '业主提现手续费',
            originalValue: 0,
            value: 0,
            unit: '元/笔',
            edit: false,
            loading: false,
          },
          {
            key: 'waterServiceFee',
            name: '水表信息服务费',
            originalValue: 0,
            value: 0,
            unit: '元/间/月',
            edit: false,
            loading: false,
          },
          {
            key: 'electricServiceFee',
            name: '电表信息服务费',
            originalValue: 0,
            value: 0,
            unit: '元/间/月',
            edit: false,
            loading: false,
          },
        ],
        secondList: [
          {
            key: 'secondaryHouseManagementFee',
            name: '房屋管理费',
            originalValue: 0,
            value: 0,
            unit: '元/间/月',
            edit: false,
            loading: false,
          },
          {
            key: 'secondaryWithdrawalServiceFee',
            name: '业主提现手续费',
            originalValue: 0,
            value: 0,
            unit: '元/笔',
            edit: false,
            loading: false,
          },
          {
            key: 'secondaryWaterServiceFee',
            name: '水表信息服务费',
            originalValue: 0,
            value: 0,
            unit: '元/间/月',
            edit: false,
            loading: false,
          },
          {
            key: 'secondaryElectricServiceFee',
            name: '电表信息服务费',
            originalValue: 0,
            value: 0,
            unit: '元/间/月',
            edit: false,
            loading: false,
          },
        ],
        loading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        queryForm: {
          pageNo: 1,
          pageSize: 20,
          title: '',
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        this.loading = true
        let res = await getChannelCommissionDefaultConfig()

        if (res && res.code === 0) {
          let list = JSON.parse(JSON.stringify(this.firstList))
          list.forEach((o) => {
            o.originalValue = o.value = res.data[o.key]
          })
          let secondList = JSON.parse(JSON.stringify(this.secondList))
          secondList.forEach((o) => {
            o.originalValue = o.value = res.data[o.key]
          })
          this.firstList = list
          this.secondList = secondList
        }
        this.loading = false
      },
      cancelEdit(row) {
        row.value = row.originalValue
        row.edit = false
      },
      async confirmEdit(row) {
        row.loading = true
        let data = {}
        this.firstList.forEach((o) => {
          data[o.key] = o.originalValue
        })
        this.secondList.forEach((o) => {
          data[o.key] = o.originalValue
        })
        data[row.key] = row.value

        let res = await updateChannelCommissionDefaultConfig(data)
        if (res.code === 0) {
          row.originalValue = row.value
          row.edit = false
        }
        row.loading = false
        // row.originalTitle = row.title
      },
    },
  }
</script>
<style scoped>
  :deep(.el-table__empty-text) {
    width: 100%;
  }

  :deep(.el-input__inner) {
    text-align: center;
  }

  :deep(.el-tabs--card > .el-tabs__header .el-tabs__nav) {
    border-left: none;
  }

  .table {
    position: absolute;
    top: 20px;
    left: 0;
    max-width: 540px;
  }
</style>
<style>
  .fade-enter-active {
    transition: all 0.3s;
  }

  .fade-leave-active {
    transition: all 0.3s;
  }

  .fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
<style>
  .fade-enter-active {
    transition: all 0.3s;
  }

  .fade-leave-active {
    transition: all 0.3s;
  }

  .fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .table {
    position: absolute;
    top: 20px;
    left: 0;
    max-width: 540px;
  }
</style>
