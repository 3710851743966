import { render, staticRenderFns } from "./return.vue?vue&type=template&id=07396311&scoped=true"
import script from "./return.vue?vue&type=script&lang=js"
export * from "./return.vue?vue&type=script&lang=js"
import style0 from "./return.vue?vue&type=style&index=0&id=07396311&scoped=true&lang=css"
import style1 from "./return.vue?vue&type=style&index=1&id=07396311&lang=css"
import style2 from "./return.vue?vue&type=style&index=2&id=07396311&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07396311",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/zss-service-platform/frontend/zss-rental-service-admin-portal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('07396311')) {
      api.createRecord('07396311', component.options)
    } else {
      api.reload('07396311', component.options)
    }
    module.hot.accept("./return.vue?vue&type=template&id=07396311&scoped=true", function () {
      api.rerender('07396311', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/distributor/setting/return.vue"
export default component.exports