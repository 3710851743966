var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c("el-page-header", {
        attrs: { content: "【" + _vm.shopName + "】" },
        on: { back: _vm.goBack },
      }),
      _c(
        "el-row",
        { attrs: { type: "flex" } },
        [
          _c(
            "el-col",
            { staticClass: "fix-width-200" },
            [
              _c(
                "el-tabs",
                {
                  attrs: { "tab-position": "left" },
                  on: { "tab-click": _vm.handleChangeBuilding },
                  model: {
                    value: _vm.building.id,
                    callback: function ($$v) {
                      _vm.$set(_vm.building, "id", $$v)
                    },
                    expression: "building.id",
                  },
                },
                _vm._l(_vm.data, function (item) {
                  return _c("el-tab-pane", {
                    key: "building" + item.id,
                    attrs: { label: item.name, name: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      `${_vm.shopName}${
                        _vm.building.name ? " - " + _vm.building.name : ""
                      }`
                    ) +
                    " "
                ),
              ]),
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleChangeBuilding },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "电表", name: "electric" } },
                    [
                      _c("Electic", {
                        ref: "electric",
                        attrs: {
                          "building-id": _vm.building.id,
                          "floor-num": _vm.building.floorNum,
                          "shop-id": _vm.shopId,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "水表", name: "water" } },
                    [
                      _c("Water", {
                        ref: "water",
                        attrs: {
                          "building-id": _vm.building.id,
                          "floor-num": _vm.building.floorNum,
                          "shop-id": _vm.shopId,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }