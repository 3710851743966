var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c("el-page-header", {
        attrs: { content: "出货记录详情" },
        on: { back: _vm.goBack },
      }),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form",
          attrs: { "label-width": "100px", model: _vm.formData, size: "small" },
        },
        [
          _c("div", { staticClass: "card-title" }, [_vm._v("订单信息")]),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商户名称", prop: "shopName" } },
                    [_vm._v(" " + _vm._s(_vm.formData.shopName) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "出货时间", prop: "outWarehouseTime" } },
                    [_vm._v(" " + _vm._s(_vm.formData.outWarehouseTime) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收货人姓名", prop: "receiver" } },
                    [_vm._v(" " + _vm._s(_vm.formData.receiver) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收货人电话", prop: "receiverPhone" } },
                    [_vm._v(" " + _vm._s(_vm.formData.receiverPhone) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收货地址", prop: "receiverAddress" } },
                    [_vm._v(" " + _vm._s(_vm.formData.receiverAddress) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单金额", prop: "orderPrice" } },
                    [_vm._v(" " + _vm._s(_vm.formData.orderPrice) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单备注", prop: "type" } },
                    [_vm._v(" " + _vm._s(_vm.formData.remark) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-form",
        {
          ref: "formPro",
          attrs: { "label-width": "0", model: _vm.deviceForm, size: "small" },
        },
        [
          _c("div", { staticClass: "card-title" }, [_vm._v("商品信息")]),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "typeId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "设备类型" },
                          model: {
                            value: _vm.deviceForm.typeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.deviceForm, "typeId", $$v)
                            },
                            expression: "deviceForm.typeId",
                          },
                        },
                        _vm._l(_vm.optionsType, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "brandId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "设备品牌" },
                          on: { change: _vm.fetchModel },
                          model: {
                            value: _vm.deviceForm.brandId,
                            callback: function ($$v) {
                              _vm.$set(_vm.deviceForm, "brandId", $$v)
                            },
                            expression: "deviceForm.brandId",
                          },
                        },
                        _vm._l(_vm.optionsBrand, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "modelId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "设备型号" },
                          model: {
                            value: _vm.deviceForm.modelId,
                            callback: function ($$v) {
                              _vm.$set(_vm.deviceForm, "modelId", $$v)
                            },
                            expression: "deviceForm.modelId",
                          },
                        },
                        _vm._l(_vm.optionsModel, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "deviceNo" } },
                    [
                      _c("el-input", {
                        staticStyle: { "max-width": "300px" },
                        attrs: {
                          clearable: "",
                          placeholder: "请填写或扫码录入设备编号",
                        },
                        model: {
                          value: _vm.deviceForm.deviceNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.deviceForm, "deviceNo", $$v)
                          },
                          expression: "deviceForm.deviceNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { type: "primary" },
                      on: { click: _vm.fetchData },
                    },
                    [_vm._v(" 搜索 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { icon: "el-icon-refresh-left" },
                      on: {
                        click: function ($event) {
                          return _vm.resetForm("formPro")
                        },
                      },
                    },
                    [_vm._v(" 重置 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { border: "", data: _vm.tableData, stripe: "" },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png"),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "序号", width: "55" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ $index }) {
                  return [_c("span", [_vm._v(" " + _vm._s($index + 1) + " ")])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "设备类型", prop: "typeName" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "设备品牌", prop: "brandName" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "设备型号", prop: "modelName" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "设备编号", prop: "deviceNo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [_vm._v(" " + _vm._s(row.deviceNo) + " ")]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleViewBarcode(row.deviceNo)
                          },
                        },
                      },
                      [_vm._v(" 查看标签 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "添加时间",
              prop: "createTime",
              sortable: "",
            },
          }),
        ],
        1
      ),
      _c("barcode", { ref: "viewBarcode" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }