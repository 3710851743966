var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "region-picker" },
    [
      _c("el-select", {
        staticStyle: { width: "100%" },
        attrs: { placeholder: _vm.placeholder, value: _vm.defaultText },
      }),
      _vm.enable
        ? _c("el-cascader", {
            ref: "regionCityPicker",
            staticClass: "cascader",
            attrs: {
              placeholder: _vm.placeholder,
              props: _vm.props,
              value: _vm.value,
            },
            on: { change: _vm.handleRegionChange },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }