<template>
  <Dialog
    :height="dailog.height"
    :model-value="dailog.visible"
    :title="dailog.title"
    :visible="dailog.visible"
    :width="dailog.width"
    @handleCloseDialog="handleCloseDialog"
    @handleConfirm="handleConfirm"
  >
    <template #content>
      <el-form
        ref="form"
        label-width="200px"
        :model="formData"
        :rules="rules"
        size="small"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="套餐名称" prop="name">
              <el-input
                v-model="formData.name"
                clearable
                placeholder="请输入..."
                style="max-width: 340px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="充值金额(元)" prop="rechargeAmount">
              <el-input
                v-model="formData.rechargeAmount"
                clearable
                placeholder="请输入..."
                style="max-width: 340px"
                type="number"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="合同数量(份)" prop="contractQuantity">
              <el-input
                v-model="formData.contractQuantity"
                clearable
                placeholder="请输入..."
                style="max-width: 340px"
                type="number"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="状态" prop="status">
              <el-switch v-model="formData.status" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="formData.remark"
                autosize
                clearable
                placeholder="请输入..."
                style="max-width: 340px"
                type="textarea"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </template>
  </Dialog>
</template>

<script>
  import Dialog from '@/components/dialog.vue'
  import { CONTRACTTEMPLETETYPE } from '@/utils/contract'
  import {
    addEsignContractPackage,
    putEsignContractPackage,
  } from '@/api/esign/contract'
  export default {
    components: {
      Dialog,
    },
    data() {
      return {
        dailog: {
          height: null,
          title: '新增套餐',
          visible: false,
          width: '950px',
        },
        typeOptions: CONTRACTTEMPLETETYPE.dict,
        formData: {
          name: '',
          rechargeAmount: '',
          contractQuantity: '',
          remark: '',
          status: false,
        },
        id: '',
        rules: {
          name: [
            { required: true, message: '请输入套餐名称', trigger: 'blur' },
          ],
          rechargeAmount: [
            { required: true, message: '请输入充值金额', trigger: 'blur' },
          ],
          contractQuantity: [
            { required: true, message: '请输入合同数量', trigger: 'blur' },
          ],
        },
        loadingSure: false,
      }
    },
    methods: {
      showModal(row) {
        this.dailog.visible = true
        if (row) {
          this.id = row.id
          this.dailog.title = '编辑套餐'
          const { name, rechargeAmount, contractQuantity, status, remark } = row
          this.formData = {
            name,
            rechargeAmount,
            contractQuantity,
            remark,
            status,
          }
        }
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.formData = this.$options.data().formData
      },
      handleConfirm() {
        this.$refs['form'].validate(async (valid) => {
          if (valid && !this.loadingSure) {
            try {
              this.loadingSure = true
              this.id
                ? await putEsignContractPackage({
                    id: this.id,
                    ...this.formData,
                    status: this.formData.status ? 1 : 0,
                  })
                : await addEsignContractPackage({
                    ...this.formData,
                    status: this.formData.status ? 1 : 0,
                  })
              this.handleCloseDialog()
              this.$emit('ok')
              this.$message({
                type: 'success',
                message: '操作成功!',
              })
            } finally {
              this.loadingSure = false
            }
          }
        })
      },
    },
  }
</script>
