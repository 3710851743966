var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "custom-dialog",
      attrs: {
        "append-to-body": "",
        title: "添加渠道商",
        visible: _vm.dialogFormVisible,
        width: "480px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v(" 确定 ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "editForm",
          staticStyle: { padding: "0 20px" },
          attrs: {
            "label-width": "100px",
            model: _vm.ruleForm,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "手机号码", prop: "phone" } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  maxlength: 11,
                  placeholder: "手机号码",
                },
                model: {
                  value: _vm.ruleForm.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "phone", $$v)
                  },
                  expression: "ruleForm.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "姓名", prop: "name" } },
            [
              _c("el-input", {
                attrs: { clearable: "", maxlength: 24, placeholder: "姓名" },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "name", $$v)
                  },
                  expression: "ruleForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "省市区", prop: "provinceCode" } },
            [
              _c("region-picker", {
                ref: "region",
                staticStyle: { width: "100%" },
                attrs: {
                  "default-text": _vm.getRegionText("address"),
                  enable: _vm.enablePicker,
                  placeholder: "请选择省/市/区",
                },
                on: { change: _vm.handleRegionChange },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "详细地址", prop: "address" } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  maxlength: 64,
                  placeholder: "xxx街道x号",
                },
                model: {
                  value: _vm.ruleForm.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "address", $$v)
                  },
                  expression: "ruleForm.address",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }