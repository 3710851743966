<template>
  <el-dialog
    append-to-body
    class="custom-dialog"
    title="分佣单详情"
    :visible.sync="dialogFormVisible"
    width="720px"
    @close="close"
  >
    <el-descriptions border :column="2" size="medium">
      <el-descriptions-item label="渠道商">
        {{ info.distributorName || '--' }}
      </el-descriptions-item>
      <el-descriptions-item label="渠道商手机号">
        {{ info.distributorPhone }}
      </el-descriptions-item>
      <el-descriptions-item label="流水号">
        {{ info.settleNo }}
      </el-descriptions-item>

      <el-descriptions-item label="生成时间">
        {{ info.createTime || '--' }}
      </el-descriptions-item>
      <el-descriptions-item label="结算状态">
        {{ info.status | statusFilterName }}
      </el-descriptions-item>

      <el-descriptions-item label="结算时间">
        {{ info.settleTime || '--' }}
      </el-descriptions-item>
      <el-descriptions-item label="佣金来源">
        {{ info.source | sourceFilterName }}
      </el-descriptions-item>
      <el-descriptions-item label="佣金金额(元)">
        <span style="color: #f56c6c">
          {{ (info.commissionAmount || 0).toFixed(2) }}
        </span>
      </el-descriptions-item>
    </el-descriptions>

    <el-table
      v-loading="loading"
      border
      :cell-style="{ padding: '5px 8px' }"
      :data="[info]"
      stripe
      style="margin-top: 20px"
    >
      <el-table-column
        align="center"
        label="来源账单号/流水号"
        min-width="140"
        prop="billId"
      >
        <template #default="{ row }">
          {{ row.billId || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="类型"
        min-width="100"
        prop="orderType"
      >
        <template #default="{ row }">
          {{ orderTypeFilterName(row.orderType) || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="来源商户"
        min-width="160"
        prop="shopName"
      >
        <template #default="{ row }">
          {{ row.shopName || '--' }} {{ row.shopPhone }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="账单金额(元)"
        min-width="90"
        prop="billAmount"
      >
        <template #default="{ row }">
          {{ (row.billAmount || 0).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="佣金金额(元)"
        min-width="90"
        prop="commissionAmount"
      >
        <template #default="{ row }">
          {{ (row.commissionAmount || 0).toFixed(2) }}
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
  </el-dialog>
</template>

<script>
  import { getChannelCommissionDetail } from '@/api/distributor/finance'

  export default {
    name: 'CommissionInfo',
    filters: {
      statusFilterName(status) {
        const statusNameMap = {
          0: '未结算',
          1: '已结算',
          2: '已失效',
        }
        return statusNameMap[status]
      },
      sourceFilterName(source) {
        const sourceNameMap = {
          1: '所属渠道佣金',
          2: '上级渠道佣金',
        }
        return sourceNameMap[source]
      },
    },
    data() {
      return {
        dialogFormVisible: false,
        info: {},
        loading: false,
      }
    },
    created() {},
    methods: {
      orderTypeFilterName(type) {
        const orderTypeNameMap = {
          1: '房租账单',
        }
        return orderTypeNameMap[type]
      },
      async showEdit(row) {
        this.info = row
        this.dialogFormVisible = true
        this.loading = true
        const res = await getChannelCommissionDetail(row.id)
        if (res.code === 0) {
          this.info = Object.assign({}, this.info, res.data)
          console.log('this.tableData', this.info)
        }
        this.loading = false
      },
      close() {
        this.dialogFormVisible = false
      },
    },
  }
</script>
<style lang="scss">
  @import '../../../dialog.scss';

  .custom-dialog {
    @include setDialog();
  }
</style>
