<template>
  <Dialog
    :height="dailog.height"
    :model-value="dailog.visible"
    :showfoot="false"
    :title="dailog.title"
    :visible="dailog.visible"
    :width="dailog.width"
    @handleCloseDialog="handleCloseDialog"
  >
    <template #content>
      <el-form label-width="80px" :model="listQuery" size="small">
        <el-row>
          <el-col
            :md="{ span: 6 }"
            :sm="{ span: 6 }"
            :xl="{ span: 6 }"
            :xs="{ span: 24 }"
          >
            <el-form-item label="交易编号">
              <el-input
                v-model="listQuery.tradeNo"
                clearable
                placeholder="交易编号"
              />
            </el-form-item>
          </el-col>
          <el-col
            :md="{ span: 6 }"
            :sm="{ span: 6 }"
            :xl="{ span: 6 }"
            :xs="{ span: 24 }"
          >
            <el-form-item label="登录账号">
              <el-input
                v-model="listQuery.loginAccount"
                clearable
                placeholder="登录账号"
              />
            </el-form-item>
          </el-col>
          <el-col
            :md="{ span: 6 }"
            :sm="{ span: 6 }"
            :xl="{ span: 6 }"
            :xs="{ span: 24 }"
          >
            <el-form-item label="支付方式">
              <el-select
                v-model="listQuery.paymentMethod"
                placeholder="支付方式"
              >
                <el-option
                  v-for="item in paymentMethodOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :md="{ span: 8 }"
            :sm="{ span: 8 }"
            :xl="{ span: 8 }"
            :xs="{ span: 24 }"
          >
            <el-form-item label="充值时间">
              <el-date-picker
                v-model="listQuery.rechargeTime"
                end-placeholder="结束日期"
                range-separator="至"
                start-placeholder="开始日期"
                type="daterange"
              />
            </el-form-item>
          </el-col>
          <el-col
            :md="{ span: 6 }"
            :sm="{ span: 6 }"
            :xl="{ span: 6 }"
            :xs="{ span: 24 }"
          >
            <el-form-item>
              <el-button
                icon="el-icon-search"
                size="mini"
                type="primary"
                @click="getList"
              >
                查询
              </el-button>
              <el-button icon="el-icon-refresh-left" size="mini" @click="reset">
                重置
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table v-loading="loading" border :data="tableData" stripe>
        <el-table-column align="center" label="序号" width="50">
          <template slot-scope="scope">
            <span>
              {{
                (listQuery.pageNumber - 1) * listQuery.pageSize +
                scope.$index +
                1
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="交易编号"
          prop="tradeNo"
          show-overflow-tooltip
          width="220"
        />
        <el-table-column
          align="center"
          label="购买套餐"
          prop="rechargePackageName"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="支付方式"
          prop="paymentMethodName"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="充值金额(元)"
          prop="rechargeAmount"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>
              {{
                scope.row.rechargeAmount
                  ? Number(scope.row.rechargeAmount) / 100
                  : 0
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="充值前"
          prop="remainingBeforeRecharge"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="充值后"
          prop="remainingAfterRecharge"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="充值时间"
          prop="rechargeTime"
          show-overflow-tooltip
        />
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>
      <Pagination
        :limit.sync="listQuery.pageSize"
        :page.sync="listQuery.pageNumber"
        :total-count="totalCount"
        @pagination="getList"
      />
    </template>
  </Dialog>
</template>

<script>
  import dayjs from 'dayjs'
  import { PaymentMethodList } from '@/utils/bill'
  import Dialog from '@/components/dialog.vue'
  import Pagination from '@/components/pagination.vue'
  import { getEsignContractRechargePage } from '@/api/esign/contract'
  console.log(PaymentMethodList.dict)
  export default {
    components: {
      Dialog,
      Pagination,
    },
    data() {
      return {
        paymentMethodOptions: PaymentMethodList.dict,
        dailog: {
          height: null,
          title: '充值记录',
          visible: false,
          width: '1250px',
        },
        listQuery: {
          shopId: '',
          tradeNo: '',
          rechargeTime: '',
          startDate: '',
          endDate: '',
          loginAccount: '',
          paymentMethod: '',
          pageNumber: 1,
          pageSize: 20,
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        size: '',
      }
    },
    methods: {
      showModal(shopId) {
        this.listQuery.shopId = shopId
        this.dailog.visible = true
        this.getList()
      },
      getList() {
        this.loading = true
        const { rechargeTime, ...params } = this.listQuery
        params.startDate =
          rechargeTime && rechargeTime.length
            ? dayjs(rechargeTime[0]).format('YYYY-MM-DD')
            : undefined
        params.endDate =
          rechargeTime && rechargeTime.length
            ? dayjs(rechargeTime[1]).format('YYYY-MM-DD')
            : undefined
        getEsignContractRechargePage(params).then((res) => {
          if (res.code === 0) {
            const { records, total } = res.data
            this.tableData = records.map((item) => ({
              ...item,
              paymentMethodName: PaymentMethodList.getLabelByValue(
                item.paymentMethod
              ),
            }))
            this.totalCount = +total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      reset() {
        for (let key in this.listQuery) {
          if (key != 'shopId') this.listQuery[key] = ''
        }
        this.listQuery.pageNumber = 1
        this.listQuery.pageSize = 20
        this.getList()
      },
      handleCloseDialog() {
        this.dailog.visible = false
      },
    },
  }
</script>
