<template>
  <el-dialog
    append-to-body
    class="custom-dialog"
    stripe
    title="更改上级渠道商"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form
      label-position="top"
      label-width="80px"
      :model="queryParams"
      style="padding-left: 30px"
    >
      <el-form-item label="上级名称/手机号">
        <el-autocomplete
          v-model="queryParams.keywords"
          :fetch-suggestions="querySearchAsync"
          highlight-first-item
          placeholder="请输上级名称/手机号"
          @select="handleSelect"
        />
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="onSubmit">
        确定
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
  import {
    updateDistributorSuperior,
    getDistributorList,
  } from '@/api/distributor/index'

  export default {
    name: 'DistributorSelect',
    data() {
      return {
        queryParams: {
          keywords: '',
        },
        params: {
          id: null,
          superiorDistributorId: null,
        },
        dialogFormVisible: false,
        loading: false,
      }
    },
    created() {},
    methods: {
      async showEdit(info) {
        this.loading = false
        this.queryParams.keywords = ''
        this.params = {
          id: info.id,
          superiorDistributorId: null,
        }
        this.dialogFormVisible = true
      },
      // 图片上传end
      async onSubmit() {
        if (!this.params.superiorDistributorId) {
          this.$baseMessage(
            '请选择上级渠道商',
            'error',
            'vab-hey-message-error'
          )
          return false
        }
        if (this.params.superiorDistributorId === this.params.id) {
          this.$baseMessage(
            '不能选择自己为上级渠道商',
            'error',
            'vab-hey-message-error'
          )
          return false
        }
        this.loading = true
        updateDistributorSuperior(this.params)
          .then((res) => {
            if (res.code === 0) {
              this.$baseMessage(
                '更改成功',
                'success',
                'vab-hey-message-success'
              )
              this.$emit('fetch-data')
              this.close()
            }
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false
          })
      },
      async querySearchAsync(queryString, cb) {
        if (!queryString) {
          cb([])
        } else {
          let results = []
          let res = await getDistributorList({ keywords: queryString })
          if (res.code === 0 && res.data.length > 0) {
            results = res.data.map((o) => {
              return {
                value: `${o.name} ${o.phone}`,
                id: o.id,
              }
            })
          }
          cb(results)
        }
      },
      handleSelect(item) {
        this.params.superiorDistributorId = item.id
      },
      close() {
        this.dialogFormVisible = false
      },
    },
  }
</script>

<style lang="scss">
  @import '../../../dialog.scss';

  .custom-dialog {
    @include setDialog();
  }
</style>
