<!-- 渠道商列表 -->
<template>
  <div>
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="searchForm"
          :inline="true"
          :model="searchForm"
          @submit.native.prevent
        >
          <el-form-item label="商户名称" prop="keywords">
            <el-input v-model="searchForm.keywords" placeholder="搜索商户" />
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="searchForm.phone" placeholder="搜索手机号" />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQueryForm"
            >
              查询
            </el-button>
            <el-button
              icon="el-icon-refresh-left"
              native-type="submit"
              @click="resetQueryForm"
            >
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :cell-style="{ padding: '5px 8px' }"
      :data="tableData"
      stripe
    >
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          <span>
            {{ (queryForm.pageNumber - 1) * queryForm.pageSize + $index + 1 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="时间"
        prop="createTime"
        width="140"
      >
        <template #default="{ row }">
          {{ parseTime(row.createTime, '{y}-{m}-{d} {h}:{i}') }}
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="推荐商户"
        min-width="100"
        prop="name"
      >
        <template #default="{ row }">
          {{ row.shopName || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="商户手机号"
        min-width="120"
        prop="shopPhone"
      />

      <el-table-column
        align="center"
        label="房源数"
        min-width="80"
        prop="houseCount"
      />
      <el-table-column
        align="center"
        label="合同数"
        min-width="80"
        prop="contractCount"
      />
      <el-table-column
        align="center"
        label="账单数"
        min-width="80"
        prop="billCount"
      />
      <el-table-column
        align="center"
        label="商家平台支付总金额(元)"
        min-width="90"
        prop="billAmount"
      >
        <template #default="{ row }">
          {{ (row.billAmount || 0).toFixed(2) }}
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="该渠道获得佣金(元)"
        min-width="80"
        prop="commission"
      >
        <template #default="{ row }">
          {{ (row.commission || 0).toFixed(2) }}
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="80"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">查看</el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="queryForm.pageSize"
      :page.sync="queryForm.pageNumber"
      :total-count="totalCount"
      @pagination="fetchData"
    />
    <shop-bill-info ref="shopBill" />
  </div>
</template>
<script>
  import { getDistributorShopPage } from '@/api/distributor/index'
  import { parseTime } from '@/utils/index'
  import Pagination from '@/components/pagination.vue'
  import ShopBillInfo from './ShopBillInfo.vue'
  export default {
    name: 'DistributorShopTable',
    components: {
      Pagination,
      ShopBillInfo,
    },
    props: {
      id: {
        type: String,
        default: null,
      },
    },
    data() {
      const searchForm = {
        phone: null,
        keywords: null,
      }
      return {
        searchForm,
        queryForm: {
          pageNumber: 1,
          pageSize: 10,
          ...searchForm,
        },
        totalCount: 0,
        tableData: [],
        loading: false,
      }
    },
    mounted() {
      this.fetchData()
    },
    methods: {
      handleQueryForm() {
        this.queryForm = Object.assign({}, this.queryForm, this.searchForm, {
          pageNumber: 1,
        })
        this.fetchData()
      },
      resetQueryForm() {
        this.$refs['searchForm'].resetFields()
        this.handleQueryForm()
      },
      fetchData() {
        this.loading = true
        getDistributorShopPage({
          ...this.queryForm,
          distributorId: this.id,
        }).then((res) => {
          if (res.code === 0) {
            const { list, totalRow } = res.data
            this.tableData = list
            this.totalCount = totalRow
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      updateBatch() {},
      handleEdit(row) {
        this.$refs['shopBill'].showEdit(row)
      },
      parseTime,
    },
  }
</script>

<style lang="scss" scoped>
  :deep() {
    .el-table .cell.el-tooltip {
      min-width: auto;
    }
    .el-table--border .el-table__cell:first-child .cell {
      padding: 0;
    }
    .el-table .cell {
      padding: 0;
    }
    .vab-query-form .el-form-item + .el-form-item {
      margin-left: 15px !important;
    }
  }
</style>
