var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Dialog", {
    attrs: {
      height: _vm.dailog.height,
      "model-value": _vm.dailog.visible,
      title: _vm.dailog.title,
      visible: _vm.dailog.visible,
      width: _vm.dailog.width,
    },
    on: {
      handleCloseDialog: _vm.handleCloseDialog,
      handleConfirm: _vm.handleConfirm,
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  "label-width": "140px",
                  model: _vm.formData,
                  size: "small",
                },
              },
              [
                _c(
                  "el-row",
                  _vm._l(_vm.formData.mappings, function (item, index) {
                    return _c(
                      "el-col",
                      { key: index, attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: item.componentName,
                              prop: "mappings." + index + ".componentKey",
                              rules: [
                                {
                                  required: true,
                                  message: "请选择字段",
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: item.componentKey,
                                  callback: function ($$v) {
                                    _vm.$set(item, "componentKey", $$v)
                                  },
                                  expression: "item.componentKey",
                                },
                              },
                              _vm._l(_vm.filedsList, function (fitem, findex) {
                                return _c("el-option", {
                                  key: findex,
                                  attrs: {
                                    label: fitem.label,
                                    value: fitem.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }