<template>
  <Dialog
    :height="dailog.height"
    :model-value="dailog.visible"
    :title="dailog.title"
    :visible="dailog.visible"
    :width="dailog.width"
    @handleCloseDialog="handleCloseDialog"
    @handleConfirm="handleConfirm"
  >
    <template #content>
      <el-form ref="form" label-width="140px" :model="formData" size="small">
        <el-row>
          <el-col
            v-for="(item, index) in formData.mappings"
            :key="index"
            :span="12"
          >
            <el-form-item
              :label="item.componentName"
              :prop="'mappings.' + index + '.componentKey'"
              :rules="[
                {
                  required: true,
                  message: '请选择字段',
                  trigger: 'blur',
                },
              ]"
            >
              <el-select v-model="item.componentKey" placeholder="请选择">
                <el-option
                  v-for="(fitem, findex) in filedsList"
                  :key="findex"
                  :label="fitem.label"
                  :value="fitem.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </template>
  </Dialog>
</template>

<script>
  import Dialog from '@/components/dialog.vue'
  import {
    getEsignContractTemplateFields,
    getEsignContractTemplateBindList,
    bindEsignContractTemplateFields,
  } from '@/api/esign/contract'
  const fileds = {
    contractPerson: '业主',
    tenantName: '租客',
    houseStr: '房源',
    houseArea: '房源面积',
    startDate: '起租日期',
    endDate: '结束日期',
    billPayDay: '应支付日',
    depositAmount: '房屋押金',
    rentalAmount: '租金单价',
    propertyAmount: '物业费单价',
    waterPrice: '水费单价',
    electricityPrice: '电费单价',
  }
  export default {
    components: {
      Dialog,
    },
    data() {
      return {
        dailog: {
          height: null,
          title: '绑定字段',
          visible: false,
          width: '950px',
        },
        filedsList: Object.keys(fileds).map((item) => ({
          label: fileds[item],
          value: item,
        })),
        formData: {
          id: '',
          mappings: [],
        },
        loadingSure: false,
      }
    },
    methods: {
      showModal(id) {
        this.dailog.visible = true
        this.formData.id = id
        this.componentsList = []
        getEsignContractTemplateFields(id).then(({ data }) => {
          this.formData.mappings = data
          getEsignContractTemplateBindList(id).then((res) => {
            this.formData.mappings.forEach((element) => {
              const current = res.data.find(
                (i) => i.componentId === element.componentId
              )
              element.componentKey = current ? current.componentKey : ''
            })
          })
        })
      },
      handleCloseDialog() {
        this.dailog.visible = false
      },
      handleConfirm() {
        this.$refs['form'].validate(async (valid) => {
          if (valid && !this.loadingSure) {
            try {
              this.loadingSure = true
              await bindEsignContractTemplateFields(this.formData)
              this.handleCloseDialog()
              this.$message({
                message: '绑定成功',
                type: 'success',
                center: true,
              })
            } finally {
              this.loadingSure = false
            }
          }
        })
      },
    },
  }
</script>
