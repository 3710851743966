<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="110px"
          :model="queryForm"
          @submit.native.prevent
        >
          <el-form-item label="设备类型编号">
            <el-input
              v-model="queryForm.number"
              clearable
              placeholder="设备类型编号"
            />
          </el-form-item>
          <el-form-item label="设备类型名称">
            <el-input
              v-model="queryForm.name"
              clearable
              placeholder="设备类型名称"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetForm('form')">
              重置
            </el-button>
            <!-- <el-button type="text" @click="handleFold">
              <span v-if="fold">展开</span>
              <span v-else>合并</span>
              <vab-icon
                class="vab-dropdown"
                :class="{ 'vab-dropdown-active': fold }"
                icon="arrow-up-s-line"
              />
            </el-button> -->
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel :span="24">
        <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
          新增设备类型
        </el-button>
        <el-button
          icon="el-icon-delete"
          type="danger"
          @click="handleDelete($event)"
        >
          删除
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @selection-change="setSelectRows"
    >
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          <span>
            {{ (listQuery.pageNumber - 1) * listQuery.pageSize + $index + 1 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="设备类型编号" prop="number" />
      <el-table-column align="center" label="设备类型名称" prop="name" />
      <el-table-column align="center" label="创建时间" prop="createTime" />

      <el-table-column align="center" fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleEdit(scope.row)">
            编辑
          </el-button>
          <el-button size="mini" type="text" @click="handleDelete(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="fetchData"
    />
    <table-edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import {
    PageDeviceType,
    DeleteDeviceType,
    DeleteMultipleDeviceType,
  } from '@/api/device/type'
  import Pagination from '@/components/pagination.vue'
  import TableEdit from './components/TableEdit.vue'

  export default {
    name: 'DeviceType',
    components: {
      Pagination,
      TableEdit,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        queryForm: {
          name: '',
          number: '',
        },
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        fold: false,
      }
    },
    beforeMount() {
      window.addEventListener('resize', this.handleHeight)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleHeight)
    },
    created() {
      this.fetchData()
    },
    methods: {
      handleFold() {
        this.fold = !this.fold
        this.handleHeight()
      },
      handleHeight() {
        if (this.fold) this.height = this.$baseTableHeight(2) - 47
        else this.height = this.$baseTableHeight(3) - 30
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleAdd() {
        this.$refs['edit'].showEdit()
      },
      handleEdit(row) {
        this.$refs['edit'].showEdit(row)
      },
      handleDelete(row) {
        if (row.id) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            await DeleteDeviceType({ id: row.id })
            this.$baseMessage('删除成功', 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        } else {
          if (this.selectRows.length > 0) {
            const ids = this.selectRows.map((item) => item.id).join()
            this.$baseConfirm('你确定要删除选中项吗', null, async () => {
              await DeleteMultipleDeviceType({ ids })
              this.$baseMessage(
                '删除成功',
                'success',
                'vab-hey-message-success'
              )
              await this.fetchData()
            })
          } else {
            this.$message({
              type: 'error',
              message: '未选中任何行',
            })
          }
        }
      },
      handleQuery() {
        this.listQuery.pageNumber = 1
        this.fetchData()
      },
      async fetchData() {
        if (this.loading) return
        try {
          this.loading = true
          const {
            data: { records, total },
          } = await PageDeviceType({ ...this.queryForm, ...this.listQuery })
          this.tableData = records
          this.totalCount = +total
        } finally {
          this.loading = false
        }
      },
      toggleSelection(rows) {
        if (rows) {
          rows.forEach((row) => {
            this.$refs.tableSort.toggleRowSelection(row)
          })
        } else {
          this.$refs.tableSort.clearSelection()
        }
      },
      resetForm(formName) {
        this.$refs[formName].resetFields()
        this.queryForm = this.$options.data().queryForm
        this.fetchData()
      },
    },
  }
</script>
