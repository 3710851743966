var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "custom-dialog",
      attrs: {
        "append-to-body": "",
        title: "审核",
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-descriptions",
        { attrs: { column: 2, size: "medium" } },
        [
          _c("el-descriptions-item", { attrs: { label: "姓名" } }, [
            _vm._v(_vm._s(_vm.row.name)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "手机号" } }, [
            _vm._v(" " + _vm._s(_vm.row.phone) + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "地址", span: 2 } }, [
            _vm._v(
              " " +
                _vm._s(_vm.row.province || "") +
                _vm._s(_vm.row.city || "") +
                _vm._s(_vm.row.district || "") +
                _vm._s(_vm.row.address || "") +
                " "
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "15px" },
          attrs: {
            "label-suffix": "：",
            "label-width": "90px",
            model: _vm.form,
          },
        },
        [
          _vm.form.status === 2
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "审核意见",
                    prop: "comment",
                    rules: {
                      required: true,
                      message: "审核意见不能为空",
                      trigger: "blur",
                    },
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "40",
                      placeholder: "审核意见（必填，20字以内）",
                      rows: 3,
                      type: "textarea",
                    },
                    model: {
                      value: _vm.form.comment,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "comment", $$v)
                      },
                      expression: "form.comment",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }