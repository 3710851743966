<!-- 账单详情 -->
<template>
  <el-dialog
    append-to-body
    class="custom-dialog"
    stripe
    title="账单详情"
    :visible.sync="dialogFormVisible"
    width="720px"
    @close="close"
  >
    <el-descriptions :column="2" size="medium">
      <el-descriptions-item label="名字">
        {{ info.distributorName || '--' }}
      </el-descriptions-item>
      <el-descriptions-item label="手机号">
        {{ info.distributorPhone || '--' }}
      </el-descriptions-item>
      <el-descriptions-item label="结算范围">
        {{ info.startDate ? parseTime(info.startDate, '{y}-{m}-{d}') : '--' }} -
        {{ info.endDate ? parseTime(info.endDate, '{y}-{m}-{d}') : '--' }}
      </el-descriptions-item>
      <el-descriptions-item label="结算状态">
        {{ info.status | statusFilterName }}
      </el-descriptions-item>
    </el-descriptions>
    <el-table
      v-loading="loading"
      border
      :cell-style="{ padding: '5px 8px' }"
      :data="tableData"
      max-height="300"
      style="margin-top: 10px"
    >
      <el-table-column
        align="center"
        label="来源订单"
        min-width="140"
        prop="billId"
      >
        <template #default="{ row }">
          {{ row.billId || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="来源商户"
        min-width="160"
        prop="shopName"
      >
        <template #default="{ row }">
          {{ row.shopName || '--' }} {{ row.shopPhone || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="来源"
        min-width="100"
        prop="source"
      >
        <template #default="{ row }">
          {{ row.source | sourceFilterName }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="订单金额(元)"
        min-width="90"
        prop="billAmount"
      >
        <template #default="{ row }">
          {{ (row.billAmount || 0).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="佣金(元)"
        min-width="90"
        prop="commissionAmount"
      >
        <template #default="{ row }">
          {{ (row.commissionAmount || 0).toFixed(2) }}
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      v-if="totalCount > queryForm.pageSize"
      background
      :current-page="queryForm.pageNumber"
      layout="prev, pager, next"
      :page-size="queryForm.pageSize"
      small
      :total="totalCount"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <!-- 结算 -->
    <div class="total">
      <div class="total__item">
        <span>结算金额(元)</span>
        <span class="price">{{ (info.commission || 0).toFixed(2) }}</span>
      </div>
      <div class="total__item">
        <span>结算订单数</span>
        <span class="price">{{ info.unsettledCount }}</span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import { getChannelCommissionPage } from '@/api/distributor/finance'
  import { parseTime } from '@/utils/index'

  export default {
    name: 'BillInfo',
    filters: {
      sourceFilterName(source) {
        const sourceNameMap = {
          1: '订单一级分佣',
          2: '订单二级分佣',
        }
        return sourceNameMap[source]
      },
      statusFilterName(status) {
        const statusNameMap = {
          0: '待结算',
          1: '已结算',
        }
        return statusNameMap[status]
      },
    },
    data() {
      return {
        dialogFormVisible: false,
        info: {},
        parseTime,
        loading: false,
        queryForm: {
          pageNumber: 1,
          pageSize: 20,
          subtotalId: null,
        },
        totalCount: 0,
        tableData: [],
      }
    },
    created() {},
    methods: {
      async showEdit(row) {
        this.info = row
        this.tableData = []
        this.dialogFormVisible = true
        this.queryForm = {
          ...this.queryForm,
          pageNumber: 1,
          subtotalId: row.id,
        }
        this.fetchData()
      },
      fetchData() {
        this.loading = true
        getChannelCommissionPage({
          ...this.queryForm,
        }).then((res) => {
          if (res.code === 0) {
            const { list, totalRow } = res.data
            this.tableData = list
            this.totalCount = totalRow
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      close() {
        this.dialogFormVisible = false
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNumber = val
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .price {
    color: #f56c6c;
    font-size: 16px;
    margin-left: 10px;
  }
  :deep() {
    .el-descriptions__body .el-descriptions__table .el-descriptions-item__cell {
      line-height: 24px;
    }
  }
  .total {
    display: flex;
    padding: 30px 0 20px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    &__item {
      margin-right: 80px;
    }
  }
  .upload-btn {
    display: flex;
    font-size: 14px;
    align-items: flex-start;
    line-height: 32px;
    &__prefix {
      color: red;
      margin-right: 1px;
    }
    &__title {
      margin-right: 20px;
    }
  }
</style>
<style lang="scss">
  @import '../../../dialog.scss';

  .custom-dialog {
    @include setDialog();
  }
</style>
