<!-- 下级列表 -->
<template>
  <div>
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="searchForm"
          :inline="true"
          :model="searchForm"
          @submit.native.prevent
        >
          <el-form-item label="下级名称" prop="keywords">
            <el-input v-model="searchForm.keywords" placeholder="搜索下级" />
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="searchForm.phone" placeholder="搜索手机号" />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQueryForm"
            >
              查询
            </el-button>
            <el-button
              icon="el-icon-refresh-left"
              native-type="submit"
              @click="resetQueryForm"
            >
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :cell-style="{ padding: '5px 8px' }"
      :data="tableData"
      stripe
    >
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          <span>
            {{ (queryForm.pageNumber - 1) * queryForm.pageSize + $index + 1 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="手机号"
        min-width="120"
        prop="phone"
      />
      <el-table-column align="center" label="名称" min-width="100" prop="name">
        <template #default="{ row }">
          {{ row.name || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="二级收益(元)"
        min-width="120"
        prop="commissionAmount"
      >
        <template #default="{ row }">
          {{ (row.commissionAmount || 0).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="推广商户数"
        min-width="100"
        prop="shopCount"
      />
      <el-table-column
        align="center"
        label="注册时间"
        prop="createTime"
        width="140"
      >
        <template #default="{ row }">
          {{ parseTime(row.createTime, '{y}-{m}-{d} {h}:{i}') }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态" min-width="80" prop="status">
        <template #default="{ row }">
          <el-tag :type="row.status | statusFilter">
            {{ row.status | statusFilterName }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="80"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">详情</el-button>
          <el-button type="text" @click="handleRemove(row)">移除</el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="queryForm.pageSize"
      :page.sync="queryForm.pageNumber"
      :total-count="totalCount"
      @pagination="fetchData"
    />
  </div>
</template>
<script>
  import {
    getDistributorSecondaryPage,
    removeSecondaryDistributor,
  } from '@/api/distributor/index'
  import { parseTime } from '@/utils/index'
  import Pagination from '@/components/pagination.vue'
  export default {
    name: 'DistributorSecondarTable',
    components: {
      Pagination,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          0: '',
          1: 'success',
        }
        return statusMap[status]
      },
      statusFilterName(status) {
        const statusNameMap = {
          0: '禁用',
          1: '正常',
        }
        return statusNameMap[status]
      },
    },

    props: {
      id: {
        type: String,
        default: null,
      },
    },
    data() {
      const searchForm = {
        phone: null,
        keywords: null,
      }
      return {
        searchForm,
        queryForm: {
          pageNumber: 1,
          pageSize: 10,
          ...searchForm,
        },
        totalCount: 0,
        tableData: [],
        loading: false,
      }
    },
    mounted() {
      this.fetchData()
    },
    methods: {
      handleQueryForm() {
        this.queryForm = Object.assign({}, this.queryForm, this.searchForm, {
          pageNumber: 1,
        })
        this.fetchData()
      },
      resetQueryForm() {
        this.$refs['searchForm'].resetFields()
        this.handleQueryForm()
      },
      fetchData() {
        this.loading = true
        getDistributorSecondaryPage({
          ...this.queryForm,
          distributorId: this.id,
        }).then((res) => {
          if (res.code === 0) {
            const { list, totalRow } = res.data
            this.tableData = list
            this.totalCount = totalRow
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      updateBatch() {},
      handleEdit(row) {
        this.$router.push({
          name: 'DistributorManageSecondInfo',
          query: {
            id: row.id,
          },
        })
      },
      handleRemove(row) {
        this.$baseConfirm('是否确认移除？', null, async () => {
          const res = await removeSecondaryDistributor({
            secondaryDistributorId: row.id,
            id: this.id,
          })
          if (res.code === 0) {
            this.$baseMessage('移除成功', 'success', 'vab-hey-message-success')
            await this.fetchData()
            this.$emit('fetch-data')
          }
        })
      },
      parseTime,
    },
  }
</script>

<style lang="scss" scoped>
  :deep() {
    .el-table .cell.el-tooltip {
      min-width: auto;
    }
    .el-table--border .el-table__cell:first-child .cell {
      padding: 0;
    }
    .el-table .cell {
      padding: 0;
    }
    .vab-query-form .el-form-item + .el-form-item {
      margin-left: 15px !important;
    }
  }
</style>
