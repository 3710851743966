<template>
  <Dialog
    :height="dailog.height"
    :model-value="dailog.visible"
    :title="title + dailog.title"
    :visible="dailog.visible"
    :width="dailog.width"
    @handleCloseDialog="handleCloseDialog"
    @handleConfirm="handleConfirm"
  >
    <template #content>
      <el-form
        ref="form"
        label-width="168px"
        :model="formData"
        :rules="rules"
        size="small"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="设备类型" prop="typeId">
              <el-select
                v-model="formData.typeId"
                placeholder="请选择"
                style="width: 300px"
              >
                <el-option
                  v-for="item in equipmentTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="设备品牌" prop="brandId">
              <el-select
                v-model="formData.brandId"
                placeholder="请选择"
                style="width: 300px"
              >
                <el-option
                  v-for="item in optionsBrand"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="设备型号编号" prop="number">
              <el-input
                v-model="formData.number"
                clearable
                :disabled="disabled"
                placeholder="请输入..."
                style="max-width: 300px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="设备型号名称" prop="name">
              <el-input
                v-model="formData.name"
                clearable
                :disabled="disabled"
                placeholder="请输入..."
                style="max-width: 300px"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </template>
  </Dialog>
</template>

<script>
  import { translateTitle } from '@/utils/i18n'
  import { SelectDeviceBrand } from '@/api/device/brand'
  import {
    SaveDeviceModel,
    UpdateDeviceModel,
    DetailDeviceModel,
  } from '@/api/device/model'
  import { SelectDeviceType } from '@/api/device/type'
  import Dialog from '@/components/dialog.vue'
  // import { isNumber } from '@/utils/validate'

  export default {
    name: 'TableEdit',
    components: {
      Dialog,
    },
    data() {
      /* const validateNumber = (rule, value, callback) => {
        if (!isNumber(value)) {
          callback(new Error(this.translateTitle('请输入数字')))
        } else {
          callback()
        }
      } */
      return {
        title: '添加',
        dailog: {
          height: 200,
          title: '设备型号',
          visible: false,
          width: '600px',
        },
        disabled: false,
        optionsBrand: [],
        equipmentTypeList: [],
        formData: {
          brandId: '',
          sortRank: '',
          number: '',
          name: '',
          typeId: '',
        },
        rules: {
          brandId: [
            {
              required: true,
              message: '请选择设备品牌',
              trigger: 'change',
            },
          ],
          /* sortRank: [
            {
              required: true,
              message: '请输入序号',
              trigger: 'blur',
            },
            { validator: validateNumber, trigger: 'blur' },
          ], */
          number: [
            {
              required: true,
              message: '请输入设备型号编号',
              trigger: 'blur',
            },
          ],
          name: [
            {
              required: true,
              message: '请输入设备型号名称',
              trigger: 'blur',
            },
          ],
        },
        id: '',
        loadingSure: false,
      }
    },
    created() {
      this.fetchBrand()
      this.selectDeviceTypeData()
    },
    methods: {
      async selectDeviceTypeData() {
        const { data } = await SelectDeviceType()
        this.equipmentTypeList = data.map((item) => {
          return {
            label: item.name,
            value: item.id,
          }
        })
      },
      translateTitle,
      showEdit(row) {
        if (!row) {
          this.title = '添加'
        } else {
          this.title = '编辑'
          this.fetchData(row.id)
        }
        this.dailog.visible = true
      },
      async fetchBrand() {
        const { data } = await SelectDeviceBrand()
        this.optionsBrand = data.map((item) => {
          return {
            label: item.name,
            value: item.id,
          }
        })
      },
      async fetchData(id) {
        const {
          data: { sortRank, name, number, brandId, typeId },
        } = await DetailDeviceModel({ id })
        this.id = id
        this.formData.sortRank = sortRank
        this.formData.name = name
        this.formData.number = number
        this.formData.brandId = brandId
        this.formData.typeId = typeId
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.$refs['form'].resetFields()
        this.formData = this.$options.data().formData
      },
      handleConfirm() {
        this.$refs['form'].validate(async (valid) => {
          if (valid && !this.loadingSure) {
            try {
              this.loadingSure = true
              const { name, number, sortRank, brandId, typeId } = this.formData
              if (this.title == '添加') {
                await SaveDeviceModel({
                  sortRank,
                  name,
                  number,
                  brandId,
                  typeId,
                })
              } else {
                await UpdateDeviceModel({
                  sortRank,
                  name,
                  number,
                  brandId,
                  id: this.id,
                  typeId,
                })
              }
              this.$message({
                type: 'success',
                message: this.title + '成功',
              })
              this.$emit('fetch-data')
              this.handleCloseDialog()
            } finally {
              this.loadingSure = false
            }
          }
        })
      },
    },
  }
</script>
