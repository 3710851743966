<template>
  <Dialog
    :height="dailog.height"
    :model-value="dailog.visible"
    :title="dailog.title"
    :visible="dailog.visible"
    :width="dailog.width"
    @handleCloseDialog="handleCloseDialog"
    @handleConfirm="handleCloseDialog"
  >
    <template #content>
      <div class="view-container">
        <div class="view-barcode">
          <img
            ref="refBarcodeBg"
            src="@/assets/barcode/bg.png"
            style="width: 800px; margin: 0 auto"
          />
          <img id="barcode" ref="refBarcode" class="barcode" />
        </div>
        <span class="tips" @click="handleSave">点击保存图片</span>
      </div>
    </template>
  </Dialog>
</template>

<script>
  import JsBarcode from 'jsbarcode'
  import Dialog from '@/components/dialog.vue'

  export default {
    name: 'Barcode',
    components: {
      Dialog,
    },
    data() {
      return {
        appName: process.env.VUE_APP_BASE_TITLE,
        dailog: {
          height: null,
          title: '查看标签',
          visible: false,
          width: '950px',
        },
        deviceNo: '',
      }
    },
    mounted() {},
    methods: {
      showModal(deviceNo) {
        this.deviceNo = deviceNo
        this.dailog.visible = true
        this.$nextTick(() => {
          JsBarcode('#barcode', deviceNo, {
            format: 'CODE128',
            font: '"PingFang SC", Arial, "Microsoft YaHei", sans-serif',
            lineColor: '#000',
            background: '#FFFFFF',
            width: 2,
            height: 84,
            fontSize: '30px',
            displayValue: true,
            textMargin: 20,
          })
        })
      },
      handleSave() {
        let img1 = new Image()
        img1.src = this.$refs['refBarcodeBg'].src
        img1.width = 1000
        img1.height = 466
        img1.setAttribute('crossOrigin', 'anonymous')
        let canvas = document.createElement('canvas')
        let context = canvas.getContext('2d')
        canvas.width = 1000
        canvas.height = 466
        let img2 = new Image()
        let flag = true
        // 将 img1 加入画布
        img1.onload = () => {
          context.drawImage(img1, 0, 0, 1000, 466)
          img2.src = this.$refs['refBarcode'].src
          img2.setAttribute('crossOrigin', 'anonymous')
          if (flag) {
            flag = false
          } else {
            canvas.toBlob((blob) => {
              let url = URL.createObjectURL(blob)
              this.download(url, this.appName + ' 设备标签')
              // 用完释放URL对象
              URL.revokeObjectURL(url)
            })
          }
        }
        // 将 img2 加入画布
        img2.onload = () => {
          context.drawImage(
            img2,
            720 - img2.width / 2,
            235,
            img2.width,
            img2.height
          )
          if (flag) {
            flag = false
          } else {
            canvas.toBlob((blob) => {
              let url = URL.createObjectURL(blob)
              this.download(url, `zss_label_${this.deviceNo}`)
              // 用完释放URL对象
              URL.revokeObjectURL(url)
            })
          }
        }
      },
      download(href, name) {
        let eleLink = document.createElement('a')
        eleLink.download = name
        eleLink.href = href
        eleLink.click()
        eleLink.remove()
      },
      handleCloseDialog() {
        this.dailog.visible = false
      },
    },
  }
</script>

<style scoped lang="scss">
  .view-container {
    text-align: center;
    .view-barcode {
      position: relative;
      width: 800px;
      margin: 30px auto;
      border: 1px solid #ddd;
      box-shadow: 2px 0px 1px #ddd;
      img {
        display: block;
      }
      .barcode {
        position: absolute;
        left: 580px;
        transform: translateX(-50%);
        bottom: 40px;
      }
    }
    .tips {
      padding: 0 20px;
      cursor: pointer;
      color: #999;
      display: inline-block;
      text-align: center;
      height: 38px;
      line-height: 38px;
      border: 1px solid #ddd;
      border-radius: 8px;
    }
  }
</style>
