<template>
  <div class="personal-center-container">
    <el-page-header :content="'发票详情'" @back="goBack" />
    <div
      v-if="detailData.status == 'REJECT' || detailData.status == 'COMPLETE'"
      class="box-card"
    >
      <el-descriptions border class="margin-top" :column="1" title="发票信息">
        <el-descriptions-item>
          <template slot="label">发票状态</template>
          <el-tag v-if="detailData.status == 'INVOICING'" type="pamary">
            开票中
          </el-tag>
          <el-tag v-if="detailData.status == 'COMPLETE'" type="success">
            已开票
          </el-tag>
          <el-tag v-if="detailData.status == 'REJECT'" type="danger">
            已驳回
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">审核信息</template>
          {{ detailData.rejectReason }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">发票类型</template>
          <span v-if="detailData.type == 'ORDINARY'">普通发票</span>
          <span v-if="detailData.type == 'VAT'">增值税专用发票</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">抬头类型</template>
          <span v-if="detailData.titleType == 'PERSONAL'">个人</span>
          <span v-if="detailData.titleType == 'ENTERPRISE'">企业</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">发票抬头</template>
          {{ detailData.title }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">纳税人识别号</template>
          {{ detailData.taxId || '--' }}
        </el-descriptions-item>
        <template v-if="detailData.titleType == 'ENTERPRISE'">
          <el-descriptions-item>
            <template slot="label">注册地址</template>
            {{ detailData.address || '--' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">注册电话</template>
            {{ detailData.phone || '--' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">开户银行</template>
            {{ detailData.bank || '--' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">银行账号</template>
            {{ detailData.bankAccount || '--' }}
          </el-descriptions-item>
        </template>
        <el-descriptions-item>
          <template slot="label">费用类型</template>
          <span v-if="detailData.costType == 'RECHARGE'">充值</span>
          <span v-if="detailData.costType == 'SERVICE_FEE'">账单</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">发票金额</template>
          {{ detailData.amount }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">发票附件</template>
          <span
            v-if="detailData.fileUrl"
            style="color: blue; cursor: pointer"
            @click="previewImg"
          >
            下载
          </span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">电子邮箱</template>
          {{ detailData.email }}
        </el-descriptions-item>
        <template v-if="detailData.type == 'VAT'">
          <el-descriptions-item>
            <template slot="label">收件人姓名</template>
            {{ detailData.email }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">收件人电话</template>
            {{ detailData.email }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">收件人地址</template>
            {{ detailData.email }}
          </el-descriptions-item>
        </template>
        <el-descriptions-item>
          <template slot="label">申请时间</template>
          {{ detailData.createTime }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">备注</template>
          {{ detailData.remark || '--' }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        border
        :column="1"
        style="margin-top: 10px"
        title="关联费用清单"
      />
      <!-- todo 充值型 -->
      <el-table
        v-if="detailData.costType == 'RECHARGE'"
        border
        :data="detailData.walletRechargeOrderList"
        stripe
      >
        <el-table-column align="center" label="流水编号" prop="orderNo" />
        <el-table-column align="center" label="类型" prop="status">
          <template slot-scope="">
            <!-- <el-tag v-if="scope.row.status == 1" type="success">已完成</el-tag>
            <el-tag v-if="scope.row.status == 2" type="parimary">
              已待支付
            </el-tag>
            <el-tag v-if="scope.row.status == 3" type="danger">已取消</el-tag>
            <el-tag v-if="scope.row.status == 4" type="info">已关闭</el-tag> -->
            充值
          </template>
        </el-table-column>
        <el-table-column align="center" label="金额(元)" prop="totalAmount" />
        <el-table-column align="center" label="创建时间" prop="createTime" />
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>
      <!-- todo 账单服务费 -->
      <el-table
        v-if="detailData.costType == 'SERVICE_FEE'"
        border
        :data="detailData.settlementList"
        stripe
      >
        <el-table-column align="center" label="账单号" prop="id" />
        <el-table-column align="center" label="账单类型" prop="costType">
          <template slot-scope="">账单</template>
        </el-table-column>
        <el-table-column
          align="center"
          label="总服务费(元)"
          prop="totalServiceFee"
        />
        <el-table-column align="center" label="结算时间" prop="realPayTime" />
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>
    </div>
    <!-- todo开票 -->
    <el-card v-else>
      <el-form ref="ruleForm" label-width="" :model="ruleForm" :rules="rules">
        <el-descriptions border class="margin-top" :column="1" title="发票信息">
          <!-- todo基础 -->
          <el-descriptions-item>
            <template slot="label">发票状态</template>
            <el-tag v-if="detailData.status == 'INVOICING'" type="pamary">
              开票中
            </el-tag>
            <el-tag v-if="detailData.status == 'COMPLETE'" type="success">
              已开票
            </el-tag>
            <el-tag v-if="detailData.status == 'REJECT'" type="danger">
              已驳回
            </el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">审核信息</template>
            {{ detailData.rejectReason }}
          </el-descriptions-item>
          <!-- //todo 企业 -->
          <el-descriptions-item>
            <template slot="label">发票类型</template>
            <el-form-item label="" prop="type">
              <el-select v-model="ruleForm.type" placeholder="请选择发票类型">
                <el-option label="普通发票" value="ORDINARY" />
                <el-option label="增值税专用发票" value="VAT" />
              </el-select>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">抬头类型</template>
            <span v-if="detailData.titleType == 'PERSONAL'">个人</span>
            <span v-if="detailData.titleType == 'ENTERPRISE'">企业</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">发票金额</template>
            {{ detailData.amount }}元
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">发票附件</template>
            <JMUpload
              file-type="text"
              :multiple="false"
              :number="1"
              @change="handleChange"
            />
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">发票抬头</template>
            <el-form-item label="" prop="title">
              <el-input v-model="ruleForm.title" placeholder="请输入发票抬头" />
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">纳税人识别号</template>
            {{ detailData.taxId || '' }}
          </el-descriptions-item>
          <template v-if="detailData.titleType == 'ENTERPRISE'">
            <el-descriptions-item>
              <template slot="label">注册地址</template>
              {{ detailData.address || '--' }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">注册电话</template>
              {{ detailData.phone || '--' }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">开户银行</template>
              {{ detailData.bank || '--' }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">银行账号</template>
              {{ detailData.bankAccount || '--' }}
            </el-descriptions-item>
          </template>
          <el-descriptions-item>
            <template slot="label">费用类型</template>
            <span v-if="detailData.costType == 'RECHARGE'">充值</span>
            <span v-if="detailData.costType == 'SERVICE_FEE'">账单</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">电子邮箱</template>
            {{ detailData.email }}
          </el-descriptions-item>
          <template v-if="detailData.type == 'VAT'">
            <el-descriptions-item>
              <template slot="label">收件人姓名</template>
              {{ detailData.email }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">收件人电话</template>
              {{ detailData.email }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">收件人地址</template>
              {{ detailData.email }}
            </el-descriptions-item>
          </template>
          <el-descriptions-item>
            <template slot="label">申请时间</template>
            {{ detailData.createTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">备注</template>
            <el-form-item label="">
              <el-input v-model="ruleForm.remark" placeholder="请输入备注" />
            </el-form-item>
          </el-descriptions-item>
        </el-descriptions>
      </el-form>

      <el-descriptions
        border
        :column="1"
        style="margin-top: 10px"
        title="关联费用清单"
      />
      <!-- todo 充值型 -->
      <el-table
        v-if="detailData.costType == 'RECHARGE'"
        border
        :data="detailData.walletRechargeOrderList"
        stripe
      >
        <el-table-column align="center" label="流水编号" prop="orderNo" />
        <el-table-column align="center" label="类型" prop="status">
          <template slot-scope="">
            <!-- <el-tag v-if="scope.row.status == 1" type="success">已完成</el-tag>
            <el-tag v-if="scope.row.status == 2" type="parimary">
              已待支付
            </el-tag>
            <el-tag v-if="scope.row.status == 3" type="danger">已取消</el-tag>
            <el-tag v-if="scope.row.status == 4" type="info">已关闭</el-tag> -->
            充值
          </template>
        </el-table-column>
        <el-table-column align="center" label="金额(元)" prop="totalAmount" />
        <el-table-column align="center" label="创建时间" prop="createTime" />
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>
      <!-- todo 账单服务费 -->
      <el-table
        v-if="detailData.costType == 'SERVICE_FEE'"
        border
        :data="detailData.settlementList"
        stripe
      >
        <el-table-column align="center" label="结算单号" prop="id" />
        <el-table-column align="center" label="账单类型" prop="costType">
          <template slot-scope="">账单服务费</template>
        </el-table-column>
        <el-table-column
          align="center"
          label="总服务费(元)"
          prop="withdrawServiceAmount"
        />
        <el-table-column align="center" label="结算时间" prop="realPayTime" />
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>
    </el-card>
    <TurnDownReasonVue
      v-if="turnDownVisible"
      :turn-down-visible.sync="turnDownVisible"
      @trunDownConfirm="trunDownConfirm"
    />
    <div>
      <el-button
        v-if="detailData.status == 'INVOICING'"
        type="primary"
        @click="invoicing"
      >
        开票
      </el-button>
      <el-button v-if="detailData.status == 'INVOICING'" @click="turnDown">
        驳回
      </el-button>
    </div>
  </div>
</template>

<script>
  import JMUpload from '@/components/jm-upload'
  import TurnDownReasonVue from '../components/turnDownReason.vue'
  import { handleActivePath } from '@/utils/routes'
  import { mapActions } from 'vuex'
  import {
    invoiceDetail,
    makeInvoice,
    invoiceReject,
  } from '@/api/financial/newIndex'
  export default {
    name: 'ManagementBill',
    components: { JMUpload, TurnDownReasonVue },
    data() {
      return {
        turnDownVisible: false,
        tableData: [],
        detailData: {},
        formData: {},
        ruleForm: {
          content: '',
          remark: '',
          taxId: '',
          title: '',
          type: '0',
        },
        rules: {
          taxId: [{ required: true, message: '请输入税号', trigger: 'blur' }],
          title: [
            { required: true, message: '请输入发票抬头', trigger: 'blur' },
          ],
        },
      }
    },
    created() {
      this.getInvoiceDetail()
    },
    methods: {
      ...mapActions({
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      trunDownConfirm(val) {
        invoiceReject({ rejectReason: val, id: this.detailData.id }).then(
          (res) => {
            if (res.code === 0) {
              this.turnDownVisible = false
              this.goBack()
              return this.$message.success(res.msg)
            }
          }
        )
      },
      turnDown() {
        this.turnDownVisible = true
      },
      handleChange(val) {
        this.ruleForm.fileUrl = val
      },
      previewImg() {
        window.open(this.detailData.fileUrl, '_blanck')
      },
      invoicing() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            let params = {
              content: this.ruleForm.content,
              fileUrl: this.ruleForm.fileUrl,
              id: this.ruleForm.id,
              remark: this.ruleForm.remark,
              taxId: this.ruleForm.taxId,
              title: this.ruleForm.title,
              type: this.ruleForm.type,
            }
            makeInvoice(params).then((res) => {
              if (res.code === 0) {
                this.goBack()
                this.$message.success(res.msg)
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      async goBack() {
        const detailPath = await handleActivePath(this.$route, true)
        this.$router.push({ name: 'InvoiceManagement' })
        this.delVisitedRoute(detailPath)
      },
      handleDetail() {},
      getInvoiceDetail() {
        invoiceDetail(this.$route.params.id).then((res) => {
          this.detailData = res.data
          this.ruleForm = { ...this.ruleForm, ...res.data }
        })
      },
    },
  }
</script>

<style lang="scss" scoped></style>
