<template>
  <div class="comprehensive-table-container">
    <el-form
      ref="form"
      label-width="100px"
      :model="formData"
      :rules="rules"
      size="small"
    >
      <div class="card-title">订单信息</div>
      <el-row>
        <el-col :span="12">
          <el-form-item label="商户名称" prop="shopName">
            <el-autocomplete
              v-model="formData.shopName"
              class="inline-input"
              clearable
              :fetch-suggestions="querySearch"
              :highlight-first-item="true"
              placeholder="请输入..."
              style="width: 340px"
              :trigger-on-focus="false"
              @select="handleSelect"
            />
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
          <el-form-item label="商户编号" prop="shopId">
            {{ formData.shopId }}
          </el-form-item>
        </el-col> -->
        <el-col :span="12">
          <el-form-item label="出货时间" prop="outWarehouseTime">
            <el-date-picker
              v-model="formData.outWarehouseTime"
              placeholder="请选择"
              style="width: 340px"
              type="datetime"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row>
        <el-col :span="12">
          <el-form-item
            v-if="formData.orderNo"
            label="订单编号"
            prop="orderNo"
          >
            {{ formData.orderNo }}
          </el-form-item>
        </el-col>
      </el-row> -->
      <el-row>
        <el-col :span="12">
          <el-form-item label="收货人姓名" prop="receiver">
            <el-input
              v-model="formData.receiver"
              clearable
              placeholder="请输入..."
              style="max-width: 340px"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收货人电话" prop="receiverPhone">
            <el-input
              v-model="formData.receiverPhone"
              clearable
              placeholder="请输入..."
              style="max-width: 340px"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收货地址" prop="receiverAddress">
            <el-input
              v-model="formData.receiverAddress"
              clearable
              placeholder="请输入..."
              style="max-width: 340px"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="订单金额" prop="orderPrice">
            <el-input
              v-model="formData.orderPrice"
              clearable
              placeholder="请输入..."
              style="max-width: 340px"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="订单备注" prop="type">
            <el-input
              v-model="formData.remark"
              maxlength="2000"
              placeholder="可以换行输入,至多2000字"
              :rows="3"
              style="width: 340px"
              type="textarea"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-divider />
    <el-form
      ref="formPro"
      label-width="0"
      :model="deviceForm"
      :rules="deviceRules"
      size="small"
    >
      <div class="card-title">商品信息</div>
      <el-row :gutter="10">
        <el-col :span="5">
          <el-form-item label="" prop="typeId">
            <el-select
              v-model="deviceForm.typeId"
              clearable
              placeholder="设备类型"
              @change="handleDeviceType"
            >
              <el-option
                v-for="item in optionsType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="" prop="brandId">
            <el-select
              v-model="deviceForm.brandId"
              clearable
              placeholder="设备品牌"
              @change="fetchModel"
            >
              <el-option
                v-for="item in optionsBrand"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="5">
          <el-form-item label="" prop="modelId">
            <el-select
              v-model="deviceForm.modelId"
              clearable
              placeholder="设备型号"
            >
              <el-option
                v-for="item in optionsModel"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="" prop="deviceNo">
            <el-input
              v-model="deviceForm.deviceNo"
              clearable
              placeholder="请填写或扫码录入设备编号"
              style="max-width: 340px"
            >
              <el-button
                v-if="
                  ['SMART_LOCK', 'SMART_GATEWAY'].includes(currentDeviceNumber)
                "
                slot="append"
                icon="el-icon-refresh"
                @click="handleRandomNo"
              >
                随机
              </el-button>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-button
            style="width: 100%"
            type="primary"
            @click="handleAddDevice2"
          >
            添加
          </el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      style="margin-bottom: 18px"
    >
      <!--
          @select="selectRow"
          @select-all="selectRow"<el-table-column align="center" type="selection" width="50" /> -->
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          <span>
            {{ $index + 1 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="设备类型" prop="typeName" />
      <el-table-column align="center" label="设备品牌" prop="brandName" />
      <el-table-column align="center" label="设备型号" prop="modelName" />
      <el-table-column align="center" label="设备编号" prop="deviceNo">
        <template #default="{ row }">
          <span>
            {{ row.deviceNo }}
          </span>
          <el-button type="text" @click="handleViewBarcode(row.deviceNo)">
            查看标签
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="添加时间"
        prop="createTime"
        sortable
      />
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="handleDeleteDevice(scope.row, scope.$index)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <!-- <Pagination
          :limit.sync="listQuery.pageSize"
          :page.sync="listQuery.pageNumber"
          :total-count="totalCount"
          @pagination="fetchData"
        /> -->
    <div class="btns">
      <el-button type="primary" @click="handleConfirm">提交</el-button>
      <el-button icon="el-icon-refresh-left" @click="resetForm">重置</el-button>
    </div>
    <barcode ref="viewBarcode" />
  </div>
</template>

<script>
  import { random } from '@/utils'
  import dayjs from 'dayjs'
  import { mapActions } from 'vuex'
  import { translateTitle } from '@/utils/i18n'
  // import Pagination from '@/components/pagination.vue'
  import { isNumber, isPhone } from '@/utils/validate'
  import { FindShopByName, SaveDeviceOrder } from '@/api/device/shipment'
  import { SelectDeviceType } from '@/api/device/type'
  import { SelectDeviceBrand } from '@/api/device/brand'
  import { SelectDeviceModel } from '@/api/device/model'
  import barcode from '../components/barcode.vue'
  export default {
    name: 'DeviceShipmentAdd',
    components: {
      // Pagination,
      barcode,
    },
    data() {
      const validateNumber = (rule, value, callback) => {
        if (value && !isNumber(value)) {
          callback(new Error(this.translateTitle('请输入金额')))
        } else {
          callback()
        }
      }
      const validatePhone = (rule, value, callback) => {
        if (value && !isPhone(value)) {
          callback(new Error(this.translateTitle('请输入正确的手机号')))
        } else {
          callback()
        }
      }
      const validateShopName = (rule, value, callback) => {
        if (value && !this.formData.shopId) {
          callback(new Error(this.translateTitle('未找到该商户')))
        } else {
          callback()
        }
      }
      return {
        id: '',
        formData: {
          orderNo: '',
          shopName: '',
          shopId: '',
          outWarehouseTime: new Date(),
          orderPrice: '',
          remark: '',
        },
        rules: {
          shopName: [
            {
              required: true,
              message: '请搜索并选择商户名称',
              trigger: 'change',
            },
            { validator: validateShopName, trigger: 'blur' },
          ],
          outWarehouseTime: [
            {
              required: true,
              message: '请选择出货时间',
              trigger: 'change',
            },
          ],
          receiver: [
            {
              required: false,
              message: '请输入收货人姓名',
              trigger: 'blur',
            },
          ],
          receiverPhone: [
            {
              required: false,
              message: '请输入收货人电话',
              trigger: 'blur',
            },
            { validator: validatePhone, trigger: 'blur' },
          ],
          receiverAddress: [
            {
              required: false,
              message: '请输入收货地址',
              trigger: 'blur',
            },
          ],
          orderPrice: [
            {
              required: false,
              message: '请输入订单金额',
              trigger: 'blur',
            },
            { validator: validateNumber, trigger: 'blur' },
          ],
        },
        deviceForm: {
          typeId: '',
          brandId: '',
          modelId: '',
          deviceNo: '',
        },
        deviceRules: {
          typeId: [
            {
              required: true,
              message: '请选择设备类型',
              trigger: 'change',
            },
          ],
          brandId: [
            {
              required: true,
              message: '请选择品牌名称',
              trigger: 'change',
            },
          ],
          modelId: [
            {
              required: true,
              message: '请选择产品型号',
              trigger: 'change',
            },
          ],
          deviceNo: [
            {
              required: true,
              message: '请填写或扫码录入设备编号',
              trigger: 'blur',
            },
          ],
        },
        optionsType: [],
        optionsBrand: [],
        optionsModel: [],
        tableData: [],
        loading: false,
        index: 0,
        loadingSure: false,
        currentDeviceNumber: '',
      }
    },
    created() {
      this.fetchType()
      this.fetchBrand()
    },
    methods: {
      handleViewBarcode(deviceNo) {
        this.$refs['viewBarcode'].showModal(deviceNo)
      },
      ...mapActions({
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      translateTitle,
      //表格选择
      selectRow(selection, row) {
        console.log('单选===', selection)
        console.log('单选===', row)
      },
      handleDeviceType(e) {
        this.currentDeviceNumber = this.optionsType.find(
          (item) => e === item.value
        )?.number
        this.deviceForm.deviceNo = ''
      },
      handleRandomNo() {
        const prefix = {
          SMART_LOCK: 'L',
          SMART_GATEWAY: 'G',
        }
        const randomNo = `${prefix[this.currentDeviceNumber]}${dayjs().format(
          'YYYYMMDD'
        )}${random(100000, 999999)}`
        this.deviceForm.deviceNo = randomNo
      },
      async querySearch(queryString, cb) {
        let results = []
        if (queryString) {
          const { data } = await FindShopByName({
            shopName: queryString,
          })
          this.formData.shopId = ''
          const restaurants = data || []
          results = restaurants.map((item) => {
            return {
              ...item,
              value: item.name || '',
            }
          })
        }
        cb(results)
      },
      handleSelect(item) {
        console.log(item)
        this.formData.shopId = item.id
        this.formData.shopName = item.name
        this.$refs['form'].validateField('shopName')
      },
      async fetchType() {
        const { data } = await SelectDeviceType()
        this.optionsType = data.map((item) => {
          return {
            label: item.name,
            value: item.id,
            number: item.number,
          }
        })
      },
      async fetchBrand() {
        const { data } = await SelectDeviceBrand()
        this.optionsBrand = data.map((item) => {
          return {
            label: item.name,
            value: item.id,
          }
        })
      },
      async fetchModel(val) {
        const { data } = await SelectDeviceModel({
          brandId: val,
        })
        this.optionsModel = data.map((item) => {
          return {
            label: item.name,
            value: item.id,
          }
        })
        this.deviceForm.modelId = ''
      },
      resetForm() {
        this.$refs['form'].resetFields()
        this.$refs['formPro'].resetFields()
        this.formData = this.$options.data().formData
        this.deviceForm = this.$options.data().deviceForm
        this.tableData = this.$options.data().tableData
      },
      handleConfirm() {
        this.$refs['form'].validate(async (valid) => {
          if (valid && !this.loadingSure && this.tableData.length) {
            this.loadingSure = true
            try {
              const {
                remark,
                orderPrice,
                receiver,
                receiverAddress,
                receiverPhone,
                orderNo,
                shopId,
                outWarehouseTime,
                devices,
              } = this.formData
              const deviceJson = this.tableData.map((item) => {
                const { typeId, brandId, modelId, deviceNo } = item
                return {
                  typeId,
                  brandId,
                  modelId,
                  deviceNo,
                }
              })
              await SaveDeviceOrder({
                remark,
                orderPrice,
                receiver,
                receiverAddress,
                receiverPhone,
                orderNo,
                shopId,
                outWarehouseTime:
                  dayjs(outWarehouseTime).format('YYYY-M-D HH:mm:ss'),
                devices,
                deviceJson: JSON.stringify(deviceJson),
              })
              this.$baseMessage(
                '操作成功',
                'success',
                'vab-hey-message-success'
              )
              this.delVisitedRoute()
              this.$router.push({
                name: 'DeviceShipment',
              })
            } finally {
              this.loadingSure = false
            }
          }
        })
      },
      handleAddDevice2() {
        this.$refs['formPro'].validate(async (valid) => {
          if (valid) {
            const { typeId, brandId, modelId, deviceNo } = this.deviceForm
            const createTime = dayjs().format('YYYY-M-D HH:mm:ss')
            const typeName = this.optionsType.find(
              (item) => item.value === typeId
            ).label
            console.log(typeName)
            const brandName = this.optionsBrand.find(
              (item) => item.value === brandId
            ).label
            const modelName = this.optionsModel.find(
              (item) => item.value === modelId
            ).label
            this.tableData.push({
              index: this.index + 1,
              typeId,
              brandId,
              modelId,
              deviceNo: deviceNo.trim(),
              typeName,
              brandName,
              modelName,
              createTime,
            })
            this.$baseMessage('添加成功', 'success', 'vab-hey-message-success')
            this.deviceForm.deviceNo = ''
          }
        })
      },
      handleDeleteDevice(row, rowIndex) {
        this.$baseConfirm(
          `你确定要删除设备编号：${row.deviceNo}吗`,
          null,
          async () => {
            this.tableData.splice(rowIndex, 1)
          }
        )
      },
    },
  }
</script>

<style scoped lang="scss">
  ::v-deep(.container) {
    padding: 0 20px;
  }
  .card-title {
    font-size: 16px;
    margin-bottom: 18px;
  }
  ::v-deep(.el-select) {
    display: block;
    position: relative;
  }
  .btns {
    text-align: center;
    margin: 30px auto 0;
  }
</style>
