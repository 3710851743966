var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Dialog", {
    attrs: {
      height: _vm.dailog.height,
      "model-value": _vm.dailog.visible,
      title: _vm.dailog.title,
      visible: _vm.dailog.visible,
      width: _vm.dailog.width,
    },
    on: {
      handleCloseDialog: _vm.handleCloseDialog,
      handleConfirm: _vm.handleCloseDialog,
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", { staticClass: "view-container" }, [
              _c("div", { staticClass: "view-barcode" }, [
                _c("img", {
                  ref: "refBarcodeBg",
                  staticStyle: { width: "800px", margin: "0 auto" },
                  attrs: { src: require("@/assets/barcode/bg.png") },
                }),
                _c("img", {
                  ref: "refBarcode",
                  staticClass: "barcode",
                  attrs: { id: "barcode" },
                }),
              ]),
              _c(
                "span",
                { staticClass: "tips", on: { click: _vm.handleSave } },
                [_vm._v("点击保存图片")]
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }