var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "personal-center-container" },
    [
      _c("el-page-header", {
        attrs: { content: "发票详情" },
        on: { back: _vm.goBack },
      }),
      _vm.detailData.status == "REJECT" || _vm.detailData.status == "COMPLETE"
        ? _c(
            "div",
            { staticClass: "box-card" },
            [
              _c(
                "el-descriptions",
                {
                  staticClass: "margin-top",
                  attrs: { border: "", column: 1, title: "发票信息" },
                },
                [
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [_vm._v("发票状态")]),
                      _vm.detailData.status == "INVOICING"
                        ? _c("el-tag", { attrs: { type: "pamary" } }, [
                            _vm._v(" 开票中 "),
                          ])
                        : _vm._e(),
                      _vm.detailData.status == "COMPLETE"
                        ? _c("el-tag", { attrs: { type: "success" } }, [
                            _vm._v(" 已开票 "),
                          ])
                        : _vm._e(),
                      _vm.detailData.status == "REJECT"
                        ? _c("el-tag", { attrs: { type: "danger" } }, [
                            _vm._v(" 已驳回 "),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [_vm._v("审核信息")]),
                      _vm._v(" " + _vm._s(_vm.detailData.rejectReason) + " "),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [_vm._v("发票类型")]),
                      _vm.detailData.type == "ORDINARY"
                        ? _c("span", [_vm._v("普通发票")])
                        : _vm._e(),
                      _vm.detailData.type == "VAT"
                        ? _c("span", [_vm._v("增值税专用发票")])
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [_vm._v("抬头类型")]),
                      _vm.detailData.titleType == "PERSONAL"
                        ? _c("span", [_vm._v("个人")])
                        : _vm._e(),
                      _vm.detailData.titleType == "ENTERPRISE"
                        ? _c("span", [_vm._v("企业")])
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [_vm._v("发票抬头")]),
                      _vm._v(" " + _vm._s(_vm.detailData.title) + " "),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v("纳税人识别号"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.detailData.taxId || "--") + " "),
                    ],
                    2
                  ),
                  _vm.detailData.titleType == "ENTERPRISE"
                    ? [
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v("注册地址"),
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.detailData.address || "--") + " "
                            ),
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v("注册电话"),
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.detailData.phone || "--") + " "
                            ),
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v("开户银行"),
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.detailData.bank || "--") + " "
                            ),
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v("银行账号"),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.detailData.bankAccount || "--") +
                                " "
                            ),
                          ],
                          2
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [_vm._v("费用类型")]),
                      _vm.detailData.costType == "RECHARGE"
                        ? _c("span", [_vm._v("充值")])
                        : _vm._e(),
                      _vm.detailData.costType == "SERVICE_FEE"
                        ? _c("span", [_vm._v("账单")])
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [_vm._v("发票金额")]),
                      _vm._v(" " + _vm._s(_vm.detailData.amount) + " "),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [_vm._v("发票附件")]),
                      _vm.detailData.fileUrl
                        ? _c(
                            "span",
                            {
                              staticStyle: { color: "blue", cursor: "pointer" },
                              on: { click: _vm.previewImg },
                            },
                            [_vm._v(" 下载 ")]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [_vm._v("电子邮箱")]),
                      _vm._v(" " + _vm._s(_vm.detailData.email) + " "),
                    ],
                    2
                  ),
                  _vm.detailData.type == "VAT"
                    ? [
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v("收件人姓名"),
                            ]),
                            _vm._v(" " + _vm._s(_vm.detailData.email) + " "),
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v("收件人电话"),
                            ]),
                            _vm._v(" " + _vm._s(_vm.detailData.email) + " "),
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v("收件人地址"),
                            ]),
                            _vm._v(" " + _vm._s(_vm.detailData.email) + " "),
                          ],
                          2
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [_vm._v("申请时间")]),
                      _vm._v(" " + _vm._s(_vm.detailData.createTime) + " "),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [_vm._v("备注")]),
                      _vm._v(" " + _vm._s(_vm.detailData.remark || "--") + " "),
                    ],
                    2
                  ),
                ],
                2
              ),
              _c("el-descriptions", {
                staticStyle: { "margin-top": "10px" },
                attrs: { border: "", column: 1, title: "关联费用清单" },
              }),
              _vm.detailData.costType == "RECHARGE"
                ? _c(
                    "el-table",
                    {
                      attrs: {
                        border: "",
                        data: _vm.detailData.walletRechargeOrderList,
                        stripe: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "empty",
                            fn: function () {
                              return [
                                _c("el-image", {
                                  staticClass: "vab-data-empty",
                                  attrs: {
                                    src: require("@/assets/empty_images/data_empty.png"),
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        4212993939
                      ),
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "流水编号",
                          prop: "orderNo",
                        },
                      }),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            align: "center",
                            label: "类型",
                            prop: "status",
                          },
                        },
                        [[_vm._v(" 充值 ")]],
                        2
                      ),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "金额(元)",
                          prop: "totalAmount",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "创建时间",
                          prop: "createTime",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.detailData.costType == "SERVICE_FEE"
                ? _c(
                    "el-table",
                    {
                      attrs: {
                        border: "",
                        data: _vm.detailData.settlementList,
                        stripe: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "empty",
                            fn: function () {
                              return [
                                _c("el-image", {
                                  staticClass: "vab-data-empty",
                                  attrs: {
                                    src: require("@/assets/empty_images/data_empty.png"),
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        4212993939
                      ),
                    },
                    [
                      _c("el-table-column", {
                        attrs: { align: "center", label: "账单号", prop: "id" },
                      }),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            align: "center",
                            label: "账单类型",
                            prop: "costType",
                          },
                        },
                        [[_vm._v("账单")]],
                        2
                      ),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "总服务费(元)",
                          prop: "totalServiceFee",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "结算时间",
                          prop: "realPayTime",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c(
            "el-card",
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    "label-width": "",
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-descriptions",
                    {
                      staticClass: "margin-top",
                      attrs: { border: "", column: 1, title: "发票信息" },
                    },
                    [
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v("发票状态"),
                          ]),
                          _vm.detailData.status == "INVOICING"
                            ? _c("el-tag", { attrs: { type: "pamary" } }, [
                                _vm._v(" 开票中 "),
                              ])
                            : _vm._e(),
                          _vm.detailData.status == "COMPLETE"
                            ? _c("el-tag", { attrs: { type: "success" } }, [
                                _vm._v(" 已开票 "),
                              ])
                            : _vm._e(),
                          _vm.detailData.status == "REJECT"
                            ? _c("el-tag", { attrs: { type: "danger" } }, [
                                _vm._v(" 已驳回 "),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v("审核信息"),
                          ]),
                          _vm._v(
                            " " + _vm._s(_vm.detailData.rejectReason) + " "
                          ),
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v("发票类型"),
                          ]),
                          _c(
                            "el-form-item",
                            { attrs: { label: "", prop: "type" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择发票类型" },
                                  model: {
                                    value: _vm.ruleForm.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "type", $$v)
                                    },
                                    expression: "ruleForm.type",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: "普通发票",
                                      value: "ORDINARY",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: "增值税专用发票",
                                      value: "VAT",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v("抬头类型"),
                          ]),
                          _vm.detailData.titleType == "PERSONAL"
                            ? _c("span", [_vm._v("个人")])
                            : _vm._e(),
                          _vm.detailData.titleType == "ENTERPRISE"
                            ? _c("span", [_vm._v("企业")])
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v("发票金额"),
                          ]),
                          _vm._v(" " + _vm._s(_vm.detailData.amount) + "元 "),
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v("发票附件"),
                          ]),
                          _c("JMUpload", {
                            attrs: {
                              "file-type": "text",
                              multiple: false,
                              number: 1,
                            },
                            on: { change: _vm.handleChange },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v("发票抬头"),
                          ]),
                          _c(
                            "el-form-item",
                            { attrs: { label: "", prop: "title" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入发票抬头" },
                                model: {
                                  value: _vm.ruleForm.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "title", $$v)
                                  },
                                  expression: "ruleForm.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v("纳税人识别号"),
                          ]),
                          _vm._v(
                            " " + _vm._s(_vm.detailData.taxId || "") + " "
                          ),
                        ],
                        2
                      ),
                      _vm.detailData.titleType == "ENTERPRISE"
                        ? [
                            _c(
                              "el-descriptions-item",
                              [
                                _c("template", { slot: "label" }, [
                                  _vm._v("注册地址"),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.detailData.address || "--") +
                                    " "
                                ),
                              ],
                              2
                            ),
                            _c(
                              "el-descriptions-item",
                              [
                                _c("template", { slot: "label" }, [
                                  _vm._v("注册电话"),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.detailData.phone || "--") +
                                    " "
                                ),
                              ],
                              2
                            ),
                            _c(
                              "el-descriptions-item",
                              [
                                _c("template", { slot: "label" }, [
                                  _vm._v("开户银行"),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.detailData.bank || "--") +
                                    " "
                                ),
                              ],
                              2
                            ),
                            _c(
                              "el-descriptions-item",
                              [
                                _c("template", { slot: "label" }, [
                                  _vm._v("银行账号"),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.detailData.bankAccount || "--") +
                                    " "
                                ),
                              ],
                              2
                            ),
                          ]
                        : _vm._e(),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v("费用类型"),
                          ]),
                          _vm.detailData.costType == "RECHARGE"
                            ? _c("span", [_vm._v("充值")])
                            : _vm._e(),
                          _vm.detailData.costType == "SERVICE_FEE"
                            ? _c("span", [_vm._v("账单")])
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v("电子邮箱"),
                          ]),
                          _vm._v(" " + _vm._s(_vm.detailData.email) + " "),
                        ],
                        2
                      ),
                      _vm.detailData.type == "VAT"
                        ? [
                            _c(
                              "el-descriptions-item",
                              [
                                _c("template", { slot: "label" }, [
                                  _vm._v("收件人姓名"),
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.detailData.email) + " "
                                ),
                              ],
                              2
                            ),
                            _c(
                              "el-descriptions-item",
                              [
                                _c("template", { slot: "label" }, [
                                  _vm._v("收件人电话"),
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.detailData.email) + " "
                                ),
                              ],
                              2
                            ),
                            _c(
                              "el-descriptions-item",
                              [
                                _c("template", { slot: "label" }, [
                                  _vm._v("收件人地址"),
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.detailData.email) + " "
                                ),
                              ],
                              2
                            ),
                          ]
                        : _vm._e(),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v("申请时间"),
                          ]),
                          _vm._v(" " + _vm._s(_vm.detailData.createTime) + " "),
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [_vm._v("备注")]),
                          _c(
                            "el-form-item",
                            { attrs: { label: "" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入备注" },
                                model: {
                                  value: _vm.ruleForm.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "remark", $$v)
                                  },
                                  expression: "ruleForm.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("el-descriptions", {
                staticStyle: { "margin-top": "10px" },
                attrs: { border: "", column: 1, title: "关联费用清单" },
              }),
              _vm.detailData.costType == "RECHARGE"
                ? _c(
                    "el-table",
                    {
                      attrs: {
                        border: "",
                        data: _vm.detailData.walletRechargeOrderList,
                        stripe: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "empty",
                            fn: function () {
                              return [
                                _c("el-image", {
                                  staticClass: "vab-data-empty",
                                  attrs: {
                                    src: require("@/assets/empty_images/data_empty.png"),
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        4212993939
                      ),
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "流水编号",
                          prop: "orderNo",
                        },
                      }),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            align: "center",
                            label: "类型",
                            prop: "status",
                          },
                        },
                        [[_vm._v(" 充值 ")]],
                        2
                      ),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "金额(元)",
                          prop: "totalAmount",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "创建时间",
                          prop: "createTime",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.detailData.costType == "SERVICE_FEE"
                ? _c(
                    "el-table",
                    {
                      attrs: {
                        border: "",
                        data: _vm.detailData.settlementList,
                        stripe: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "empty",
                            fn: function () {
                              return [
                                _c("el-image", {
                                  staticClass: "vab-data-empty",
                                  attrs: {
                                    src: require("@/assets/empty_images/data_empty.png"),
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        4212993939
                      ),
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "结算单号",
                          prop: "id",
                        },
                      }),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            align: "center",
                            label: "账单类型",
                            prop: "costType",
                          },
                        },
                        [[_vm._v("账单服务费")]],
                        2
                      ),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "总服务费(元)",
                          prop: "withdrawServiceAmount",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "结算时间",
                          prop: "realPayTime",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
      _vm.turnDownVisible
        ? _c("TurnDownReasonVue", {
            attrs: { "turn-down-visible": _vm.turnDownVisible },
            on: {
              "update:turnDownVisible": function ($event) {
                _vm.turnDownVisible = $event
              },
              "update:turn-down-visible": function ($event) {
                _vm.turnDownVisible = $event
              },
              trunDownConfirm: _vm.trunDownConfirm,
            },
          })
        : _vm._e(),
      _c(
        "div",
        [
          _vm.detailData.status == "INVOICING"
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.invoicing } },
                [_vm._v(" 开票 ")]
              )
            : _vm._e(),
          _vm.detailData.status == "INVOICING"
            ? _c("el-button", { on: { click: _vm.turnDown } }, [
                _vm._v(" 驳回 "),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }