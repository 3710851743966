<template>
  <el-dialog
    append-to-body
    class="custom-dialog"
    title="分佣配置"
    :visible.sync="dialogFormVisible"
    width="540px"
    @close="close"
  >
    <div style="margin-bottom: 20px; font-size: 14px">
      渠道商：{{ info.name }} {{ info.phone }}
    </div>
    <el-radio-group v-model="tabLevel">
      <el-radio-button label="first">所属渠道</el-radio-button>
      <el-radio-button label="second">上级渠道</el-radio-button>
    </el-radio-group>
    <div style="position: relative; height: 240px">
      <transition name="fade">
        <el-table
          v-show="tabLevel === 'first'"
          v-loading="loading"
          border
          :cell-style="{ padding: '5px 8px' }"
          class="table"
          :data="secondList"
          stripe
        >
          <el-table-column
            align="center"
            label="分佣项"
            min-width="130"
            prop="name"
          />
          <el-table-column
            align="center"
            label="默认配置"
            min-width="130"
            prop="default"
          >
            <template #default="{ row }">
              {{ row.default }}{{ row.unit }}
            </template>
          </el-table-column>
          <!--<el-table-column
            align="center"
            label="分佣费率"
            min-width="130"
            prop="default"
          >
            <template #default="{ row }">
              {{ row.default }}{{ row.unit }}
            </template>
          </el-table-column>-->
          <el-table-column align="center" label="分佣费率" min-width="140px">
            <template #default="{ row }">
              <el-input
                v-model="row.value"
                :readonly="btnLoading"
                size="mini"
                style="width: 60px"
              />
              <span
                style="
                  display: inline-block;
                  width: 50px;
                  margin-left: 8px;
                  text-align: left;
                "
              >
                {{ row.unit }}
              </span>
            </template>
          </el-table-column>
          <template #empty>
            <el-image
              class="vab-data-empty"
              :src="require('@/assets/empty_images/data_empty.png')"
            />
          </template>
        </el-table>
      </transition>
      <transition name="fade">
        <el-table
          v-show="tabLevel === 'second'"
          v-loading="loading"
          border
          :cell-style="{ padding: '5px 8px' }"
          class="table"
          :data="firstList"
          stripe
        >
          <el-table-column
            align="center"
            label="分佣项"
            min-width="130"
            prop="name"
          />
          <el-table-column
            align="center"
            label="默认配置"
            min-width="130"
            prop="default"
          >
            <template #default="{ row }">
              {{ row.default }}{{ row.unit }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="分佣费率"
            min-width="130"
            prop="default"
          >
            <template #default="{ row }">
              {{ row.default }}{{ row.unit }}
            </template>
          </el-table-column>
          <!--<el-table-column align="center" label="分佣费率" min-width="140px">
            <template #default="{ row }">
              <el-input
                v-model="row.value"
                :readonly="btnLoading"
                size="mini"
                style="width: 60px"
              />
              <span
                style="
                  margin-left: 8px;
                  width: 50px;
                  text-align: left;
                  display: inline-block;
                "
              >
                {{ row.unit }}
              </span>
            </template>
          </el-table-column>-->
          <template #empty>
            <el-image
              class="vab-data-empty"
              :src="require('@/assets/empty_images/data_empty.png')"
            />
          </template>
        </el-table>
      </transition>
    </div>

    <template #footer>
      <div v-show="tabLevel === 'first'">
        <el-button @click="close">取 消</el-button>
        <el-button :loading="btnLoading" type="primary" @click="onSubmit">
          保存
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
  import {
    getChannelCommissionConfigDetail,
    getChannelCommissionDefaultConfig,
    updateChannelCommissionConfig,
  } from '@/api/distributor/setting'

  export default {
    name: 'CommissionSetting',
    props: {
      id: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        tabLevel: 'first',
        dialogFormVisible: false,
        firstList: [
          {
            key: 'houseManagementFee',
            name: '房屋管理费',
            default: 0,
            value: 0,
            unit: '元/间/月',
            edit: false,
            loading: false,
          },
          {
            key: 'withdrawalServiceFee',
            name: '业主提现手续费',
            default: 0,
            value: 0,
            unit: '元/笔',
            edit: false,
            loading: false,
          },
          {
            key: 'waterServiceFee',
            name: '水表信息服务费',
            default: 0,
            value: 0,
            unit: '元/间/月',
            edit: false,
            loading: false,
          },
          {
            key: 'electricServiceFee',
            name: '电表信息服务费',
            default: 0,
            value: 0,
            unit: '元/间/月',
            edit: false,
            loading: false,
          },
        ],
        secondList: [
          {
            key: 'secondaryHouseManagementFee',
            name: '房屋管理费',
            default: 0,
            value: 0,
            unit: '元/间/月',
            edit: false,
            loading: false,
          },
          {
            key: 'secondaryWithdrawalServiceFee',
            name: '业主提现手续费',
            default: 0,
            value: 0,
            unit: '元/笔',
            edit: false,
            loading: false,
          },
          {
            key: 'secondaryWaterServiceFee',
            name: '水表信息服务费',
            default: 0,
            value: 0,
            unit: '元/间/月',
            edit: false,
            loading: false,
          },
          {
            key: 'secondaryElectricServiceFee',
            name: '电表信息服务费',
            default: 0,
            value: 0,
            unit: '元/间/月',
            edit: false,
            loading: false,
          },
        ],
        loading: false,
        btnLoading: false,
        info: {},
      }
    },
    created() {},
    methods: {
      async showEdit(row) {
        this.info = row
        this.tabLevel = 'first'
        this.btnLoading = false
        this.dialogFormVisible = true
        this.fetchData()
      },
      async fetchData() {
        this.loading = true
        let defaultRes = await getChannelCommissionDefaultConfig()

        if (defaultRes.code === 0 && defaultRes.data) {
          this.firstList.forEach((o) => {
            o.default = defaultRes.data[o.key]
          })
          this.secondList.forEach((o) => {
            o.default = defaultRes.data[o.key]
          })
        }
        let res = await getChannelCommissionConfigDetail({
          distributorId: this.id,
        })
        if (res.code === 0 && res.data) {
          this.firstList.forEach((o) => {
            o.value = res.data[o.key]
          })
          this.secondList.forEach((o) => {
            o.value = res.data[o.key]
          })
        }

        this.loading = false
      },
      close() {
        this.dialogFormVisible = false
      },
      async onSubmit() {
        this.btnLoading = true
        let data = {
          distributorId: this.id,
        }
        this.firstList.forEach((o) => {
          data[o.key] = o.value
        })
        this.secondList.forEach((o) => {
          data[o.key] = o.value
        })
        let res = await updateChannelCommissionConfig(data).catch(() => {
          this.btnLoading = false
        })
        if (res.code === 0) {
          this.$baseMessage('保存成功', 'success', 'vab-hey-message-success')
          this.fetchData()
        }
        this.btnLoading = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  :deep(.el-input__inner) {
    text-align: center !important;
  }
  .table {
    position: absolute;
    top: 20px;
    left: 0;
    max-width: 540px;
  }
</style>
<style lang="scss">
  @import '../../../dialog.scss';

  .custom-dialog {
    @include setDialog();
    .fade-enter-active {
      transition: all 0.3s;
    }
    .fade-leave-active {
      transition: all 0.3s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }
  }
</style>
