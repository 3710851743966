<template>
  <div class="comprehensive-table-container">
    <el-page-header :content="'【' + shopName + '】'" @back="goBack" />
    <el-row type="flex">
      <el-col class="fix-width-200">
        <el-tabs
          v-model="building.id"
          tab-position="left"
          @tab-click="handleChangeBuilding"
        >
          <el-tab-pane
            v-for="item in data"
            :key="'building' + item.id"
            :label="item.name"
            :name="item.id"
          />
        </el-tabs>
      </el-col>
      <el-col>
        <div class="title">
          {{ `${shopName}${building.name ? ' - ' + building.name : ''}` }}
        </div>
        <el-tabs v-model="activeName" @tab-click="handleChangeBuilding">
          <el-tab-pane label="电表" name="electric">
            <Electic
              ref="electric"
              :building-id="building.id"
              :floor-num="building.floorNum"
              :shop-id="shopId"
            />
          </el-tab-pane>
          <el-tab-pane label="水表" name="water">
            <Water
              ref="water"
              :building-id="building.id"
              :floor-num="building.floorNum"
              :shop-id="shopId"
            />
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import { handleActivePath } from '@/utils/routes'
  import { ListBuilding } from '@/api/device/merchant'
  import Electic from './components/Electic.vue'
  import Water from './components/Water.vue'

  export default {
    name: 'DeviceMerchantManage',
    components: {
      Electic,
      Water,
    },
    data() {
      return {
        activeName: 'electric',
        shopId: '',
        loading: false,
        data: [],
        shopName: '加载中...',
        building: {
          id: '',
          name: '',
          floorNum: 0,
        },
      }
    },
    created() {
      const route = this.$route
      this.shopId = route.query.id
      this.fetchData()
      this.$nextTick(() => {
        this.changeTabsMeta({
          title: '商户水电表管理',
          meta: {
            title: `${route.query.shopName} - 水电表管理`,
          },
        })
        console.log(route.query.shopName, this.$route.meta.title)
        this.shopName = route.query.shopName
      })
    },
    methods: {
      ...mapActions({
        changeTabsMeta: 'tabs/changeTabsMeta',
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      async goBack() {
        const detailPath = await handleActivePath(this.$route, true)
        await this.$router.push('/merchant/device')
        await this.delVisitedRoute(detailPath)
      },
      async fetchData() {
        if (this.loading) return
        try {
          this.loading = true
          const { data } = await ListBuilding({
            shopId: this.shopId,
          })
          this.data = data
          if (data) {
            const { id, name, floorNum } = data[0]
            this.building = { id, name, floorNum }
            console.log(this.building)
            this.$refs[this.activeName].fetchData(id)
          }
        } finally {
          this.loading = false
        }
      },
      handleChangeBuilding() {
        const { id, name, floorNum } = this.data.find(
          (item) => item.id == this.building.id
        )
        this.building = { id, name, floorNum }
        console.log(this.building)
        this.$refs[this.activeName].fetchData(id)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .fix-width-200 {
    width: 240px;
  }
  .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 2.4em;
  }
  :deep(.el-tabs--left) {
    .el-tabs__header {
      min-width: 160px;
    }
    .el-tabs__item {
      text-align: center;
      &.is-active {
        background-color: rgba(24, 144, 255, 0.1);
      }
    }
    .el-tabs__content {
      display: none;
    }
  }
</style>
