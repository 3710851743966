<template>
  <div>
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="80px"
          :model="queryForm"
          @submit.native.prevent
        >
          <el-form-item label="房源状态">
            <el-select
              v-model="queryForm.houseStatus"
              clearable
              placeholder="房源状态"
            >
              <el-option
                v-for="item in HouseSourceStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="房源编号">
            <el-input
              v-model="queryForm.houseName"
              clearable
              placeholder="房源编号"
            />
          </el-form-item>
          <el-form-item label="设备绑定">
            <el-select
              v-model="queryForm.deviceBind"
              clearable
              placeholder="设备绑定"
            >
              <el-option
                v-for="item in DeviceBindStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="设备状态">
            <el-select
              v-model="queryForm.deviceStatus"
              clearable
              placeholder="设备状态"
            >
              <el-option
                v-for="item in WaterStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="设备编号">
            <el-input
              v-model="queryForm.deviceNo"
              clearable
              placeholder="设备编号"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetForm('form')">
              重置
            </el-button>
            <!-- <el-button type="text" @click="handleFold">
              <span v-if="fold">展开</span>
              <span v-else>合并</span>
              <vab-icon
                class="vab-dropdown"
                :class="{ 'vab-dropdown-active': fold }"
                icon="arrow-up-s-line"
              />
            </el-button> -->
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>
    <el-tabs v-model="floor" @tab-click="fetchData()">
      <el-tab-pane label="全部" name="all" />
      <template v-if="data?.length">
        <el-tab-pane
          v-for="item in floorNum"
          :key="'water' + item"
          :label="item + '层'"
          :name="String(item)"
        />
      </template>
    </el-tabs>
    <el-image
      v-if="!loading && !data?.length"
      class="vab-data-empty"
      :src="require('@/assets/empty_images/data_empty.png')"
    />
    <el-row
      v-else
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
      element-loading-text="拼命加载中"
      :gutter="20"
    >
      <el-col
        v-for="item in data"
        :key="item.id"
        :lg="6"
        :md="8"
        :sm="12"
        :xl="12"
        :xs="12"
         style="padding-bottom: 5px;"
      >
        <el-card style="height: 234px">
          <div slot="header" class="clearfix">
            <el-row align="middle" justify="space-between" type="flex">
              <el-col :span="12">
                <span>{{ item.name }}房</span>
              </el-col>
              <el-col :span="12" style="text-align: right">
                <el-tag v-if="item.status == '3'" effect="dark">
                  {{ returnLabel(item.status) }}
                </el-tag>
                <el-tag v-if="item.status == '1'" effect="dark" type="success">
                  {{ returnLabel(item.status) }}
                </el-tag>
                <el-tag v-if="item.status == '2'" effect="dark" type="danger">
                  {{ returnLabel(item.status) }}
                </el-tag>
              </el-col>
            </el-row>
          </div>
          <div class="list-item">
            <el-row>
              <el-col>设备编号：{{ item.deviceNo || '--' }}</el-col>
            </el-row>
            <el-row align="middle" justify="space-between" type="flex">
              <el-col>设备绑定状态：{{ returnLabel2(item.bindStatus) }}</el-col>
              <el-col style="text-align: right">
                <vab-icon
                  v-if="item.deviceStatus === 2"
                  icon="rss-fill"
                  style="color: #1890ff"
                />
                <vab-icon
                  v-if="item.deviceStatus === 0"
                  icon="rss-fill"
                  style="color: #95de64"
                />
                <vab-icon
                  v-if="item.deviceStatus === 1"
                  icon="rss-fill"
                  style="color: #cc0000"
                />
                {{ item.signalLvl }}
              </el-col>
            </el-row>
            <el-row>
              <el-col>上期读数：{{ item.meterVal || '--' }}</el-col>
            </el-row>
            <el-row>
              <el-col>
                最后抄表时间：{{
                  item.meterDate
                    ? dayjs(item.meterDate).format('YYYY-MM-DD HH:mm:ss')
                    : '--'
                }}
              </el-col>
            </el-row>
          </div>
          <el-button
            size="large"
            type="text"
            @click="handleViewMeterLog(item.id)"
          >
            抄表记录
          </el-button>
          <el-button
            size="large"
            type="text"
            @click="handleViewDeviceBindLog(item.id)"
          >
            设备详情
          </el-button>
        </el-card>
      </el-col>
    </el-row>
    <MeterLog ref="meterLog" />
    <BindLog ref="bindLog" />
  </div>
</template>

<script>
  import dayjs from 'dayjs'
  import {
    WaterStatus,
    DeviceBindStatus,
    HouseSourceStatus,
  } from '@/utils/constant'
  import { HouseDeviceList } from '@/api/device/merchant'
  import MeterLog from './meterLog.vue'
  import BindLog from './bindLog.vue'
  export default {
    components: { MeterLog, BindLog },
    props: {
      shopId: {
        type: String,
        default: '',
      },
      buildingId: {
        type: String,
        default: '',
      },
      floorNum: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        WaterStatus,
        DeviceBindStatus,
        HouseSourceStatus,
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
        },
        queryForm: {
          houseStatus: '',
          houseName: '',
          deviceBind: '',
          deviceStatus: '',
          deviceNo: '',
        },
        floor: 'all',
        data: [],
        loading: false,
      }
    },
    methods: {
      dayjs,
      async fetchData(id) {
        if (this.loading) return
        try {
          this.loading = true
          const { houseStatus, houseName, deviceBind, deviceStatus, deviceNo } =
            this.queryForm
          const {
            data: { houseList },
          } = await HouseDeviceList({
            // ...this.listQuery,
            houseStatus: houseStatus === 'all' ? '' : houseStatus,
            houseName,
            bindStatus: deviceBind === 'all' ? '' : deviceBind,
            deviceStatus: deviceStatus === 'all' ? '' : deviceStatus,
            deviceNo,
            floor: this.floor === 'all' ? '' : this.floor,
            shopId: this.shopId,
            buildingId: id || this.buildingId,
            typeNumber: 'SMART_WATER_METER',
          })
          this.data = houseList
          // this.totalCount = totalRow
        } finally {
          this.loading = false
        }
      },
      handleQuery() {
        // this.listQuery.pageNumber = 1
        this.floor = 'all'
        this.fetchData()
      },
      resetForm(formName) {
        this.$refs[formName].resetFields()
        this.queryForm = this.$options.data().queryForm
        this.fetchData()
      },
      returnLabel(status) {
        return (
          this.HouseSourceStatus.find((item) => item.value == status)?.label ||
          '--'
        )
      },
      returnLabel2(status) {
        return (
          this.DeviceBindStatus.find((item) => item.value == status)?.label ||
          '--'
        )
      },
      handleViewMeterLog(houseId) {
        this.$refs['meterLog'].showView(houseId, 'water')
      },
      handleViewDeviceBindLog(houseId) {
        this.$refs['bindLog'].showView(houseId, 'water')
      },
    },
  }
</script>

<style scoped lang="scss">
  .list-item {
    line-height: 1.8em;
  }
</style>
