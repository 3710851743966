<!-- 渠道商详情 -->
<template>
  <div class="detail-container">
    <el-page-header content="渠道商详情" @back="goBack" />
    <el-button
      :disabled="loading"
      icon="el-icon-edit"
      type="primary"
      @click="showEdit"
    >
      修改信息
    </el-button>
    <el-descriptions
      border
      class="margin-top"
      :column="2"
      :content-style="{ 'work-break': 'break-all' }"
      :label-style="{ width: '160px', 'word-break': 'keep-all' }"
      size="medium"
    >
      <el-descriptions-item label="渠道商名称">
        <span
          style="margin-right: 10px; line-height: 20px; display: inline-block"
        >
          {{ info.name || '--' }}
        </span>
        <el-tooltip
          :content="info.status == 0 ? '点击开启' : '点击禁用'"
          :enterable="false"
          placement="top"
        >
          <el-switch
            v-model="info.status"
            :active-value="1"
            :disabled="statusChangeLoading"
            :inactive-value="0"
            @change="onSwitchChange(row)"
          />
        </el-tooltip>
      </el-descriptions-item>
      <el-descriptions-item label="上级">
        <el-link
          v-if="info.superiorDistributorName"
          style="margin-right: 30px; cursor: default"
          type="primary"
        >
          {{ info.superiorDistributorName }}
        </el-link>
        <el-button v-else size="mini" type="primary" @click="onChangeSuperior">
          设置上级
        </el-button>
        <template v-if="info.superiorDistributorName">
          <el-button size="mini" type="primary" @click="onChangeSuperior">
            修改上级
          </el-button>
          <el-button size="mini" type="danger" @click="removeSuperior">
            移除
          </el-button>
        </template>
      </el-descriptions-item>
      <el-descriptions-item label="申请时间">
        {{ info.applyTime || '--' }}
      </el-descriptions-item>
      <el-descriptions-item label="申请通过时间">
        {{ info.applySuccessTime || '--' }}
      </el-descriptions-item>

      <el-descriptions-item label="手机号">
        {{ info.phone }}
      </el-descriptions-item>
      <el-descriptions-item label="分佣配置">
        <el-button size="mini" type="primary" @click="showSetting">
          查看/修改
        </el-button>
      </el-descriptions-item>

      <el-descriptions-item label="联系地址" :span="2">
        {{ info.province || '' }}{{ info.city || '' }}{{ info.district || ''
        }}{{ info.address || '' }}
      </el-descriptions-item>
      <el-descriptions-item label="银行名称">
        {{ info.bankName || '--' }}
      </el-descriptions-item>
      <el-descriptions-item label="结算账户名">
        {{ info.settleAccount || '--' }}
      </el-descriptions-item>
      <el-descriptions-item label="结算账户">
        {{ info.settleAccountNo || '--' }}
      </el-descriptions-item>

      <el-descriptions-item
        v-if="info.openBankProvince"
        label="地址/支行"
        :span="2"
      >
        {{ info.openBankProvince || '' }}{{ info.openBankCity || ''
        }}{{ info.openBank || '' }}
      </el-descriptions-item>
      <el-descriptions-item v-else label="地址/支行" :span="2">
        --
      </el-descriptions-item>
    </el-descriptions>

    <el-descriptions
      border
      class="margin-top"
      :column="2"
      :content-style="{ 'work-break': 'break-all' }"
      :label-style="{ width: '160px', 'word-break': 'keep-all' }"
      size="medium"
    >
      <el-descriptions-item label="推广商户数">
        {{ info.shopCount }}
      </el-descriptions-item>
      <el-descriptions-item label="佣金收入(元)">
        {{ (info.commissionAmount || 0).toFixed(2) }}
      </el-descriptions-item>
      <el-descriptions-item label="下级数">
        {{ info.lowerDistributorCount }}
      </el-descriptions-item>
      <el-descriptions-item />
    </el-descriptions>

    <el-tabs v-model="tabs" style="margin-top: 30px" type="border-card">
      <el-tab-pane label="推广商户" name="1">
        <transition name="fade">
          <ShopTable v-show="tabs === '1'" :id="id" />
        </transition>
      </el-tab-pane>
      <el-tab-pane label="下级列表" name="2">
        <transition name="fade">
          <SecondarTable v-show="tabs === '2'" :id="id" @fetch-data="setInfo" />
        </transition>
      </el-tab-pane>
      <el-tab-pane label="收入记录" name="3">
        <transition name="fade">
          <CommissionTable v-show="tabs === '3'" :id="id" />
        </transition>
      </el-tab-pane>
      <el-tab-pane label="结算记录" name="4">
        <transition name="fade">
          <BillTable v-show="tabs === '4'" :id="id" />
        </transition>
      </el-tab-pane>
    </el-tabs>
    <!-- 更换上级 -->
    <distributor-select ref="distributorSelect" @fetch-data="setInfo" />
    <commission-setting :id="id" ref="commissionSetting" />
    <!-- 编辑渠道商 -->
    <distributor-edit ref="distributorEdit" @fetch-data="setInfo" />
  </div>
</template>

<script>
  import {
    getDistributorDetail,
    updateDistributorStatus,
    updateDistributorSuperior,
  } from '@/api/distributor/index'
  import { mapActions } from 'vuex'
  import { handleActivePath } from '@/utils/routes'

  import BillTable from './components/BillTable.vue'
  import CommissionTable from './components/CommissionTable.vue'
  import SecondarTable from './components/SecondarTable.vue'
  import ShopTable from './components/ShopTable.vue'
  import DistributorSelect from './components/DistributorSelect.vue'
  import CommissionSetting from './components/CommissionSetting.vue'
  import DistributorEdit from './components/DistributorEdit.vue'

  export default {
    name: 'DistributorManageInfo',
    components: {
      BillTable,
      CommissionTable,
      SecondarTable,
      ShopTable,
      DistributorSelect,
      CommissionSetting,
      DistributorEdit,
    },
    data() {
      return {
        id: null,

        loading: false,
        statusChangeLoading: false,
        route: { query: { title: '加载中' } },
        form: {
          text: '',
        },
        info: {
          status: 0,
        },
        tabs: '1',
      }
    },
    created() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
      const route = this.$route
      this.id = this.$route.query.id
      this.setInfo()
      this.$nextTick(() => {
        this.changeTabsMeta({
          title: '详情页',
          meta: {
            title: '渠道商详情',
          },
        })
        this.route = {
          path: route.path,
          params: route.params,
          query: route.query,
          name: route.name,
          meta: route.meta,
        }
      })
    },
    methods: {
      ...mapActions({
        changeTabsMeta: 'tabs/changeTabsMeta',
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      async setInfo() {
        this.loading = true
        const res = await getDistributorDetail(this.id)
        if (res.code === 0) {
          this.info = res.data
          this.loading = false
        }
      },
      async goBack() {
        const detailPath = await handleActivePath(this.$route, true)
        await this.$router.back()
        await this.delVisitedRoute(detailPath)
      },
      handleRefreshMainPage() {
        this.$baseEventBus.$emit('reload-router-view', 'ComprehensiveTable')
      },
      async onSwitchChange() {
        this.statusChangeLoading = true
        let res = await updateDistributorStatus(this.info.id)
        this.statusChangeLoading = false
        if (res.code !== 0) {
          this.info.status = this.info.status === 0 ? 1 : 0
        }
      },
      onEdit() {},
      onChangeSuperior() {
        this.$refs['distributorSelect'].showEdit(this.info)
      },
      removeSuperior() {
        this.$baseConfirm('是否确认删除？', null, async () => {
          const res = await updateDistributorSuperior({
            id: this.id,
          })
          if (res.code === 0) {
            this.$baseMessage('更改成功', 'success', 'vab-hey-message-success')
            await this.setInfo()
          }
        })
      },
      showSetting() {
        this.$refs['commissionSetting'].showEdit(this.info)
      },
      showEdit() {
        this.$refs['distributorEdit'].showEdit(this.info)
      },
    },
  }
</script>
<style scoped>
  .margin-top {
    margin-top: 20px;
  }
  :deep(.el-tabs--border-card) {
    box-shadow: none;
    border-color: #e4e7ed;
  }
</style>
<style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
