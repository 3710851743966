<template>
  <el-dialog
    append-to-body
    class="custom-dialog"
    title="佣金关联账单"
    :visible.sync="dialogFormVisible"
    width="720px"
    @close="close"
  >
    <el-table
      v-loading="loading"
      border
      :cell-style="{ padding: '5px 8px' }"
      :data="tableData"
      stripe
    >
      <el-table-column
        align="center"
        label="来源订单"
        min-width="140"
        prop="billId"
      >
        <template #default="{ row }">
          {{ row.billId || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="来源商户"
        min-width="160"
        prop="shopName"
      >
        <template #default="{ row }">
          {{ row.shopName || '--' }} {{ row.shopPhone }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="订单金额(元)"
        min-width="90"
        prop="billAmount"
      >
        <template #default="{ row }">
          {{ (row.billAmount || 0).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="佣金(元)"
        min-width="90"
        prop="commissionAmount"
      >
        <template #default="{ row }">
          {{ (row.commissionAmount || 0).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="分佣项"
        min-width="100"
        prop="orderType"
      >
        <template #default="{ row }">
          {{ orderTypeFilterName(row.orderType) || '--' }}
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
  </el-dialog>
</template>

<script>
  import { getChannelCommissionDetail } from '@/api/distributor/finance'

  export default {
    name: 'CommissionAssBillInfo',
    data() {
      return {
        dialogFormVisible: false,
        tableData: [],
        loading: false,
      }
    },
    created() {},
    methods: {
      orderTypeFilterName(type) {
        const orderTypeNameMap = {
          1: '房租账单',
        }
        return orderTypeNameMap[type]
      },
      async showEdit(row) {
        this.tableData = []
        this.dialogFormVisible = true
        this.loading = true
        const res = await getChannelCommissionDetail(row.id)
        if (res.code === 0) {
          this.tableData = [res.data]
          console.log('this.tableData', this.tableData)
        }
        this.loading = false
      },
      close() {
        this.dialogFormVisible = false
      },
    },
  }
</script>
<style lang="scss">
  @import '../../../dialog.scss';

  .custom-dialog {
    @include setDialog();
  }
</style>
