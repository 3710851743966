var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "custom-dialog",
      attrs: {
        "append-to-body": "",
        stripe: "",
        title: "账单详情",
        visible: _vm.dialogFormVisible,
        width: "720px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-descriptions",
        { attrs: { column: 2, size: "medium" } },
        [
          _c("el-descriptions-item", { attrs: { label: "名字" } }, [
            _vm._v(" " + _vm._s(_vm.info.distributorName || "--") + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "手机号" } }, [
            _vm._v(" " + _vm._s(_vm.info.distributorPhone || "--") + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "结算范围" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.info.startDate
                    ? _vm.parseTime(_vm.info.startDate, "{y}-{m}-{d}")
                    : "--"
                ) +
                " - " +
                _vm._s(
                  _vm.info.endDate
                    ? _vm.parseTime(_vm.info.endDate, "{y}-{m}-{d}")
                    : "--"
                ) +
                " "
            ),
          ]),
          _c("el-descriptions-item", { attrs: { label: "结算状态" } }, [
            _vm._v(
              " " + _vm._s(_vm._f("statusFilterName")(_vm.info.status)) + " "
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { "margin-top": "10px" },
          attrs: {
            border: "",
            "cell-style": { padding: "5px 8px" },
            data: _vm.tableData,
            "max-height": "300",
          },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png"),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "来源订单",
              "min-width": "140",
              prop: "billId",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_vm._v(" " + _vm._s(row.billId || "--") + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "来源商户",
              "min-width": "160",
              prop: "shopName",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(row.shopName || "--") +
                        " " +
                        _vm._s(row.shopPhone || "--") +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "来源",
              "min-width": "100",
              prop: "source",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("sourceFilterName")(row.source)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "订单金额(元)",
              "min-width": "90",
              prop: "billAmount",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " + _vm._s((row.billAmount || 0).toFixed(2)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "佣金(元)",
              "min-width": "90",
              prop: "commissionAmount",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " + _vm._s((row.commissionAmount || 0).toFixed(2)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.totalCount > _vm.queryForm.pageSize
        ? _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.queryForm.pageNumber,
              layout: "prev, pager, next",
              "page-size": _vm.queryForm.pageSize,
              small: "",
              total: _vm.totalCount,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "total" }, [
        _c("div", { staticClass: "total__item" }, [
          _c("span", [_vm._v("结算金额(元)")]),
          _c("span", { staticClass: "price" }, [
            _vm._v(_vm._s((_vm.info.commission || 0).toFixed(2))),
          ]),
        ]),
        _c("div", { staticClass: "total__item" }, [
          _c("span", [_vm._v("结算订单数")]),
          _c("span", { staticClass: "price" }, [
            _vm._v(_vm._s(_vm.info.unsettledCount)),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }