<template>
  <el-dialog
    :before-close="handleClose"
    title="驳回原因"
    :visible="turnDownVisible"
    width="400px"
  >
    <el-form
      ref="ruleForm"
      class="demo-ruleForm"
      label-width="100px"
      :model="ruleForm"
      :rules="rules"
    >
      <el-form-item label="驳回原因" prop="textarea">
        <el-input
          v-model="ruleForm.textarea"
          placeholder="请输入原因"
          :rows="2"
          type="textarea"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
  export default {
    name: '',
    props: {
      turnDownVisible: {
        type: Boolean,
        default: () => {
          return false
        },
      },
    },
    data() {
      return {
        ruleForm: {
          textarea: '',
        },
        rules: {
          textarea: [
            { required: true, message: '请输入驳回原因', trigger: 'blur' },
          ],
        },
      }
    },
    created() {},
    methods: {
      handleClose() {
        this.$emit('update:turnDownVisible', false)
      },
      handleConfirm() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.$emit('trunDownConfirm', this.ruleForm.textarea)
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
    },
  }
</script>
<style scoped lang="scss"></style>
