<template>
  <el-dialog
    append-to-body
    class="custom-dialog"
    title="编辑信息"
    :visible.sync="dialogFormVisible"
    width="720px"
    @close="close"
  >
    <el-form
      ref="editForm"
      v-loading="loading"
      label-width="100px"
      :model="ruleForm"
      :rules="rules"
      style="padding: 0 20px"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="渠道商名称" prop="name">
            <el-input
              v-model="ruleForm.name"
              :maxlength="24"
              placeholder="渠道商名称"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="上级">
            <span v-if="info.superiorDistributorName">
              {{ info.superiorDistributorName }}
            </span>
            <span v-else style="color: #999">无上级</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="申请时间">
            {{ info.applyTime }}
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="申请通过时间">
            {{ info.applySuccessTime }}
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="手机号码" prop="phone">
            <el-input
              v-model="ruleForm.phone"
              :disabled="true"
              :maxlength="11"
              placeholder="手机号码"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="联系地址" required>
            <el-row style="width: 94%">
              <el-col :span="10">
                <el-form-item
                  prop="provinceCode"
                  style="width: 100%; margin-bottom: 0"
                >
                  <region-picker
                    :default-text="getRegionText('address')"
                    :enable="enablePicker"
                    placeholder="请选择省/市/区"
                    prop="address"
                    style="width: 100%"
                    :value="[
                      ruleForm.provinceCode,
                      ruleForm.cityCode,
                      ruleForm.districtCode,
                    ]"
                    @change="handleRegionChange"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  prop="address"
                  style="width: 100%; margin-bottom: 0; margin-left: 10px"
                >
                  <el-input
                    v-model="ruleForm.address"
                    :maxlength="64"
                    placeholder="xxx街道x号"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="银行名称" prop="bankName">
            <el-input
              v-model="ruleForm.bankName"
              :maxlength="24"
              placeholder="请输入银行名称"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="结算账户名" prop="settleAccount">
            <el-input
              v-model="ruleForm.settleAccount"
              :maxlength="24"
              placeholder="请输入结算账户名"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="结算账户" prop="settleAccountNo">
            <el-input
              v-model="ruleForm.settleAccountNo"
              :maxlength="32"
              placeholder="请输入结算账户"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="地址/支行" required>
            <el-row style="width: 94%">
              <el-col :span="10">
                <el-form-item
                  prop="openBankProvinceCode"
                  style="width: 100%; margin-bottom: 0"
                >
                  <region-city-picker
                    :default-text="getRegionText('bank')"
                    :enable="enablePicker"
                    placeholder="请选择省/市"
                    prop="bankAddress"
                    style="width: 100%"
                    :value="[
                      ruleForm.openBankProvinceCode,
                      ruleForm.openBankCityCode,
                    ]"
                    @change="handleRegionChange"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  prop="openBank"
                  style="width: 100%; margin-bottom: 0; margin-left: 10px"
                >
                  <el-input
                    v-model="ruleForm.openBank"
                    :maxlength="32"
                    placeholder="支行名称"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="onSubmit">
        保存
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { getRegion } from '@/api/common/index'
  import { updateDistributor } from '@/api/distributor/index'
  import RegionPicker from './RegionPicker.vue'
  import RegionCityPicker from './RegionCityPicker.vue'

  export default {
    name: 'DistributorEdit',
    components: {
      RegionPicker,
      RegionCityPicker,
    },
    data() {
      const validatorPhone = function (rule, value, callback) {
        if (value === '') {
          callback(new Error('请输入手机号码'))
        } else if (!/^1\d{10}$/.test(value)) {
          callback(new Error('手机号格式错误'))
        } else {
          callback()
        }
      }
      return {
        dialogFormVisible: false,
        loading: false,
        ruleForm: {
          id: null,
          name: '',
          phone: '',
          address: '',
          province: '',
          provinceCode: '',
          city: '',
          cityCode: '',
          district: '',
          districtCode: '',
          settleAccount: '',
          settleAccountNo: '',
          openBank: '',
          bankName: '',
          openBankProvince: '',
          openBankProvinceCode: '',
          openBankCity: '',
          openBankCityCode: '',
        },
        rules: {
          name: [
            { required: true, message: '请输入渠道商名称', trigger: 'blur' },
          ],
          phone: [
            { required: true, validator: validatorPhone, trigger: 'blur' },
          ],
          settleAccount: [
            { required: true, message: '请输入结算账户名', trigger: 'blur' },
          ],
          settleAccountNo: [
            { required: true, message: '请输入结算账户', trigger: 'blur' },
          ],
          provinceCode: [
            { required: true, message: '请选择省市区', trigger: 'change' },
          ],
          address: [
            { required: true, message: '请输入详细地址', trigger: 'blur' },
          ],
          openBankProvinceCode: [
            { required: true, message: '请选择支行省市', trigger: 'change' },
          ],
          openBank: [
            { required: true, message: '请输入支行名称', trigger: 'blur' },
          ],
          bankName: [
            { required: true, message: '请输入银行名称', trigger: 'blur' },
          ],
        },
        regionOptions: [],
        info: {},
        props: {
          lazy: true,
          async lazyLoad(node, resolve) {
            console.log('node', node)
            const { level } = node
            let res = await getRegion(level === 0 ? 0 : node.value)
            if (res.code === 0) {
              const nodes = res.data.map((o) => {
                return {
                  label: o.name,
                  value: `${o.name},${o.code}`,
                  leaf: level >= 2,
                }
              })
              resolve(nodes)
            }
          },
        },
        enablePicker: true,
      }
    },
    methods: {
      async showEdit(row) {
        this.loading = false
        this.enablePicker = true
        this.info = row
        Object.keys(this.ruleForm).forEach((key) => {
          this.ruleForm[key] = row[key]
        })
        this.dialogFormVisible = true
        setTimeout(() => {
          this.$refs.editForm.resetFields()
        }, 0)
      },
      close() {
        setTimeout(() => {
          this.$refs.editForm.resetFields()
          // 重置地区选择
          this.enablePicker = false
        }, 0)
        this.dialogFormVisible = false
      },
      onSubmit() {
        this.$refs['editForm'].validate(async (valid) => {
          if (valid) {
            this.loading = true

            updateDistributor(this.ruleForm)
              .then((res) => {
                if (res.code === 0) {
                  this.$baseMessage(
                    '保存成功',
                    'success',
                    'vab-hey-message-success'
                  )
                  this.$emit('fetch-data')
                  this.close()
                }
              })
              .catch((err) => {
                console.log('err', err)
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            return false
          }
        })
      },
      handleRegionChange(e) {
        if (e.prop === 'address') {
          this.ruleForm = Object.assign({}, this.ruleForm, {
            province: e.value.province.name,
            provinceCode: e.value.province.code,
            city: e.value.city.name,
            cityCode: e.value.city.code,
            district: e.value.district.name,
            districtCode: e.value.district.code,
          })
        } else if (e.prop === 'bankAddress') {
          this.ruleForm = Object.assign({}, this.ruleForm, {
            openBankProvince: e.value.province.name,
            openBankProvinceCode: e.value.province.code,
            openBankCity: e.value.city.name,
            openBankCityCode: e.value.city.code,
          })
        }
      },
      getRegionText(type) {
        let { ruleForm } = this
        if (type === 'address') {
          return (
            (ruleForm.province ? ruleForm.province : '') +
            (ruleForm.city ? ` / ${ruleForm.city}` : '') +
            (ruleForm.district ? ` / ${ruleForm.district}` : '')
          )
        } else if (type === 'bank') {
          return (
            (ruleForm.openBankProvince ? ruleForm.openBankProvince : '') +
            (ruleForm.openBankCity ? ` / ${ruleForm.openBankCity}` : '')
          )
        }
      },
    },
  }
</script>

<style lang="scss" scoped></style>
<style lang="scss">
  @import '../../../dialog.scss';

  .custom-dialog {
    @include setDialog();
  }
</style>
