var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Dialog", {
    attrs: {
      height: _vm.dailog.height,
      "model-value": _vm.dailog.visible,
      title: _vm.title + _vm.dailog.title,
      visible: _vm.dailog.visible,
      width: _vm.dailog.width,
    },
    on: {
      handleCloseDialog: _vm.handleCloseDialog,
      handleConfirm: _vm.handleConfirm,
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  "label-width": "168px",
                  model: _vm.formData,
                  rules: _vm.rules,
                  size: "small",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("el-form-item", {
                          attrs: { label: "", prop: "sortRank" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "设备品牌编号", prop: "number" } },
                          [
                            _c("el-input", {
                              staticStyle: { "max-width": "300px" },
                              attrs: {
                                clearable: "",
                                disabled: _vm.disabled,
                                placeholder: "请输入...",
                              },
                              model: {
                                value: _vm.formData.number,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "number", $$v)
                                },
                                expression: "formData.number",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "设备品牌名称", prop: "name" } },
                          [
                            _c("el-input", {
                              staticStyle: { "max-width": "300px" },
                              attrs: {
                                clearable: "",
                                disabled: _vm.disabled,
                                placeholder: "请输入...",
                              },
                              model: {
                                value: _vm.formData.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "name", $$v)
                                },
                                expression: "formData.name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }