var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-top-panel",
            [
              _c(
                "el-form",
                {
                  ref: "searchForm",
                  attrs: { inline: true, model: _vm.searchForm },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称/上级名称", prop: "keywords" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入名称/上级名称" },
                        model: {
                          value: _vm.searchForm.keywords,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "keywords", $$v)
                          },
                          expression: "searchForm.keywords",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号", prop: "phone" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入手机号" },
                        model: {
                          value: _vm.searchForm.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "phone", $$v)
                          },
                          expression: "searchForm.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择状态" },
                          model: {
                            value: _vm.searchForm.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "status", $$v)
                            },
                            expression: "searchForm.status",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-search",
                            "native-type": "submit",
                            type: "primary",
                          },
                          on: { click: _vm.handleQueryForm },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-refresh-left",
                            "native-type": "submit",
                          },
                          on: { click: _vm.resetQueryForm },
                        },
                        [_vm._v(" 重置 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vab-query-form-left-panel",
            { attrs: { span: 24 } },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-plus", type: "primary" },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v(" 新增渠道商 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            border: "",
            "cell-style": { padding: "5px 8px" },
            data: _vm.tableData,
            stripe: "",
          },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png"),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "序号", width: "55" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ $index }) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm.queryForm.pageNumber - 1) *
                              _vm.queryForm.pageSize +
                              $index +
                              1
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "手机号",
              "min-width": "120",
              prop: "phone",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "名称",
              "min-width": "100",
              prop: "name",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_vm._v(" " + _vm._s(row.name || "--") + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "上级名称",
              "min-width": "100",
              prop: "superiorDistributorName",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " + _vm._s(row.superiorDistributorName || "--") + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "地址",
              "min-width": "220",
              prop: "address",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(row.province || "") +
                        _vm._s(row.city || "") +
                        _vm._s(row.district || "") +
                        _vm._s(row.address || "") +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "下级数量",
              "min-width": "100",
              prop: "lowerDistributorCount",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "推广商户数",
              "min-width": "100",
              prop: "shopCount",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "获得佣金",
              "min-width": "120",
              prop: "commissionAmount",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " + _vm._s((row.commission || 0).toFixed(2)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "注册时间",
              prop: "createTime",
              width: "140",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.parseTime(row.createTime, "{y}-{m}-{d} {h}:{i}")
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "状态",
              "min-width": "80",
              prop: "status",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: row.status == 0 ? "点击开启" : "点击禁用",
                          enterable: false,
                          placement: "top",
                        },
                      },
                      [
                        _c("el-switch", {
                          attrs: {
                            "active-value": 1,
                            disabled: _vm.statusChangeLoading,
                            "inactive-value": 0,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onSwitchChange(row)
                            },
                          },
                          model: {
                            value: row.status,
                            callback: function ($$v) {
                              _vm.$set(row, "status", $$v)
                            },
                            expression: "row.status",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              "show-overflow-tooltip": "",
              width: "80",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row)
                          },
                        },
                      },
                      [_vm._v("详情")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          limit: _vm.queryForm.pageSize,
          page: _vm.queryForm.pageNumber,
          "total-count": _vm.totalCount,
        },
        on: {
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryForm, "pageSize", $event)
          },
          "update:page": function ($event) {
            return _vm.$set(_vm.queryForm, "pageNumber", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("distributor-add", {
        ref: "add",
        on: { "fetch-data": _vm.handleQueryForm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }