<!-- 月结账单 -->
<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="searchForm"
          :inline="true"
          :model="searchForm"
          @submit.native.prevent
        >
          <el-form-item label="结算记录号" prop="subtotalId">
            <el-input
              v-model="searchForm.subtotalId"
              placeholder="请输入结算记录号"
            />
          </el-form-item>
          <el-form-item label="结算名" label-width="70px" prop="settleAccount">
            <el-input
              v-model="searchForm.settleAccount"
              placeholder="请输入结算名"
            />
          </el-form-item>
          <el-form-item
            label="渠道商"
            label-width="70px"
            prop="distributorName"
          >
            <el-input
              v-model="searchForm.distributorName"
              placeholder="请输入渠道商"
            />
          </el-form-item>
          <el-form-item label="结算状态" prop="status">
            <el-select v-model="searchForm.status" placeholder="请选择结算状态">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQueryForm"
            >
              查询
            </el-button>
            <el-button
              icon="el-icon-refresh-left"
              native-type="submit"
              @click="resetQueryForm"
            >
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>

      <vab-query-form-left-panel :span="24">
        <el-date-picker
          v-model="dateRange"
          align="left"
          end-placeholder="开始日期"
          range-separator="-"
          start-placeholder="结束日期"
          style="margin-bottom: 10px; width: 250px"
          type="daterange"
          @change="onDateRangeChange"
        />
      </vab-query-form-left-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :cell-style="{ padding: '5px 8px' }"
      :data="tableData"
      stripe
    >
      <el-table-column align="center" label="序号" width="55">
        <template slot-scope="scope">
          <span>
            {{
              (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="结算记录号"
        min-width="170"
        prop="id"
      >
        <template #default="{ row }">
          {{ row.id || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="渠道商"
        min-width="100"
        prop="distributorName"
      >
        <template #default="{ row }">
          {{ row.distributorName || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="手机号"
        min-width="120"
        prop="distributorPhone"
      >
        <template #default="{ row }">
          {{ row.distributorPhone || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="生成时间"
        prop="createTime"
        width="160"
      />
      <el-table-column
        align="center"
        label="结算范围"
        min-width="110"
        prop="startDate"
      >
        <template #default="{ row }">
          {{ row.startDate ? parseTime(row.startDate, '{y}-{m}-{d}') : '--' }}
          至
          {{ row.endDate ? parseTime(row.endDate, '{y}-{m}-{d}') : '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="结算名"
        min-width="100"
        prop="settleAccount"
      >
        <template #default="{ row }">
          {{ row.settleAccount || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="结算账号"
        min-width="160"
        prop="settleAccountNo"
      >
        <template #default="{ row }">
          {{ row.settleAccountNo || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="结算时间"
        prop="settlementTime"
        width="160"
      >
        <template #default="{ row }">
          {{ row.settlementTime || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="结算金额(元)"
        min-width="120"
        prop="commission"
      >
        <template #default="{ row }">
          {{ (row.commission || 0).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="结算订单数"
        min-width="120"
        prop="billCount"
      />
      <el-table-column
        align="center"
        label="结算状态"
        min-width="80"
        prop="status"
      >
        <template #default="{ row }">
          <el-tag :type="row.status | statusFilter">
            {{ row.status | statusFilterName }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        fixed="right"
        label="操作"
        show-overflow-tooltip
        width="120"
      >
        <template #default="{ row }">
          <el-button
            :disabled="row.status !== 0"
            type="text"
            @click="handleEdit(row, true)"
          >
            结算
          </el-button>
          <el-button type="text" @click="handleEdit(row)">详情</el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="queryForm.pageSize"
      :page.sync="queryForm.pageNumber"
      :total-count="totalCount"
      @pagination="fetchData"
    />
    <bill-info ref="bill" @fetch-data="fetchData" />
  </div>
</template>
<script>
  import { getChannelSubtotalPage } from '@/api/distributor/finance'
  import { parseTime } from '@/utils/index'
  import Pagination from '@/components/pagination.vue'
  import BillInfo from './components/BillInfo.vue'
  export default {
    name: 'DistributorFinanceBill',
    components: {
      Pagination,
      BillInfo,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          0: 'primary',
          1: 'success',
          2: 'info',
        }
        return statusMap[status]
      },
      statusFilterName(status) {
        const statusNameMap = {
          0: '待结算',
          1: '已结算',
        }
        return statusNameMap[status]
      },
    },

    data() {
      const now = new Date()
      const dateRange = [
        new Date(now.getFullYear(), now.getMonth() - 1, 1),
        new Date(now.getFullYear(), now.getMonth(), 0),
      ]
      const searchForm = {
        subtotalId: null, // 账单id
        status: null, // 结算状态
        settleAccount: null, // 结算账户名
        distributorName: null, // 渠道商名称
      }
      return {
        searchForm,
        queryForm: {
          pageNumber: 1,
          pageSize: 20,
          ...searchForm,
          startDate: null,
          endDate: null,
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        options: [
          {
            label: '全部',
            value: '',
          },
          {
            label: '待结算',
            value: 0,
          },
          {
            label: '已结算',
            value: 1,
          },
        ],
        dateRange,
      }
    },
    mounted() {
      this.fetchData()
    },
    methods: {
      handleQueryForm() {
        this.queryForm = Object.assign({}, this.queryForm, this.searchForm, {
          pageNumber: 1,
        })
        this.fetchData()
      },
      resetQueryForm() {
        this.$refs['searchForm'].resetFields()
        this.handleQueryForm()
      },
      fetchData() {
        this.loading = true
        getChannelSubtotalPage({
          ...this.queryForm,
          startDate:
            this.dateRange && this.dateRange[0]
              ? parseTime(this.dateRange[0], '{y}-{m}-{d}') + ' 0:0:0'
              : null,
          endDate:
            this.dateRange && this.dateRange[0]
              ? parseTime(this.dateRange[1], '{y}-{m}-{d}') + ' 23:59:59'
              : null,
        }).then((res) => {
          if (res.code === 0) {
            const { records, total } = res.data
            this.tableData = records
            this.totalCount = +total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      onDateRangeChange() {
        this.queryForm.pageNumber = 1
        this.fetchData()
      },
      handleEdit(row, settle = false) {
        this.$refs['bill'].showEdit(row, settle)
      },

      parseTime,
    },
  }
</script>
<style lang="scss" scoped>
  :deep() {
    .el-table .cell.el-tooltip {
      min-width: auto;
    }
    .el-table--border .el-table__cell:first-child .cell {
      padding: 0;
    }
    .el-table .cell {
      padding: 0;
    }
    .vab-query-form .el-form-item + .el-form-item {
      margin-left: 15px !important;
    }
  }
</style>
