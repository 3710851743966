<template>
  <Dialog
    :height="dailog.height"
    :model-value="dailog.visible"
    :showfoot="false"
    :title="dailog.title"
    :visible="dailog.visible"
    :width="dailog.width"
    @handleCloseDialog="handleCloseDialog"
  >
    <template #content>
      <el-form label-width="80px" :model="listQuery" size="small">
        <el-row>
          <el-col
            :md="{ span: 6 }"
            :sm="{ span: 6 }"
            :xl="{ span: 6 }"
            :xs="{ span: 24 }"
          >
            <el-form-item label="合同编号">
              <el-input
                v-model="listQuery.contractCode"
                clearable
                placeholder="合同编号"
              />
            </el-form-item>
          </el-col>
          <el-col
            :md="{ span: 6 }"
            :sm="{ span: 6 }"
            :xl="{ span: 6 }"
            :xs="{ span: 24 }"
          >
            <el-form-item label="合同状态">
              <el-select
                v-model="listQuery.contractStatus"
                placeholder="合同状态"
              >
                <el-option
                  v-for="item in ESignContractStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :md="{ span: 8 }"
            :sm="{ span: 8 }"
            :xl="{ span: 8 }"
            :xs="{ span: 24 }"
          >
            <el-form-item label="签约时间">
              <el-date-picker
                v-model="listQuery.contractDate"
                end-placeholder="结束日期"
                range-separator="至"
                start-placeholder="开始日期"
                type="daterange"
              />
            </el-form-item>
          </el-col>
          <el-col
            :md="{ span: 4 }"
            :sm="{ span: 4 }"
            :xl="{ span: 4 }"
            :xs="{ span: 24 }"
          >
            <el-button
              icon="el-icon-search"
              size="mini"
              type="primary"
              @click="getList"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" size="mini" @click="reset">
              重置
            </el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-table v-loading="loading" border :data="tableData" stripe>
        <el-table-column align="center" label="序号" width="50">
          <template slot-scope="scope">
            <span>
              {{
                (listQuery.pageNumber - 1) * listQuery.pageSize +
                scope.$index +
                1
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="合同编号"
          prop="contractCode"
          show-overflow-tooltip
          width="220"
        />
        <el-table-column
          align="center"
          label="租客"
          prop="tenantName"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="合同模板"
          prop="contractTemplateName"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="合同状态"
          prop="contractStatusName"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="发起时间"
          prop="initiateTime"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="业主签约时间"
          prop="shopSignTime"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="承租人签约时间"
          prop="memberSignTime"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="操作"
          prop="createTime"
          show-overflow-tooltip
        >
          <template #default="{ row }">
            <el-button size="mini" type="text" @click="handleVideDetail(row)">
              合同详情
            </el-button>
          </template>
        </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
      </el-table>
      <Pagination
        :limit.sync="listQuery.pageSize"
        :page.sync="listQuery.pageNumber"
        :total-count="totalCount"
        @pagination="getList"
      />
    </template>
  </Dialog>
</template>

<script>
  import dayjs from 'dayjs'
  import { ESignContractStatus } from '@/utils/contract'
  import Dialog from '@/components/dialog.vue'
  import Pagination from '@/components/pagination.vue'
  import {
    getEsignContractInitiatePage,
    getEsignContractInfoUrl,
  } from '@/api/esign/contract'
  export default {
    components: {
      Dialog,
      Pagination,
    },
    data() {
      return {
        ESignContractStatusOptions: ESignContractStatus.dict,
        dailog: {
          height: null,
          title: '发起记录',
          visible: false,
          width: '1340px',
        },
        listQuery: {
          shopId: '',
          contractCode: '',
          contractStatus: '',
          startDate: '',
          endDate: '',
          contractDate: '',
          pageNumber: 1,
          pageSize: 20,
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        size: '',
      }
    },
    methods: {
      showModal(shopId) {
        this.listQuery.shopId = shopId
        this.dailog.visible = true
        this.getList()
      },
      getList() {
        this.loading = true
        const { contractDate, ...params } = this.listQuery
        params.startDate =
          contractDate && contractDate.length
            ? dayjs(contractDate[0]).format('YYYY-MM-DD')
            : undefined
        params.endDate =
          contractDate && contractDate.length
            ? dayjs(contractDate[1]).format('YYYY-MM-DD')
            : undefined
        getEsignContractInitiatePage(params).then((res) => {
          if (res.code === 0) {
            const { records, total } = res.data
            this.tableData = records.map((item) => ({
              ...item,
              contractStatusName: ESignContractStatus.getLabelByValue(
                item.contractStatus
              ),
            }))
            this.totalCount = +total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      reset() {
        for (let key in this.listQuery) {
          if (key != 'shopId') this.listQuery[key] = ''
        }
        this.listQuery.pageNumber = 1
        this.listQuery.pageSize = 20
        this.getList()
      },
      handleCloseDialog() {
        this.dailog.visible = false
      },
      handleVideDetail(row) {
        getEsignContractInfoUrl({ contractFlowId: row.contractFlowId }).then(
          (res) => {
            window.open(res.data, '_blank')
          }
        )
      },
    },
  }
</script>
