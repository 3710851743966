<template>
  <Dialog
    :height="dailog.height"
    :model-value="dailog.visible"
    :title="dailog.title"
    :visible="dailog.visible"
    :width="dailog.width"
    @handleCloseDialog="handleCloseDialog"
    @handleConfirm="handleConfirm"
  >
    <template #content>
      <el-form
        ref="form"
        label-width="180px"
        :model="formData"
        :rules="rules"
        size="small"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="模板名称" prop="contractTemplateName">
              <el-input
                v-model="formData.contractTemplateName"
                clearable
                placeholder="请输入..."
                style="max-width: 340px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="适用类型" prop="houseType">
              <el-radio-group v-model="formData.houseType">
                <el-radio
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.value"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col v-if="!id" :span="24">
            <el-form-item label="合同模板" prop="file">
              <el-upload
                ref="upload"
                accept=".docx,.doc"
                :action="domain"
                :auto-upload="false"
                :data="{
                  houseType: formData.houseType,
                  contractTemplateName: formData.contractTemplateName,
                }"
                :file-list="fileList"
                :headers="headers"
                :limit="1"
                :on-change="onChange"
                :on-error="uploadError"
                :on-exceed="onExceed"
                :on-remove="onRemove"
                :on-success="uploadSuccess"
              >
                <el-button size="small" type="primary">点击上传</el-button>
                <span style="display: inline-block; margin-left: 10px">
                  文件类型仅支持：doc,docx
                </span>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col v-if="id" :span="24">
            <el-row>
              <el-col :span="7">
                <el-form-item
                  label="出租方签署所在页码"
                  prop="landlordSealPosition.positionPage"
                >
                  <el-input
                    v-model="formData.landlordSealPosition.positionPage"
                    clearable
                    placeholder="请输入..."
                    type="number"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item
                  label="签署X坐标"
                  prop="landlordSealPosition.positionX"
                >
                  <el-input
                    v-model="formData.landlordSealPosition.positionX"
                    clearable
                    placeholder="请输入..."
                    type="number"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item
                  label="签署Y坐标"
                  prop="landlordSealPosition.positionY"
                >
                  <el-input
                    v-model="formData.landlordSealPosition.positionY"
                    clearable
                    placeholder="请输入..."
                    type="number"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item
                  label="签署日期X坐标"
                  prop="landlordSealPosition.signDatePositionX"
                >
                  <el-input
                    v-model="formData.landlordSealPosition.signDatePositionX"
                    clearable
                    placeholder="请输入..."
                    type="number"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item
                  label="签署日期Y坐标"
                  prop="landlordSealPosition.signDatePositionY"
                >
                  <el-input
                    v-model="formData.landlordSealPosition.signDatePositionY"
                    clearable
                    placeholder="请输入..."
                    type="number"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col v-if="id" :span="24">
            <el-row>
              <el-col :span="7">
                <el-form-item
                  label="承租方签署所在页码"
                  prop="renterSealPosition.positionPage"
                >
                  <el-input
                    v-model="formData.renterSealPosition.positionPage"
                    clearable
                    placeholder="请输入..."
                    type="number"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="X坐标" prop="renterSealPosition.positionX">
                  <el-input
                    v-model="formData.renterSealPosition.positionX"
                    clearable
                    placeholder="请输入..."
                    type="number"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="Y坐标" prop="renterSealPosition.positionY">
                  <el-input
                    v-model="formData.renterSealPosition.positionY"
                    clearable
                    placeholder="请输入..."
                    type="number"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item
                  label="签署日期X坐标"
                  prop="renterSealPosition.signDatePositionX"
                >
                  <el-input
                    v-model="formData.renterSealPosition.signDatePositionX"
                    clearable
                    placeholder="请输入..."
                    type="number"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item
                  label="签署日期Y坐标"
                  prop="renterSealPosition.signDatePositionY"
                >
                  <el-input
                    v-model="formData.renterSealPosition.signDatePositionY"
                    clearable
                    placeholder="请输入..."
                    type="number"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
    </template>
  </Dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Dialog from '@/components/dialog.vue'
  import { CONTRACTTEMPLETETYPE } from '@/utils/contract'
  import { baseURLV2, contentType } from '@/config'
  import { putEsignContractTemplate } from '@/api/esign/contract'
  export default {
    components: {
      Dialog,
    },
    data() {
      return {
        dailog: {
          height: null,
          title: '新增模板',
          visible: false,
          width: '1050px',
        },
        typeOptions: CONTRACTTEMPLETETYPE.dict,
        formData: {
          contractTemplateName: '',
          houseType: '',
          file: undefined,
          landlordSealPosition: {
            positionPage: '',
            positionX: '',
            positionY: '',
            signDatePositionX: '',
            signDatePositionY: '',
          },
          renterSealPosition: {
            positionPage: '',
            positionX: '',
            positionY: '',
            signDatePositionX: '',
            signDatePositionY: '',
          },
        },
        id: '',
        rules: {
          contractTemplateName: [
            { required: true, message: '请输入模板名称', trigger: 'blur' },
          ],
          houseType: [
            { required: true, message: '请选择适用类型', trigger: 'blur' },
          ],
          file: [
            { required: true, message: '请上传合同模板', trigger: 'blur' },
          ],
        },
        uploadUrl: '', //提交到esign模板编辑地址
        fileList: [],
        urlList: [],
        loadingSure: false,
        headers: {
          contentType,
        },
        domain: `${baseURLV2 || ''}/v2/esign/contract/template/create`,
      }
    },
    computed: {
      ...mapGetters({
        token: 'user/token',
      }),
    },
    mounted() {
      this.headers['Authorization'] = `Bearer ${this.token}`
    },
    methods: {
      showModal(row) {
        this.dailog.visible = true
        this.id = ''
        this.dailog.title = '新增模板'
        if (row) {
          this.id = row.id
          this.dailog.title = '编辑模板'
          const { contractTemplateName, houseType } = row
          this.formData.contractTemplateName = contractTemplateName
          this.formData.houseType = houseType
          if (row.landlordSealPosition) {
            this.formData.landlordSealPosition = JSON.parse(
              row.landlordSealPosition
            )
          }
          if (row.renterSealPosition) {
            this.formData.renterSealPosition = JSON.parse(
              row.renterSealPosition
            )
          }
        }
      },
      handleCloseDialog() {
        this.dailog.visible = false
        console.log(this.$options.data().formData)
        this.formData = this.$options.data().formData
      },
      onChange(e) {
        this.formData.file = e
        console.log(this.formData)
      },
      handleConfirm() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            try {
              this.loadingSure = true
              if (this.id) {
                const res = await putEsignContractTemplate({
                  id: this.id,
                  ...this.formData,
                })
                this.$emit('ok')
                this.dailog.visible = false
                if (res.data) {
                  this.$confirm('编辑成功，是否去e签宝编辑合同模板?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                  }).then(() => {
                    window.open(res.data, '_blanck')
                  })
                }
              } else {
                this.loadingSure = false
                this.$refs.upload.submit()
              }
            } finally {
              this.loadingSure = false
            }
          }
        })
      },
      uploadSuccess(response, file) {
        if (response.code == 0 && response.data) {
          this.urlList.push({
            uid: file.uid,
            url: this.uploadUrl,
          })
          this.$emit('ok')
          this.dailog.visible = false
          this.uploadUrl = response.data
          this.$confirm('新增成功，是否去e签宝编辑合同模板?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            window.open(this.uploadUrl, '_blanck')
          })
        } else {
          this.$message({
            message: response.msg || '上传出错，请重试！',
            type: 'error',
            center: true,
          })
        }
      },
      // 上传失败
      uploadError() {
        this.uploadUrl = ''
        this.$message({
          message: '上传出错，请重试！',
          type: 'error',
          center: true,
        })
      },
      onExceed() {
        this.$message({
          message: '最多可上传1个文件',
          type: 'error',
          center: true,
        })
      },
      onRemove(file) {
        const arr = this.urlList.filter((o) => {
          return o.uid !== file.uid
        })
        this.urlList = arr
        this.formData.file = ''
      },
    },
  }
</script>
