<!-- 城市选择 -->
<template>
  <div class="region-picker">
    <el-select
      :placeholder="placeholder"
      style="width: 100%"
      :value="defaultText"
    />

    <el-cascader
      v-if="enable"
      ref="regionCityPicker"
      class="cascader"
      :placeholder="placeholder"
      :props="props"
      :value="value"
      @change="handleRegionChange"
    />
  </div>
</template>

<script>
  import { getRegion } from '@/api/common/index'
  export default {
    name: 'RegionCityPicker',
    props: {
      placeholder: {
        type: String,
        default: '请选择省/市/区',
      },
      prop: {
        type: String,
        default: null,
      },
      value: {
        type: Array,
        default() {
          return []
        },
      },
      defaultText: {
        type: String,
        default: '',
      },
      enable: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        props: {
          lazy: true,
          async lazyLoad(node, resolve) {
            const { level } = node
            let res = await getRegion(level === 0 ? 0 : node.value)
            if (res.code === 0) {
              const nodes = res.data.map((o) => {
                return {
                  label: o.name,
                  value: o.code,
                  leaf: level >= 1,
                }
              })
              resolve(nodes)
            } else {
              resolve([])
            }
          },
        },
      }
    },
    watch: {
      defaultText: {
        handler(val) {
          setTimeout(() => {
            this.$refs.regionCityPicker.inputValue = val
          }, 0)
        },
        immediate: true,
      },
    },
    methods: {
      handleRegionChange(values) {
        let result = {
          province: {
            name: '',
            code: '',
          },
          city: {
            name: '',
            code: '',
          },
          district: {
            name: '',
            code: '',
          },
        }
        let labels = this.$refs.regionCityPicker.getCheckedNodes()[0].pathLabels
        values.map((code, index) => {
          if (index === 0) {
            result.province = {
              name: labels[index],
              code,
            }
          } else if (index === 1) {
            result.city = {
              name: labels[index],
              code,
            }
          } else if (index === 2) {
            result.district = {
              name: labels[index],
              code,
            }
          }
        })
        this.$emit('change', {
          prop: this.prop,
          value: result,
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .region-picker {
    position: relative;
    .cascader {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      opacity: 0;
      z-index: 0;
    }
  }
</style>
